import React from 'react'
import { 
  Row,
  Col
} from 'react-bootstrap'
import { 
  IoEyeOutline 
} from 'react-icons/io5'
import {
  useFormikContext
} from 'formik'
import { 
  InfoItemVertical, 
  TextArea,
  ActionButton
} from 'components'
import { 
  DateConvert 
} from 'utilities'
import {
  StatusSeleksi,
  TahapanSeleksi
} from '../Comps'

export const DetailPelamarKerja = () => {
  const { values, errors, touched, handleChange } = useFormikContext()

  return (
    <div>
      <Row>
        <Col lg>
          <Row>
            <Col md>
              <InfoItemVertical 
                label="Kode Pelamar Kerja"
                text={values?.kode_pelamar_kerja ?? '-'}
                />
              <InfoItemVertical 
                label="Nam Pelamar Kerja"
                text={values?.nama_pelamar_kerja ?? '-'}
                />
              <InfoItemVertical 
                label="NIK Pelamar Kerja"
                text={values?.nik ?? '-'}
                />
            </Col>
            <Col md>
              <InfoItemVertical 
                label="Tgl Pelamar Kerja"
                text={values?.tgl_pelamar_kerja ? DateConvert(new Date(values.tgl_pelamar_kerja)).detail : '-'}
              />
              <InfoItemVertical 
                label="Tgl Lahir Pelamar Kerja"
                text={values?.tgl_lahir ? DateConvert(new Date(values.tgl_lahir)).detail : '-'}
              />
              <InfoItemVertical 
                label="Posisi Yang Diinginkan"
                text={values?.posisi ?? '-'}
              />
            </Col>
          </Row>
        </Col>
        <Col lg>
        <Row>
            <Col md>
              <InfoItemVertical 
                label="No. Telp"
                text={values?.no_telepon ?? '-'}
                />
              <InfoItemVertical 
                label="Pendidikan Terakhir"
                text={values?.pendidikan_terakhir ?? '-'}
              />
              <InfoItemVertical 
                label="Jurusan"
                text={values?.jurusan ?? '-'}
              />
            </Col>
            <Col md>
              <InfoItemVertical 
                label="Email"
                text={values?.email ?? '-'}
              />
              <InfoItemVertical 
                label="Nama Sekolah / Instansi"
                text={values?.institusi ?? '-'}
              />
              <InfoItemVertical 
                label="Alamat Pelamar Pekerja"
                text={values?.alamat ?? '-'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg>
          <Row>
            <Col md>
              <InfoItemVertical 
                label="Dokumen CV"
                text={
                  <ActionButton 
                    size="sm" 
                    className="my-2"
                    text={<><IoEyeOutline /> Lihat Dokumen</>} 
                    onClick={() => window.open(values?.path_file_cv)}
                  />
                }
              />
            </Col>
            <Col md>
              <InfoItemVertical 
                label="Dokumen KTP"
                text={
                  <ActionButton 
                    size="sm" 
                    className="my-2"
                    text={<><IoEyeOutline /> Lihat Dokumen</>} 
                    onClick={() => window.open(values?.path_file_ktp)}
                  />
                }
              />
            </Col>
          </Row>
        </Col>
        <Col lg>
        <Row>
            <Col md>
            </Col>
            <Col md>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md>
          <Row>
            <Col>
              <InfoItemVertical 
                label="Tahapan Seleksi"
                text={<TahapanSeleksi textLeft status={values.tahap_seleksi} date={values.tgl_tahap_seleksi} />}
              />
            </Col>
            <Col>
              <InfoItemVertical 
                label="Status Seleksi"
                text={<StatusSeleksi textLeft status={values.status_seleksi} date={values.tgl_status_seleksi} />}
              />
            </Col>
          </Row>
        </Col>
        <Col>
        </Col>
      </Row>

      <TextArea 
        label="Keterangan"
        name="keterangan"
        values={values.keterangan}
        onChange={handleChange}
        placeholder="Masukan keterangan"
        error={!!(errors.keterangan && touched.keterangan)}
        errorText={!!(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </div>
  )
}
