import Services from "../../../services";

class JenisKaryawanApi {
  get() {
    return Services.get("/hrda/jenis_pekerja");
  }

  getPage(page, dataLength, key) {
    return Services.get(`/hrda/jenis_pekerja/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  getDropdown() {
    return Services.get("/hrda/jenis_pekerja/dropdown");
  }

  search(key) {
    return Services.get("/hrda/jenis_pekerja/page/?per_page=10&page=1&q=" + key);
  }

  show(value) {
    return Services.put("/hrda/jenis_pekerja/show", value);
  }

  hide(value) {
    return Services.put("/hrda/jenis_pekerja/hide", value);
  }

  create(value) {
    return Services.post("/hrda/jenis_pekerja", value);
  }

  update(value) {
    return Services.put("/hrda/jenis_pekerja", value);
  }

  delete(value) {
    return Services.post("/hrda/jenis_pekerja/delete", value);
  }

  checkDuplicate(params) {
    return Services.get("/hrda/jenis_pekerja/check_duplicate", { params });
  }
}

export default new JenisKaryawanApi();
