import {
  useState,
  useEffect
} from 'react'
import {
  useHistory
} from 'react-router-dom'
import {
  Formik,
  useFormik
} from 'formik'
import {
  BackButton,
  ActionButton
} from 'components'
import {
  PelamarKerjaApi
} from 'api'
import {
  SectionPelamarKerja
} from './Comps'
import {
  formInitialValues,
  formValidationSchema
} from './Utils'


export const PelamarKerjaCreate = ({setNavbarTitle}) => {
  const history = useHistory()
  const [fetching, setFetching] = useState({
    loading: true,
    error: true
  })

  const formSubmitHandler = async (values) => {
    const dataFile = [values?.data_file_cv, values?.data_file_ktp] // index 0 = data CV, index 1 = data KTP
    
    let pathCV = ''
    let pathKTP = ''

    await Promise.all(dataFile.map(async (val, index) => {
      if (!!val) {
        const fileData = new FormData()
        fileData.append("file", val)

        return await PelamarKerjaApi.saveFile(fileData)
          .then(res => {
            const pathFile = res?.data?.data ?? ''

            if(index === 0) {
              pathCV = pathFile
            } else {
              pathKTP = pathFile
            }
          })
          .catch(() => {
            if(index === 0) {
              pathCV = ''
            } else {
              pathKTP = ''
            }
          })
      } else {
        pathCV = values?.path_file_cv
        pathKTP = values?.path_file_ktp
      }
    }))

    const finalValues = {
      ...values,
      path_file_ktp: pathKTP,
      path_file_cv: pathCV,
    }

    PelamarKerjaApi.create(finalValues)
    .then(() => history.push('/human-resource/master/karyawan/pelamar-kerja', { alert: { show: true, variant: 'primary', text: 'Tambah data berhasil!' } }))
    .catch(() => history.push('/human-resource/master/karyawan/pelamar-kerja', { alert: { show: true, variant: 'danger', text: 'Tambah data gagal!' } }))
  }

  useEffect(() => {
    setNavbarTitle('Pelamar Kerja')
    setFetching({
      loading: false,
      error: false
    })
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <div className="mx-2">Tambah Data Pelamar Kerja</div>
        <BackButton 
          size="sm"
          onClick={() => {
            history.replace('/human-resource/master/karyawan/pelamar-kerja', {})
            history.go(-1)
          }}
        />
      </div>
      <div className="bg-white rounded border mt-2 p-3">
       <Formik
          initialValues={formInitialValues()}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({isSubmitting, handleSubmit}) => (
            <>
              <SectionPelamarKerja />
              <div className='text-right'>
                <ActionButton 
                  variant="primary"
                  text="Tambah"
                  className="px-5 my-4 text-white"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  )
}
