import {
  DateConvert,
} from 'utilities'

export const TahapanSeleksi = ({status = '', date, textLeft = false}) => {
  switch (status?.toUpperCase()) {
    case 'REVIEW DOKUMEN':
      return (
        <div className={`d-flex flex-column ${textLeft ? 'align-items-start' : 'align-items-center'}`}>
          <span className='d-block text-nowrap text-secondary'>Review Dokumen</span>
          <span className='d-block'>{date ? DateConvert(new Date(date)).defaultDMY : '-'}</span>
        </div>
      )

    case 'LOLOS SELEKSI':
      return (
        <div className={`d-flex flex-column ${textLeft ? 'align-items-start' : 'align-items-center'}`}>
          <span className='d-block text-nowrap text-success'>Lolos Seleksi</span>
          <span className='d-block'>{date ? DateConvert(new Date(date)).defaultDMY : '-'}</span>
        </div>
      )
  
    default:
      return (
        <div className={`d-flex flex-column ${textLeft ? 'align-items-start' : 'align-items-center'}`}>
          <span className='d-block text-nowrap text-primary'>{status ?? '-'}</span>
          <span className='d-block'>{date ? DateConvert(new Date(date)).defaultDMY : '-'}</span>
        </div>
      )
  }
}