import React, { useState, useEffect } from 'react'
import { Modal, ButtonGroup } from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import {
  IoPencilOutline,
  IoTrashOutline,
} from "react-icons/io5"
import {
  Input,
  SelectSearch,
  ActionButton,
  DataStatus,
  DatePicker,
} from "components"
import { DateConvert } from 'utilities'
import InputDokumenKontrak from './InputDokumenKontrak'
import { RegistrasiKaryawanApi } from 'api'

const Kontrak = ({ id_karyawan, type }) => {
  const [modalConfig, setModalConfig] = useState({ show: false, type: 'create', data: null })
  const [modalDelete, setModalDelete] = useState({ show: false, data: null })
  const tableStyling = { padding: "2px", fontSize: "14px", verticalAlign: "middle" }
  const [dataKontrak, setDataKontrak] = useState([])
  const [loadingKontrak, setLoadingKontrak] = useState(false)

  const fetchDataKontrak = () => {
    setLoadingKontrak(true)
    RegistrasiKaryawanApi.getDataKontrak({ id_karyawan })
      .then(({ data }) => setDataKontrak(data?.data ?? []))
      .finally(() => setLoadingKontrak(false))
  }

  const ModalForm = ({ fetchDataKontrak, modalConfig, setModalConfig }) => {
    const [document, setDocument] = useState(null)
    const [dropdown, setDropdown] = useState({
      jenis_pekerja: [],
      status_pekerja: [],
      detail_status_pekerja: [],
    })
    const [loading, setLoading] = useState({
      jenis_pekerja: false,
      status_pekerja: false,
      detail_status_pekerja: false,
    })
    const isUpdateForm = modalConfig.type === 'update'
    const {
      id_kontrak,
      tgl_mulai_kontrak,
      tgl_selesai_kontrak,
      no_kontrak,
      id_jenis_karyawan,
      id_status_karyawan,
      id_status_karyawan_detail,
      path_file,
      dokumen_kontrak } = modalConfig?.data ?? {}

    const handleUploadDocument = async () => {
      const formData = new FormData()
      formData.append('file', document?.data)

      return RegistrasiKaryawanApi.uploadDokumenKontrak(formData)
        .then(({ data }) => data?.data)
        .catch(({ res }) => window.alert(res?.data?.message ?? "Gagal mengupload dokumen"))
    }

    const formInitialValues = {
      id_karyawan: id_karyawan ?? "",
      tgl_mulai_kontrak: isUpdateForm ? tgl_mulai_kontrak : "",
      tgl_selesai_kontrak: isUpdateForm ? tgl_selesai_kontrak : "",
      no_kontrak: isUpdateForm ? no_kontrak : "",
      id_jenis_karyawan: isUpdateForm ? id_jenis_karyawan : "",
      id_status_karyawan: isUpdateForm ? id_status_karyawan : "",
      id_status_karyawan_detail: isUpdateForm ? id_status_karyawan_detail : "",
    }
    const formValidationSchema = Yup.object().shape({
      tgl_mulai_kontrak: Yup.string().required("Tanggal mulai kontrak harus diisi"),
      tgl_selesai_kontrak: Yup.string().required("Tanggal selesai kontrak harus diisi"),
      no_kontrak: Yup.string().required("Nomor kontrak harus diisi"),
      id_jenis_karyawan: Yup.string().required("Jenis pekerja harus diisi"),
      id_status_karyawan: Yup.string().required("Status pekerja harus diisi"),
      id_status_karyawan_detail: Yup.string().required("Detail status pekerja harus diisi"),
    })
    const formSubmitHandler = async (values, { setSubmitting }) => {
      if (document) {
        if (modalConfig.type === "create") {
          const dokumen_kontrak = await handleUploadDocument()
          const finalValue = { ...values, dokumen_kontrak }

          RegistrasiKaryawanApi.createDataKontrak(finalValue)
            .catch(({ res }) => window.alert(res?.data?.message ?? "Gagal menambahkan data kontrak"))
            .finally(() => {
              setModalConfig(prev => ({ ...prev, show: false }))
              fetchDataKontrak()
            })
        }
        else {
          const new_dokumen_kontrak = document?.data ? await handleUploadDocument() : dokumen_kontrak
          const finalValue = {
            ...values,
            id_kontrak: id_kontrak,
            dokumen_kontrak: new_dokumen_kontrak
          }

          RegistrasiKaryawanApi.updateDataKontrak(finalValue)
            .catch(({ res }) => window.alert(res?.data?.message ?? "Gagal menambahkan data kontrak"))
            .finally(() => {
              setModalConfig(prev => ({ ...prev, show: false }))
              fetchDataKontrak()
            })
        }
      }
      else {
        window.alert("Pilih Dokumen Kontrak terlebih dahulu")
        setSubmitting(false)
      }
    }

    const fetchJenisPekerja = () => {
      setLoading({ jenis_pekerja: true })
      RegistrasiKaryawanApi.getJenis()
        .then(({ data }) => setDropdown(prev => ({ ...prev, jenis_pekerja: data?.data?.map(val => ({ value: val.id_jenis_pekerja, label: val.nama_jenis_pekerja })) })))
        .catch(({ res }) => window.alert(res?.data?.message ?? "Gagal memuat dropdown jenis pekerja"))
        .finally(() => setLoading({ jenis_pekerja: false }))
    }
    const fetchStatusPekerja = (id_jenis) => {
      setLoading(prev => ({ ...prev, status_pekerja: true }))
      RegistrasiKaryawanApi.getJenis()
      RegistrasiKaryawanApi.getStatus(id_jenis)
        .then(({ data }) => setDropdown(prev => ({ ...prev, status_pekerja: data?.data?.map(val => ({ value: val.id_status_pekerja, label: val.nama_status_pekerja })) })))
        .catch(({ res }) => window.alert(res?.data?.message ?? "Gagal memuat dropdown status pekerja"))
        .finally(() => setLoading(prev => ({ ...prev, status_pekerja: false })))
    }
    const fetchDetailStatusPekerja = (id_status_pekerja) => {
      setLoading(prev => ({ ...prev, detail_status_pekerja: true }))
      RegistrasiKaryawanApi.getStatusDetail(id_status_pekerja)
        .then(({ data }) => setDropdown(prev => ({ ...prev, detail_status_pekerja: data?.data?.map(val => ({ value: val.id_status_pekerja_detail, label: val.nama_status_pekerja_detail })) })))
        .catch(({ res }) => window.alert(res?.data?.message ?? "Gagal memuat dropdown status pekerja detail"))
        .finally(() => setLoading(prev => ({ ...prev, detail_status_pekerja: false })))
    }

    useEffect(() => {
      fetchJenisPekerja()

      if (isUpdateForm) {
        fetchStatusPekerja(id_jenis_karyawan)
        fetchDetailStatusPekerja(id_status_karyawan)
      }
      setDocument({ link: path_file, dokumen_kontrak: dokumen_kontrak ?? "" })

      return () => { setDocument(null) }
    }, [modalConfig.data])

    return (
      <Modal
        show={modalConfig.show}
        onHide={() => setModalConfig({ show: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="mb-0">{modalConfig.type === 'create' ? "Tambah" : "Ubah"} Data Kontrak </h6>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setValues, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <DatePicker
                  label="Tgl. Mulai Kontrak*"
                  placeholderText="Tgl. Mulai Kontrak"
                  selected={values?.tgl_mulai_kontrak ? new Date(values.tgl_mulai_kontrak) : ''}
                  onChange={date => {
                    const newDate = DateConvert(new Date(date)).default
                    setValues({ ...values, tgl_mulai_kontrak: newDate })
                  }}
                  error={!!(errors.tgl_mulai_kontrak && touched.tgl_mulai_kontrak)}
                  errorText={errors.tgl_mulai_kontrak}
                />
                <DatePicker
                  label="Tgl. Selesai Kontrak*"
                  placeholderText="Tgl. Selesai Kontrak"
                  selected={values?.tgl_selesai_kontrak ? new Date(values.tgl_selesai_kontrak) : ''}
                  onChange={date => {
                    const newDate = DateConvert(new Date(date)).default
                    setValues({ ...values, tgl_selesai_kontrak: newDate })
                  }}
                  error={!!(errors.tgl_selesai_kontrak && touched.tgl_selesai_kontrak)}
                  errorText={errors.tgl_selesai_kontrak}
                />
                <Input
                  label="Nomor Kontrak*"
                  placeholder="Masukkan nomor kontrak"
                  name="no_kontrak"
                  value={values.no_kontrak}
                  onChange={handleChange}
                  error={!!(errors.no_kontrak && touched.no_kontrak)}
                  errorText={errors.no_kontrak}
                />
                <SelectSearch
                  label="Jenis Pekerja*"
                  name="id_jenis_karyawan"
                  placeholder="Pilih jenis pekerja"
                  option={dropdown.jenis_pekerja ?? []}
                  loading={loading.jenis_pekerja}
                  defaultValue={dropdown?.jenis_pekerja?.find(i => i.value === values.id_jenis_karyawan) ?? null}
                  onChange={(val) => {
                    setFieldValue('id_jenis_karyawan', val.value)
                    setFieldValue('id_status_karyawan', "")
                    setFieldValue('id_status_karyawan_detail', "")
                    fetchStatusPekerja(val.value)
                  }}
                  error={!!(errors.id_jenis_karyawan && touched.id_jenis_karyawan)}
                  errorText={!!(errors.id_jenis_karyawan && touched.id_jenis_karyawan) && errors.id_jenis_karyawan}
                />
                <SelectSearch
                  key={`status_${values?.id_jenis_karyawan}`}
                  label="Status Pekerja*"
                  name="id_status_karyawan"
                  placeholder="Pilih status pekerja"
                  option={dropdown?.status_pekerja ?? []}
                  loading={loading.status_pekerja}
                  defaultValue={dropdown?.status_pekerja?.find(i => i.value === values.id_status_karyawan) ?? null}
                  isDisabled={Boolean(!values.id_jenis_karyawan)}
                  onChange={(val) => {
                    setFieldValue('id_status_karyawan', val.value)
                    setFieldValue('id_status_karyawan_detail', "")
                    fetchDetailStatusPekerja(val.value)
                  }}
                  error={!!(errors.id_status_karyawan && touched.id_status_karyawan)}
                  errorText={!!(errors.id_status_karyawan && touched.id_status_karyawan) && errors.id_status_karyawan}
                />
                <SelectSearch
                  key={`detail_${values?.id_status_karyawan}`}
                  label="Detail Status Pekerja*"
                  name="id_status_karyawan_detail"
                  placeholder="Pilih detail status pekerja"
                  option={dropdown?.detail_status_pekerja ?? []}
                  loading={loading.detail_status_pekerja}
                  defaultValue={dropdown?.detail_status_pekerja?.find(i => i.value === values.id_status_karyawan_detail) ?? null}
                  isDisabled={Boolean(!values.id_status_karyawan)}
                  onChange={(val) => setFieldValue('id_status_karyawan_detail', val.value)}
                  error={!!(errors.id_status_karyawan_detail && touched.id_status_karyawan_detail)}
                  errorText={!!(errors.id_status_karyawan_detail && touched.id_status_karyawan_detail) && errors.id_status_karyawan_detail}
                />
                <InputDokumenKontrak type={modalConfig.type} document={document} setDocument={setDocument} />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant={isUpdateForm ? "warning text-white" : "primary"}
                  text={isUpdateForm ? "Ubah" : "Tambah"}
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    )
  }

  const ModalDelete = ({ modalDelete, setModalDelete }) => {
    const [isDeleting, setIsDeleting] = useState(false)

    const deleteDataHandler = () => {
      setIsDeleting(true)

      RegistrasiKaryawanApi.deleteDataKontrak({ id_kontrak: modalDelete.data.id_kontrak })
        .then(() => {
          setModalDelete({ show: false, data: null })
          fetchDataKontrak()
        })
        .catch(({ response }) => window.alert(response.data.message ?? "Data gagal dihapus"))
    }

    return (
      <Modal
        show={modalDelete.show}
        onHide={() => setModalDelete({ show: false, data: null })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="mb-0"> Hapus Data Kontrak </h6>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <h5>
            <span>Hapus data dengan nomor: </span>
            <br />
            <b>{modalDelete?.data?.no_kontrak}</b>
          </h5>
          <small className="text-danger">Data yang dihapus tidak dapat dikembalikan!</small>

          <div className="d-flex justify-content-center mt-3">
            <ActionButton
              variant="outline-secondary"
              className="m-1"
              text="Batal"
              onClick={() => setModalDelete(prev => ({ ...prev, show: false }))}
            />

            <ActionButton
              variant="danger"
              className="m-1"
              text="Hapus Data"
              loading={isDeleting}
              onClick={deleteDataHandler}
            />
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  useEffect(() => {
    fetchDataKontrak()
    return () => { }
  }, [])

  return (
    <div>
      {type !== "DETAIL" && (
        <div className="my-3 d-flex justify-content-end align-items-center">
          <ActionButton text="Tambah" onClick={() => setModalConfig({ show: true, type: "create" })} />
        </div>
      )}

      <table className="table table-bordered bg-white table-sm">
        <thead className="text-center">
          <tr>
            <th style={{ ...tableStyling, width: 40 }}>No.</th>
            {type !== "DETAIL" && (
              <th style={{ ...tableStyling, width: "60px" }}>Aksi</th>
            )}
            <th style={{ ...tableStyling, width: "60px" }}>Nomor Kontrak</th>
            <th style={{ ...tableStyling, width: "60px" }}>Tgl. Mulai Kontrak</th>
            <th style={{ ...tableStyling, width: "60px" }}>Tgl. Selesai Kontrak</th>
            <th style={tableStyling}>Jenis Pekerja</th>
            <th style={tableStyling}>Status Pekerja</th>
            <th style={tableStyling}>Detail Status Pekerja</th>
            <th style={{ ...tableStyling, minWidth: "100px", width: "70px" }}>Dokumen Kontrak</th>
          </tr>
        </thead>
        <tbody>
          {loadingKontrak
            ? (
              <tr>
                <td colSpan={type !== "DETAIL" ? 9 : 8}>
                  <DataStatus loading={loadingKontrak} text="Memuat data..." />
                </td>
              </tr>)
            : (dataKontrak?.length > 0)
              ? dataKontrak?.map((row, index) => (
                <tr key={row.id_kontrak}>
                  <td className="px-2 text-center" style={tableStyling}>
                    {index + 1}
                  </td>
                  {type !== "DETAIL" && (
                    <td style={tableStyling}>
                      <ButtonGroup>
                        <ActionButton
                          size="sm"
                          variant="success"
                          text={<IoPencilOutline />}
                          onClick={() => setModalConfig({ show: true, type: "update", data: row })}
                        />
                        <ActionButton size="sm" variant="danger" text={<IoTrashOutline />} onClick={() => setModalDelete({ show: true, data: row })} />
                      </ButtonGroup>
                    </td>
                  )}
                  <td style={tableStyling}>{row.no_kontrak}</td>
                  <td style={tableStyling}>{DateConvert(new Date(row.tgl_mulai_kontrak)).defaultDMY}</td>
                  <td style={tableStyling}>{DateConvert(new Date(row.tgl_selesai_kontrak)).defaultDMY}</td>
                  <td style={tableStyling}>{row.nama_jenis_pekerja}</td>
                  <td style={tableStyling}>{row.nama_status_pekerja}</td>
                  <td style={tableStyling}>{row.nama_status_pekerja_detail}</td>
                  <td style={{ ...tableStyling, padding: "2px" }}>
                    <ActionButton
                      size="sm"
                      className="btn btn-block text-nowrap"
                      text={
                        <a href={`${process.env.REACT_APP_FILE_UPLOAD_URL}/files/${row?.dokumen_kontrak}`} target='_blank' className="d-flex align-items-center text-white">
                          <div className="ml-1">Dokumen Kontrak</div>
                        </a>
                        // <a href={row?.path_file} target='_blank' className="d-flex align-items-center text-white">
                        //   <div className="ml-1">Dokumen Kontrak</div>
                        // </a>
                      } />
                  </td>
                </tr>
              ))
              : <tr><td colSpan={type !== "DETAIL" ? 9 : 8} className="text-center"> <DataStatus text="Tidak ada data" /></td></tr>
          }
        </tbody>
      </table>
      {modalConfig.show && <ModalForm fetchDataKontrak={fetchDataKontrak} modalConfig={modalConfig} setModalConfig={setModalConfig} />}
      {modalDelete.show && <ModalDelete fetchDataKontrak={fetchDataKontrak} modalDelete={modalDelete} setModalDelete={setModalDelete} />}
    </div>
  )
}

export default Kontrak