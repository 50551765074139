import React, {

} from 'react'
import { 
    Formik 
} from 'formik'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { 
    DateConvert
 } from 'utilities'
import Axios from "axios"

export const ModalFilter = ({ show, setShow, data, setData }) => {
    const dataTahapanSeleksi = ['Review Dokumen', 'Interview Tahap 1', 'Interview Tahap 2', 'Lolos Seleksi']
    const dataStatusSeleksi = ['Menunggu Seleksi', 'Ditolak', 'Diterima', 'Blacklist']

    const formInitialValues = {
        tgl_pelamar_kerja_mulai: data?.filter?.tgl_pelamar_kerja_mulai,
        tgl_pelamar_kerja_selesai: data?.filter?.tgl_pelamar_kerja_selesai,
        tgl_lahir_mulai: data?.filter?.tgl_lahir_mulai,
        tgl_lahir_selesai: data?.filter?.tgl_lahir_selesai,
        tgl_tahap_seleksi_mulai: data?.filter?.tgl_tahap_seleksi_mulai,
        tgl_tahap_seleksi_selesai: data?.filter?.tgl_tahap_seleksi_selesai,
        tgl_status_seleksi_mulai: data?.filter?.tgl_status_seleksi_mulai,
        tgl_status_seleksi_selesai: data?.filter?.tgl_status_seleksi_selesai,
        tahapan_seleksi: data?.filter?.tahapan_seleksi,
        status_seleksi: data?.filter?.status_seleksi,
    }

    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }
    
    const onTanggalChange = (name, dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            [`${name}_mulai`]: startDate,
            [`${name}_selesai`]: endDate,
        })
    }

    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_pelamar_kerja_mulai: undefined,
            tgl_pelamar_kerja_selesai: undefined,
            tgl_lahir_mulai: undefined,
            tgl_lahir_selesai: undefined,
            tgl_tahap_seleksi_mulai: undefined,
            tgl_tahap_seleksi_selesai: undefined,
            tgl_status_seleksi_mulai: undefined,
            tgl_status_seleksi_selesai: undefined,
            tahapan_seleksi: undefined,
            status_seleksi: undefined,
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                        
                    <DatePicker 
                        selectsRange
                        label="Tgl. Pelamar Kerja"
                        placeholderText="Pilih tgl. pelamar kerja"
                        startDate={values.tgl_pelamar_kerja_mulai ? new Date(values.tgl_pelamar_kerja_mulai) : ''}
                        endDate={values.tgl_pelamar_kerja_selesai ? new Date(values.tgl_pelamar_kerja_selesai) : ''}
                        onChange={dates => onTanggalChange('tgl_pelamar_kerja', dates, values, setValues)}
                        monthsShown={2}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Lahir"
                        placeholderText="Pilih tgl. lahir"
                        startDate={values.tgl_lahir_mulai ? new Date(values.tgl_lahir_mulai) : ''}
                        endDate={values.tgl_lahir_selesai ? new Date(values.tgl_lahir_selesai) : ''}
                        onChange={dates => onTanggalChange('tgl_lahir', dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch
                        key={values.tahapan_seleksi}
                        label="Tahapan Seleksi"
                        placeholder="Pilih tahapan seleksi"
                        defaultValue={values.tahapan_seleksi ? dataTahapanSeleksi.map(val => ({label: val, value: val})).find(val => values.tahapan_seleksi === val.value) : ''}
                        option={dataTahapanSeleksi.map(val => ({label: val, value: val}))}
                        onChange={val => setFieldValue('tahapan_seleksi', val.value)}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Tahapan Seleksi"
                        placeholderText="Pilih tgl. tahap seleksi"
                        startDate={values.tgl_tahap_seleksi_mulai ? new Date(values.tgl_tahap_seleksi_mulai) : ''}
                        endDate={values.tgl_tahap_seleksi_selesai ? new Date(values.tgl_tahap_seleksi_selesai) : ''}
                        onChange={dates => onTanggalChange('tgl_tahap_seleksi', dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch
                        key={values.status_seleksi}
                        label="Status Seleksi"
                        placeholder="Pilih status seleksi"
                        defaultValue={values.status_seleksi ? dataStatusSeleksi.map(val => ({label: val, value: val})).find(val => values.status_seleksi === val.value) : ''}
                        option={dataStatusSeleksi.map(val => ({label: val, value: val}))}
                        onChange={val => setFieldValue('status_seleksi', val.value)}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Status Seleksi"
                        placeholderText="Pilih tgl. status seleksi"
                        startDate={values.tgl_status_seleksi_mulai ? new Date(values.tgl_status_seleksi_mulai) : ''}
                        endDate={values.tgl_status_seleksi_selesai ? new Date(values.tgl_status_seleksi_selesai) : ''}
                        onChange={dates => onTanggalChange('tgl_status_seleksi', dates, values, setValues)}
                        monthsShown={2}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}