import Axios from 'axios'

const { REACT_APP_BASE_URL } = process.env
const UploadService = Axios.create({ baseURL: REACT_APP_BASE_URL })

UploadService.interceptors.request.use(config => {
  config.headers = {
    "Content-Type": 'multipart/form-data',
    "Api-Key": 'TUdQQmFuZ3NhdFBhbnRla0FzdQ=='
  }
  return config
})

export default UploadService