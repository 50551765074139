import {
  useState,
  useEffect
} from 'react'
import {
  useHistory, 
  useParams
} from 'react-router-dom'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import {
  BackButton,
  DataStatus,
  ActionButton
} from 'components'
import {
  PelamarKerjaApi
} from 'api'
import {
  DetailPelamarKerja
} from './Comps'
import {
  formInitialValues,
} from './Utils'

const ButtonSection = ({onTerima, onTolak, onBlacklist, terimaLoading, tolakLoading, blacklistLoading, tahap, status}) => {
  const parseTahap = tahap?.toUpperCase()
  const parseStatus = status?.toUpperCase()
  const textTerima = parseStatus === 'DITERIMA' ? parseTahap === 'INTERVIEW TAHAP 1' ? 'TAHAP 2' : parseTahap === 'INTERVIEW TAHAP 2' ? 'LOLOS SELEKSI' : 'TAHAP 1' : 'TERIMA'
  
  return (
    <div>
      {parseStatus !== 'BLACKLIST' &&
        <ActionButton 
          variant="dark"
          className="m-1"
          text="Blacklist"
          onClick={onBlacklist}
          loading={blacklistLoading}
        />
      }
      {parseStatus !== 'BLACKLIST' && parseStatus !== 'DITOLAK' &&
        <ActionButton 
          variant="danger"
          className="m-1"
          text="TOLAK"
          onClick={onTolak}
          loading={tolakLoading}
        />
      }
      {parseStatus !== 'BLACKLIST' && !Boolean(parseTahap === 'LOLOS SELEKSI' && parseStatus === 'DITERIMA')
        ? <ActionButton 
            variant="primary"
            className="m-1"
            text={textTerima}
            onClick={() => onTerima(textTerima)}
            loading={terimaLoading}
          />
        : <></>
      }
    </div>
  )
}

export const PelamarKerjaDetail = ({setNavbarTitle}) => {
  const history = useHistory()
  const { id } = useParams()
  const [detailData, setDetailData] = useState({})
  const [buttonLoading, setButtonLoading] = useState({
    blacklist: false,
    tolak: false,
    terima: false
  })
  const [fetching, setFetching] = useState({
    loading: true,
    error: true
  })

  const getInitialData = () => {
    setFetching({loading: true,error: false})

    PelamarKerjaApi.getSingle({id_pelamar_kerja: id})
      .then((res) => {
        setDetailData(res?.data?.data ?? {})
        setFetching({
          loading: false,
          error: false
        })
      })
      .catch(() => {
        setFetching({
          loading: false,
          error: true
        })
      })

  }

  const onBlacklistHandler = (formik) => {
    const keterangan = formik?.values?.keterangan
    const isError = !keterangan

    if(isError) {
      formik.submitForm()
    } else {
        setButtonLoading({...buttonLoading, blacklist: true})
        PelamarKerjaApi.updateStatus({
          id_pelamar_kerja: id,
          keterangan: keterangan,
          status_seleksi: 'Blacklist'.toUpperCase()
        })
          .then(() => history.push('/human-resource/master/karyawan/pelamar-kerja', { alert: { show: true, variant: 'primary', text: 'Update status pekerja berhasil!' } }))
          .catch(() => history.push('/human-resource/master/karyawan/pelamar-kerja', { alert: { show: true, variant: 'danger', text: 'Update status pekerja gagal!' } }))
      }
    }

  const onTolakHandler = (formik) => {
    const keterangan = formik?.values?.keterangan
    const isError = !keterangan

    if(isError) {
      formik.submitForm()
    } else {
        setButtonLoading({...buttonLoading, tolak: true})
        PelamarKerjaApi.updateStatus({
          id_pelamar_kerja: id,
          keterangan: keterangan,
          status_seleksi: 'Ditolak'.toUpperCase()
        })
          .then(() => history.push('/human-resource/master/karyawan/pelamar-kerja', { alert: { show: true, variant: 'primary', text: 'Update status pekerja berhasil!' } }))
          .catch(() => history.push('/human-resource/master/karyawan/pelamar-kerja', { alert: { show: true, variant: 'danger', text: 'Update status pekerja gagal!' } }))
      }
  }

  const onTerimaHandler = (status, tahap, formik) => {
    const parseTahap = tahap?.toUpperCase()
    const keterangan = formik?.values?.keterangan
    const newTahap = parseTahap === 'REVIEW DOKUMEN' ? 'Interview Tahap 1' : parseTahap === 'INTERVIEW TAHAP 1' ? 'Interview Tahap 2' : 'LOLOS SELEKSI'
    const isError = !keterangan

    if(isError) {
      formik.submitForm()
    } else {
        setButtonLoading({...buttonLoading, terima: true})

        status === 'TERIMA'
          ? PelamarKerjaApi.updateStatus({
              id_pelamar_kerja: id,
              keterangan: keterangan,
              status_seleksi: 'Diterima'.toUpperCase()
            })
              .then(() => history.push('/human-resource/master/karyawan/pelamar-kerja', { alert: { show: true, variant: 'primary', text: 'Update status pekerja berhasil!' } }))
              .catch(() => history.push('/human-resource/master/karyawan/pelamar-kerja', { alert: { show: true, variant: 'danger', text: 'Update status pekerja gagal!' } }))
          : PelamarKerjaApi.updateTahap({
            id_pelamar_kerja: id,
            keterangan: keterangan,
            tahap_seleksi: newTahap.toUpperCase()
          })
            .then(() => history.push('/human-resource/master/karyawan/pelamar-kerja', { alert: { show: true, variant: 'primary', text: 'Update tahap pekerja berhasil!' } }))
            .catch(() => history.push('/human-resource/master/karyawan/pelamar-kerja', { alert: { show: true, variant: 'danger', text: 'Update tahap pekerja gagal!' } }))
    }

  }

  useEffect(() => {
    setNavbarTitle('Pelamar Kerja')
    getInitialData()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <div className="mx-2">Detail Data Pelamar Kerja</div>
        <BackButton 
          size="sm"
          onClick={() => {
            history.replace('/human-resource/master/karyawan/pelamar-kerja', {})
            history.go(-1)
          }}
        />
      </div>
      <div className="bg-white rounded border mt-2 p-3">
        {fetching.loading || fetching.error
          ? <>
              <DataStatus 
                loading={fetching.loading}
                text={fetching.loading ? 'Memuat data . . .' : 'Data gagal dimuat!'}
              />
            </>
          : <Formik
              enableReinitialize
              initialValues={{...formInitialValues(detailData), keterangan: ''}}
              validationSchema={Yup.object().shape({keterangan: Yup.string().required('Keterangan tidak boleh kosong')})}
              onSubmit={(_tes, tea, ya) => console.log(ya)}
            >
              {(formik) => (
                <>
                  <DetailPelamarKerja />
                  <ButtonSection 
                    tolakLoading={buttonLoading?.tolak}
                    blacklistLoading={buttonLoading?.blacklist}
                    terimaLoading={buttonLoading?.terima}
                    tahap={formik.values.tahap_seleksi}
                    status={formik.values.status_seleksi}
                    onBlacklist={() => onBlacklistHandler(formik)}
                    onTolak={() => onTolakHandler(formik)}
                    onTerima={(status) => onTerimaHandler(status, formik?.values?.tahap_seleksi, formik)}
                  />
                </>
              )}
            </Formik>
        }
      </div>
    </>
  )
}
