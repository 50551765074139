import {
  DateConvert,
} from 'utilities'

export const StatusSeleksi = ({status = '', date, textLeft = false}) => {
  switch (status?.toUpperCase()) {
    case 'DITERIMA':
      return (
        <div className={`d-flex flex-column ${textLeft ? 'align-items-start' :'align-items-center'}`}>
          <span className='d-block text-nowrap text-success'>Diterima</span>
          <span className='d-block'>{date ? DateConvert(new Date(date)).defaultDMY : '-'}</span>
        </div>
      )

    case 'DITOLAK':
      return (
        <div className={`d-flex flex-column ${textLeft ? 'align-items-start' :'align-items-center'}`}>
          <span className='d-block text-nowrap text-danger'>Ditolak</span>
          <span className='d-block'>{date ? DateConvert(new Date(date)).defaultDMY : '-'}</span>
        </div>
      )

    case 'BLACKLIST':
      return (
        <div className={`d-flex flex-column ${textLeft ? 'align-items-start' :'align-items-center'}`}>
          <span className='d-block text-nowrap text-dark'>Blacklist</span>
          <span className='d-block'>{date ? DateConvert(new Date(date)).defaultDMY : '-'}</span>
        </div>
      )
  
    default:
      return (
        <div className={`d-flex flex-column ${textLeft ? 'align-items-start' :'align-items-center'}`}>
          <span className='d-block text-nowrap text-warning'>Menunggu Seleksi</span>
          <span className='d-block'>{date ? DateConvert(new Date(date)).defaultDMY : '-'}</span>
        </div>
      )
  }
}