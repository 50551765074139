import Services from "../../../services"
import UploadService from "services/UploadService"

class RegistrasiKaryawanApi {
  getPage(page, dataLength, key) {
    return Services.get(`/hrda/karyawan/page/?per_page=${dataLength}&page=${page}&q=${key}`)
  }
  getOne(id) {
    return Services.get("/hrda/karyawan/single/?id_karyawan=" + id)
  }

  getKode() {
    return Services.get("/hrda/karyawan/no_baru")
  }

  getJenjangPendidikan() {
    return Services.get("/hrda/jenjang_pendidikan/dropdown")
  }

  getBank() {
    return Services.get("/hrda/bank/dropdown")
  }

  getKeluarga(id) {
    return Services.get("/hrda/karyawan/data_keluarga?id_karyawan=" + id)
  }

  search(key) {
    return Services.get("/hrda/karyawan/page/?per_page=10&page=1&q=" + key)
  }

  resetPassword(value) {
    return Services.put("/hrda/karyawan/reset_password", value)
  }

  checkUsername(values) {
    return Services.post("/hrda/karyawan/cek_username", { username: values })
  }

  checkKtp(values) {
    return Services.post("/hrda/karyawan/cek_ktp", { no_ktp: values })
  }

  checkKtp2(values) {
    return Services.post("/hrda/karyawan/cek_ktp", values)
  }

  createDataPribadi(values) {
    return Services.post("/hrda/karyawan", values)
  }

  createDataKepegawaian(values) {
    return Services.post("/hrda/karyawan/data_kepegawaian", values)
  }

  updateDataPribadi(values) {
    return Services.put("/hrda/karyawan", values)
  }

  updateDataBPJS(values) {
    return Services.post("/hrda/karyawan/data_bpjs", values)
  }

  updateDataKontak(values) {
    return Services.post("/hrda/karyawan/data_kontak", values)
  }

  updateDataAlamat(values) {
    return Services.post("/hrda/karyawan/data_alamat", values)
  }

  updateDataKepegawaian(values) {
    return Services.put("/hrda/karyawan/data_kepegawaian", values)
  }

  updateDataBank(values) {
    return Services.post("/hrda/karyawan/data_bank", values)
  }

  updateDataKeluarga(values) {
    return Services.post("/hrda/karyawan/data_keluarga", values)
  }

  updateDataPendidikan(values) {
    return Services.put("/hrda/karyawan/data_pendidikan", values)
  }

  updateKeluarga(values) {
    return Services.put("/hrda/karyawan/data_keluarga", { ...values })
  }

  deleteKeluarga(values) {
    return Services.post("/hrda/karyawan/data_keluarga_delete", values)
  }

  delete(value) {
    return Services.post("/hrda/karyawan/delete", value)
  }

  checkNPWP(values) {
    return Services.post("/hrda/karyawan/cek_no_npwp", { no_npwp: values })
  }

  getUnitProduksi() {
    return Services.get("/hrda/karyawan/unit_produksi")
  }

  checkNPWP2(values) {
    return Services.post("/hrda/karyawan/cek_no_npwp", values)
  }

  getJenis() {
    return Services.get("/hrda/karyawan/jenis_pegawai")
  }

  getStatus(value) {
    return Services.get("/hrda/karyawan/status_pegawai?id_jenis_pekerja=" + value)
  }

  getStatusDetail(value) {
    return Services.get("/hrda/karyawan/status_pegawai_detail?id_status_pekerja=" + value)
  }

  getNo(id, status) {
    return Services.get(`/hrda/karyawan/no_baru?id_karyawan=${id}&id_status_pekerja=${status}`)
  }

  getLokasi() {
    return Services.get(`/hrda/lokasi_kerja/dropdown`)
  }

  updateUser(values) {
    return Services.put("/hrda/karyawan/username", values)
  }

  export() {
    return Services.get("/hrda/karyawan/xlsx")
  }

  checkDuplicate(params) {
    return Services.get("/hrda/karyawan/check_duplicate", { params })
  }

  checkDuplicateNoTLP(params) {
    return Services.get("/hrda/karyawan/cek_no_telp", { params })
  }

  getImportTemplate() {
    return Services.post("/hrda/karyawan_import/template")
  }

  importPekerja(data) {
    return Services.post("/hrda/karyawan_import", data)
  }

  // Data Kontrak 
  getDataKontrak(params) {
    return Services.get("/hrda/karyawan/data_kontrak", { params })
  }

  createDataKontrak(data) {
    return Services.post("/hrda/karyawan/data_kontrak", data)
  }

  updateDataKontrak(data) {
    return Services.put("/hrda/karyawan/data_kontrak", data)
  }

  deleteDataKontrak(data) {
    return Services.post("/hrda/karyawan/delete_kontrak", data)
  }

  uploadDokumenKontrak(data) {
    return UploadService.post("/upload/upload/dokumen_kontrak", data)
  }
}

export default new RegistrasiKaryawanApi()
