import React, { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Input, Select, ActionButton, Alert } from "../../../../../components";
import { RegistrasiKaryawanApi } from "../../../../../api";

const FormDataPribadi = ({ dataKaryawan }) => {
  const [showAlert, setShowAlert] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const formInitialValues = {
    id_karyawan: dataKaryawan.id_karyawan,
    nama_karyawan: dataKaryawan.nama_karyawan,
    no_ktp: dataKaryawan.no_ktp,
    tempat_lahir: dataKaryawan.tempat_lahir,
    tgl_lahir: dataKaryawan.tgl_lahir,
    jenis_kelamin: dataKaryawan.jenis_kelamin,
    agama: dataKaryawan.agama,
    status_perkawinan: dataKaryawan.status_perkawinan,
    id_jabatan: dataKaryawan.id_jabatan,
    umur: dataKaryawan.umur,
    no_kk: dataKaryawan.no_kk,
    golongan_darah: dataKaryawan.golongan_darah,
  };

  const formValidationSchema = Yup.object().shape({
    nama_karyawan: Yup.string()
      .required("Masukan nama Pekerja")
      .test("checkDuplicate", "Nama Pekerja Sudah Didaftarkan", (value) =>
        dataKaryawan.nama_karyawan !== value
          ? RegistrasiKaryawanApi.checkDuplicate({ nama_karyawan: value })
              .then(() => true)
              .catch(() => false)
          : true
      ),
    no_ktp: Yup.string()
      .required("Masukan nomor ktp")
      .test("checkKtp", "Nomor ktp telah didaftarkan", (value) =>
        dataKaryawan.no_ktp === value
          ? true
          : RegistrasiKaryawanApi.checkKtp(value)
              .then(() => true)
              .catch(() => false)
      )
      .nullable(),
    tempat_lahir: Yup.string().required("Masukan tempat lahir").nullable(),
    tgl_lahir: Yup.string().required("Pilih tanggal lahir").nullable(),
    jenis_kelamin: Yup.string().required("Pilih jenis kelamin").nullable(),
    agama: Yup.string().required("Pilih agama").nullable(),
    status_perkawinan: Yup.string().required("Pilih status perkawinan").nullable(),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    RegistrasiKaryawanApi.updateDataPribadi(values)
      .then(() => {
        setShowAlert({
          show: true,
          variant: "primary",
          text: "Ubah data sukses!",
        });
      })
      .catch((err) => {
        setShowAlert({
          show: true,
          variant: "danger",
          text: `Ubah data gagal! (${err})`,
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue }) => {
          
          const getUmur = () => {
            const currentYear = new Date().getFullYear();
            const yearDate = values?.tgl_lahir?.slice(0, 4) ?? '';
            const age = parseInt(currentYear - yearDate);

            return age
          }
          
          return (
            <form className="py-2" onSubmit={handleSubmit}>
              <Alert
                show={showAlert.show}
                showCloseButton={true}
                text={showAlert.text}
                variant={showAlert.variant}
                onClose={() =>
                  setShowAlert({
                    ...showAlert,
                    show: false,
                  })
                }
              />
              <Input
                type="text"
                label="Nama Pekerja"
                placeholder="Masukan nama Pekerja"
                name="nama_karyawan"
                value={values.nama_karyawan}
                error={errors.nama_karyawan && true}
                errorText={errors.nama_karyawan}
                onChange={handleChange}
              />
              <Row>
                <Col lg>
                  <Input
                    type="text"
                    label="No. KTP"
                    placeholder="Masukan nomor ktp"
                    name="no_ktp"
                    value={values.no_ktp}
                    error={errors.no_ktp && true}
                    errorText={errors.no_ktp}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg>
                  <Input
                    type="text"
                    label="No. KK"
                    placeholder="Masukan Nomor KK"
                    name="no_kk"
                    value={values.no_kk}
                    error={errors.no_kk && true}
                    errorText={errors.no_kk}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <Input
                    type="text"
                    label="Tempat Lahir"
                    placeholder="Masukan tempat lahir"
                    name="tempat_lahir"
                    value={values.tempat_lahir}
                    error={errors.tempat_lahir && touched.tempat_lahir && true}
                    errorText={errors.tempat_lahir}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg>
                  <Input
                    type="date"
                    label="Tanggal Lahir"
                    name="tgl_lahir"
                    value={values.tgl_lahir}
                    error={errors.tgl_lahir && touched.tgl_lahir && true}
                    errorText={errors.tgl_lahir}
                    onChange={(val) => {
                      const currentYear = new Date().getFullYear();
                      const yearDate = val.target.value.slice(0, 4);
                      const age = parseInt(currentYear - yearDate);
                      setFieldValue("tgl_lahir", val.target.value);
                      setFieldValue("umur", age);
                    }}
                  />
                </Col>
                <Col lg>
                  <Input
                    type="text"
                    label="Umur"
                    name="umur"
                    readOnly
                    placeholder="Masukan Tanggal Lahir"
                    value={getUmur()}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <Select
                    label="Jenis Kelamin"
                    name="jenis_kelamin"
                    defaultValue={values.jenis_kelamin}
                    error={errors.jenis_kelamin && touched.jenis_kelamin && true}
                    errorText={errors.jenis_kelamin}
                    onChange={handleChange}
                  >
                    <option value="">Pilih jenis kelamin</option>
                    <option value="L">Laki-laki</option>
                    <option value="P">Perempuan</option>
                  </Select>
                </Col>
                <Col lg>
                  <Select
                    label="Agama"
                    name="agama"
                    defaultValue={values.agama}
                    error={errors.agama && touched.agama && true}
                    errorText={errors.agama}
                    onChange={handleChange}
                  >
                    <option value="">Pilih agama</option>
                    <option value="IS">Islam</option>
                    <option value="KP">Kristen Protestan</option>
                    <option value="KK">Kristen Katholik</option>
                    <option value="HD">Hindu</option>
                    <option value="BD">Budha</option>
                    <option value="KO">Khonghucu</option>
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <Select
                    label="Status Perkawinan"
                    name="status_perkawinan"
                    defaultValue={values.status_perkawinan}
                    error={errors.status_perkawinan && touched.status_perkawinan && true}
                    errorText={errors.status_perkawinan}
                    onChange={handleChange}
                  >
                    <option value="">Pilih status perkawinan</option>
                    <option value="BK">Belum Kawin</option>
                    <option value="KW">Kawin</option>
                    <option value="CH">Cerai Hidup</option>
                    <option value="CM">Cerai Mati</option>
                  </Select>
                </Col>
                <Col lg>
                  <Select
                    label="Golongan Darah"
                    name="golongan_darah"
                    defaultValue={values.golongan_darah}
                    error={errors.golongan_darah && touched.golongan_darah && true}
                    errorText={errors.golongan_darah}
                    onChange={handleChange}
                  >
                    <option value="">Pilih Golongan Darah</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="O">O</option>
                    <option value="AB">AB</option>
                  </Select>
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-3">
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah Data Pribadi"
                  loading={isSubmitting}
                />
              </div>
            </form>
          )
        }}
      </Formik>
    </>
  );
};

export default FormDataPribadi;
