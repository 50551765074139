import React, { useState, useEffect } from "react"
import axios from "axios"
import { useHistory, useLocation } from "react-router-dom"
import { Dropdown, Spinner } from "react-bootstrap"
// import FileSaver from "file-saver"
import { saveAs } from 'file-saver'
import {
  CRUDLayout,
  InputSearch,
  TdFixed,
  DataStatus,
  Tr,
  ReadButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  FilterButton,
} from "components"
import { LaporanDurasiKontrakApi } from "api"
import { TableNumber, DateConvert } from "utilities"
import { ModalFilterDurasiKontrak } from "./Section"
import { getLabelSisaDurasi } from "./Helper"

const LaporanDurasiKontrak = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const TITLE = "Laporan Durasi Kontrak"
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`
  }
  const filter = sessionStorage.getItem(SESSION_KEY.FILTER) ? JSON.parse(sessionStorage.getItem(SESSION_KEY.FILTER)) : {}
  const pagination = sessionStorage.getItem(SESSION_KEY.PAGINATION) ? JSON.parse(sessionStorage.getItem(SESSION_KEY.PAGINATION)) : {}

  const [isLoading, setIsLoading] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [searchConfig, setSearchConfig] = useState({ status: false, key: "" })
  const [data, setData] = useState([{}])
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
    data: {}
  })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: filter?.active,
      karyawan: filter?.karyawan,
      jabatan: filter?.jabatan,
      unit_organisasi: filter?.unit_organisasi,
      jenis_karyawan: filter?.jenis_karyawan,
      status_karyawan: filter?.status_karyawan,
      status_karyawan_detail: filter?.status_karyawan_detail,
      tgl_mulai_kontrak_mulai: filter?.tgl_mulai_kontrak_mulai,
      tgl_mulai_kontrak_selesai: filter?.tgl_mulai_kontrak_selesai,
      tgl_selesai_kontrak_mulai: filter?.tgl_selesai_kontrak_mulai,
      tgl_selesai_kontrak_selesai: filter?.tgl_selesai_kontrak_selesai,
      sisa_durasi: filter?.sisa_durasi,
    },
    pagination: {
      page: pagination?.page ?? "1",
      dataLength: pagination?.dataLength ?? "10",
      totalPage: pagination?.totalPage ?? "1",
      dataCount: pagination?.dataCount ?? "0",
    }
  })
  const [dropdown, setDropdown] = useState({
    karyawan: [{ value: undefined, label: 'Semua' },],
    jabatan: [{ value: undefined, label: 'Semua' },],
    unit_organisasi: [{ value: undefined, label: 'Semua' },],
    jenis_karyawan: [{ value: undefined, label: 'Semua' },],
    status_karyawan: [{ value: undefined, label: 'Semua' },],
    status_karyawan_detail: [{ value: undefined, label: 'Semua' },],
    sisa_durasi: [
      { value: undefined, label: 'Semua' },
      { value: "merah", label: '15 Hari  Kebawah' },
      { value: "kuning", label: '30 Hari Kebawah' },
      { value: "hijau", label: '30 Hari Keatas' },
    ],
  })

  const getData = () => {
    setIsLoading(true)

    LaporanDurasiKontrakApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      ...dataFilter?.filter
    })
      .then((res) => {
        setData(res.data.data)
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data.data_count,
            totalPage: res?.data?.total_page
          }
        })
      })
      .catch(() => {
        setAlertConfig({ show: true, variant: "danger", text: "Data gagal dimuat!" })
      })
      .finally(() => setIsLoading(false))
  }

  // fetch dropdown filter
  const fetchDropdown = () => {
    axios.all([
      LaporanDurasiKontrakApi.dropdownKaryawan(),
      LaporanDurasiKontrakApi.dropdownJabatan(),
      LaporanDurasiKontrakApi.dropdownUnitOrgansiasi(),
      LaporanDurasiKontrakApi.dropdownJenisPegawai(),
      LaporanDurasiKontrakApi.dropdownStatusPegawai(),
      LaporanDurasiKontrakApi.dropdownStatusDetail(),
    ])
      .then(axios.spread((resKaryawan, resJabatan, resUnitOrg, resJenisPgw, resStatusPgw, resStatusDetail) => {
        const mapKaryawan = resKaryawan.data.data.map((item) => ({ value: item.id_karyawan, label: item.nama_karyawan }))
        const mapJabatan = resJabatan.data.data.map((item) => ({ value: item.id_jabatan, label: item.nama_jabatan }))
        const mapUnitOrg = resUnitOrg.data.data.map((item) => ({ value: item.id_unit_organisasi, label: item.nama_unit_organisasi }))
        const mapJenisPgw = resJenisPgw.data.data.map(item => ({ value: item.id_jenis_pekerja, label: item.nama_jenis_pekerja }))
        const mapStatusPgw = resStatusPgw.data.data.map(item => ({ value: item.id_status_pekerja, label: item.nama_status_pekerja }))
        const mapStatusDetail = resStatusDetail.data.data.map(item => ({ value: item.id_status_pekerja_detail, label: item.nama_status_pekerja_detail }))

        setDropdown((prev) => ({
          ...prev,
          karyawan: prev?.karyawan?.concat(mapKaryawan ?? []),
          jabatan: prev?.jabatan?.concat(mapJabatan ?? []),
          unit_organisasi: prev?.unit_organisasi?.concat(mapUnitOrg ?? []),
          jenis_karyawan: prev?.jenis_karyawan?.concat(mapJenisPgw ?? []),
          status_karyawan: prev?.status_karyawan?.concat(mapStatusPgw ?? []),
          status_karyawan_detail: prev?.status_karyawan_detail?.concat(mapStatusDetail ?? []),
        }))
      }))
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    })
    setAlertConfig({
      show: true,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  const handleExport = tipe => {
    setLoadingExport(true)

    LaporanDurasiKontrakApi.export({
      ...dataFilter.filter,
      active: undefined,
      tipe,
    })
      .then(res => {
        const link = res?.data?.data
        // const name = res.data.data.split('/').pop()
        // FileSaver.saveAs(data, name)
        const parse = 'https://' + link.split('/').filter((_val, idx) => idx !== 0).filter((_val, idx) => idx !== 0).join('/')
        saveAs(parse, "Laporan Durasi Kontrak")
      }).catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        })
      }).finally(() => setLoadingExport(false))
  }

  useEffect(() => {
    fetchDropdown()
    return () => setDropdown({})
  }, [])

  useEffect(() => {
    setNavbarTitle(TITLE)
    getData()

    return () => { setIsLoading(false) }
  }, [setNavbarTitle, searchConfig.key, dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, dataFilter?.filter])

  const Table = () => {

    return (
      <>
        <div className="font-weight-bold mb-2">List Data Sisa Durasi Kontrak</div>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Nama Pekerja</Th>
              <Th>Jabatan</Th>
              <Th>Unit Organisasi</Th>
              <Th>Jenis Pekerja</Th>
              <Th>Status Pekerja</Th>
              <Th>Detail Status Pekerja</Th>
              <ThFixed>Tgl. Mulai Kontrak</ThFixed>
              <ThFixed>Tgl. Selesai Kontrak</ThFixed>
              <ThFixed>Sisa Durasi Kontrak</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={val.id_penerimaan_barang}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="d-flex flex-row">
                    <ReadButton
                      onClick={() => {
                        sessionStorage.setItem(SESSION_KEY.FILTER, JSON.stringify(dataFilter?.filter))
                        sessionStorage.setItem(SESSION_KEY.PAGINATION, JSON.stringify(dataFilter?.pagination))

                        history.push(`/human-resource/laporan/sisa-durasi-kontrak/detail/${val.id_kontrak}`)
                      }}
                    />
                  </div>
                </TdFixed>
                <Td>{val.nama_karyawan}</Td>
                <Td>{val.nama_jabatan}</Td>
                <Td>{val.nama_unit_organisasi}</Td>
                <Td>{val.nama_jenis_pekerja}</Td>
                <Td>{val.nama_status_pekerja}</Td>
                <Td>{val.nama_status_pekerja_detail}</Td>
                <Td>{val.tgl_mulai_kontrak ? DateConvert(new Date(val.tgl_mulai_kontrak)).defaultDMY : "-"}</Td>
                <Td>{val.tgl_selesai_kontrak ? DateConvert(new Date(val.tgl_selesai_kontrak)).defaultDMY : "-"}</Td>
                <Td>{getLabelSisaDurasi(parseInt(val.sisa_durasi ?? 0))}</Td>
              </Tr>
            ))} </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1}
          dataPage={dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength ? dataFilter?.pagination?.dataCount : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength}
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) => setDataFilter({ ...dataFilter, pagination: { ...dataFilter.pagination, page: selected + 1, } })}
          onDataLengthChange={(e) => setDataFilter({ ...dataFilter, pagination: { ...dataFilter.pagination, page: 1, dataLength: e.target.value, } })}
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
            <Dropdown>
              <Dropdown.Toggle className="ml-2 mb-2 px-3 btn btn-warning text-white" disabled={loadingExport}> {loadingExport ? <Spinner animation="border" variant="white" size="sm" /> : "Export"} </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleExport("excel")}>
                  Excel (.xlsx)
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleExport("pdf")}>
                  PDF (.pdf)
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </CRUDLayout.HeadSearchSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig(prev => ({ ...prev, show: false }))}
      />

      {/* Table Section */}
      {isLoading === true
        ? (<DataStatus loading={true} text="Memuat data..." />)
        : data?.length > 0
          ? <Table />
          : (<DataStatus text="Tidak ada data" />)
      }

      {/* Modal Filter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterDurasiKontrak
          SESSION_KEY={SESSION_KEY}
          dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default LaporanDurasiKontrak
