import { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  Card,
  Row,
  Col
} from "react-bootstrap"
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  InfoItemVertical
} from "components"
import { LaporanDurasiKontrakApi } from "api"
import { DateConvert } from "utilities"
import { getLabelSisaDurasi } from "./Helper"

const LaporanDurasiKontrakDetail = ({ setNavbarTitle }) => {
  const { id: id_kontrak } = useParams()
  const history = useHistory()
  const [dataInfo, setDataInfo] = useState({})
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const getInitialData = () => {
    setFetchingStatus({ loading: true, success: false })

    LaporanDurasiKontrakApi.getSingle({ id_kontrak })
      .then((res) => {
        setDataInfo(res.data.data ?? {})
        setFetchingStatus({ loading: false, success: true })
      })
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({ loading: false, success: false })
      })
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle("Laporan Durasi Kontrak")
  }, [])

  return (
    <>
      <div className="mb-2 d-flex justify-content-between align-items-end">
        <b>Detail Sisa Durasi Kontrak Pekerja</b>
        <BackButton onClick={() => history.goBack()} />
      </div>

      <Card>
        <Card.Body className="pb-5">
          {fetchingStatus.loading
            ? <DataStatus loading text="Memuat . . ." />
            : fetchingStatus.success
              ? (
                <Row>
                  <Col lg={4}>
                    <InfoItemVertical label="Nama Pekerja" text={dataInfo.nama_karyawan} />
                    <InfoItemVertical label="Unit Produksi" text={dataInfo.nama_unit_organisasi} />
                    <InfoItemVertical label="Status Pekerja" text={dataInfo.nama_status_pekerja} />
                    <InfoItemVertical label="Tgl. Mulai Kontrak" text={dataInfo.tgl_mulai_kontrak ? DateConvert(new Date(dataInfo.tgl_mulai_kontrak)).detail : "-"} />
                    <InfoItemVertical label="Sisa Durasi Kontrak" text={getLabelSisaDurasi(parseInt(dataInfo.sisa_durasi ?? 0))} />
                  </Col>

                  <Col lg={4}>
                    <InfoItemVertical label="Jabatan" text={dataInfo.nama_jabatan} />
                    <InfoItemVertical label="Jenis Pekerja" text={dataInfo.nama_jenis_pekerja} />
                    <InfoItemVertical label="Detail Status Pekerja" text={dataInfo.nama_status_pekerja_detail} />
                    <InfoItemVertical label="Tgl. Selesai Kontrak" text={dataInfo.tgl_selesai_kontrak ? DateConvert(new Date(dataInfo.tgl_selesai_kontrak)).detail : "-"} />
                  </Col>

                  <Col lg={4}>
                    <InfoItemVertical label="Dokumen Kontrak" text={
                      <ActionButton
                        size="sm"
                        className="mt-2"
                        text={
                          <a href={`${process.env.REACT_APP_FILE_UPLOAD_URL}/files/${dataInfo?.dokumen_kontrak}`} target='_blank' className="d-flex align-items-center text-white">
                            <div className="ml-1">Lihat Dokumen Kontrak</div>
                          </a>
                        }
                      />
                    } />
                  </Col>
                </Row>
              )
              : <DataStatus text="Data gagal dimuat" />
          }
        </Card.Body>
      </Card>
    </>
  )
}

export default LaporanDurasiKontrakDetail