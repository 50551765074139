import Services from "../../../services";

class HakAksesApprovalApi {
  get() {
    return Services.get("/hrda/hak_akses/?tipe_hak_akses=approval&kode_hak_akses_parent=HRDU");
  }

  getModal(tipe, hak) {
    return Services.get(
      `/hrda/hak_akses/?tipe_hak_akses=${tipe}&kode_hak_akses_parent=${hak}&show_owned=1`
    );
  }

  getHakAkses() {
    return Services.get("/hrda/hak_akses/?tipe_hak_akses=approval");
  }

  create(value) {
    return Services.post("/hrda/hak_akses/jabatan_multi/", value);
  }

  // <----------API HAK AKSES APPROVAL NEW----------->

  // GET PAGE HAK AKSES APPROVAL RAB
  getHak(params) {
    return Services.get("hrda/hak_akses_approval/page", { params });
  }

  // GET DROPDOWN JABATAN
  getJabatan() {
    return Services.get("/hrda/jabatan/dropdown");
  }

  // GET DETAIL HAK AKSES
  getSingle(kode_transaksi) {
    return Services.get(`/hrda/hak_akses_approval/single/?kode_transaksi=${kode_transaksi}`);
  }

  // SET HAK AKSES
  setHakAkses(data) {
    return Services.post("hrda/hak_akses_approval/set", data);
  }
}

export default new HakAksesApprovalApi();
