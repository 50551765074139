export const formInitialValues = (data = {}) => ({
  nama_pelamar_kerja: data?.nama_pelamar_kerja ?? '',
  nik: data?.nik ?? '',
  tgl_lahir: data?.tgl_lahir ?? '',
  tgl_pelamar_kerja: data?.tgl_pelamar_kerja ?? '',
  kode_pelamar_kerja: data?.kode_pelamar_kerja ?? '',
  alamat: data?.alamat ?? '',
  pendidikan_terakhir: data?.pendidikan_terakhir ?? '',
  institusi: data?.institusi ?? '',
  jurusan: data?.jurusan ?? '',
  no_telepon: data?.no_telepon ?? '',
  email: data?.email ?? '',
  posisi: data?.posisi ?? '',
  path_file_ktp: data?.link_ktp,
  path_file_cv: data?.link_cv,
  link_file_ktp: data?.path_file_ktp,
  link_file_cv: data?.path_file_cv,
  data_file_ktp: undefined,
  data_file_cv: undefined,
  name_file_ktp: undefined,
  name_file_cv: undefined,
  tgl_pelamar_kerja: data?.tgl_pelamar_kerja,
  tgl_status_seleksi: data?.tgl_status_seleksi,
  tgl_tahap_seleksi: data?.tgl_tahap_seleksi,
  status_seleksi: data?.status_seleksi,
  tahap_seleksi: data?.tahap_seleksi
})