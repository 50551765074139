// React
import React, { useState, useEffect } from "react";

// Component
import { Row, Col } from "react-bootstrap";
import { Input, Select, ActionButton, SelectSearch } from "../../../../../components";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// API
import { RegistrasiKaryawanApi } from "../../../../../api";
import axios from "axios";

const FormDataKepegawaian = ({ setIdKaryawan, setAlertHandler, dataJabatan, setPage, kode }) => {
  console.log(kode);
  const [formDataPekerja, setFormDataPekerja] = useState({
    jenis: [],
    unit_produksi: [],
    status: [],
    status_detail: [],
  });
  const [isLoading, setIsLoading] = useState({
    status: false,
    status_detail: false,
  });
  const [formDataLokasi, setFormDataLokasi] = useState({
    lokasi: [],
  });

  const getLokasi = () => {
    RegistrasiKaryawanApi.getLokasi().then((res) => {
      setFormDataLokasi({
        lokasi: res.data.data,
      });
    });
  };

  const getJenis = () => {
    axios.all([RegistrasiKaryawanApi.getJenis(), RegistrasiKaryawanApi.getUnitProduksi()]).then(
      axios.spread((res, unit_produksi) => {
        setFormDataPekerja({
          jenis: res.data.data,
          unit_produksi: unit_produksi.data.data,
          status: [],
          status_detail: [],
        });
      })
    );
  };

  const getStatus = (value) => {
    setIsLoading({
      status: true,
      status_detail: true,
    });

    RegistrasiKaryawanApi.getStatus(value)
      .then((res) => {
        setFormDataPekerja({
          ...formDataPekerja,
          status: res.data.data,
          status_detail: [],
        });
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading({
          status: false,
          status_detail: false,
        });
      });
  };

  const getStatusDetail = (value) => {
    setIsLoading({
      status_detail: true,
    });

    RegistrasiKaryawanApi.getStatusDetail(value)
      .then((res) => {
        setFormDataPekerja({
          ...formDataPekerja,
          status_detail: res.data.data,
        });
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading({
          status_detail: false,
        });
      });
  };

  useEffect(() => {
    getJenis();
    getLokasi();
  }, []);

  const formInitialValues = {
    no_npwp: "",
    tgl_bergabung: "",
    tgl_pengangkatan: "",
    id_jabatan: "",
    id_jenis_pekerja: "",
    nama_jenis_pekerja: "",
    id_status_pekerja: "",
    nama_status_pekerja: "",
    id_status_pekerja_detail: "",
    nama_status_pekerja_detail: "",
    id_lokasi_kerja: "",
    nama_lokasi_kerja: "",
    username: "",
    password: "",
    password_confirmation: "",
    nama_karyawan: "",
    no_ktp: "",
    tempat_lahir: "",
    tgl_lahir: "",
    jenis_kelamin: "",
    agama: "",
    status_perkawinan: "",
    id_unit_produksi: "",
    no_karyawan: kode,
    keterangan: "",
    tgl_selesai: "",
    umur: "",
    no_kk: "",
    golongan_darah: "",
  };

  const formValidationSchema = Yup.object().shape({
    no_npwp: Yup.string()
      .test("checkNPWP", "Nomor NPWP telah didaftarkan", (value) =>
        RegistrasiKaryawanApi.checkNPWP2({ no_npwp: value })
          .then(() => true)
          .catch(() => false)
      )
      .nullable(),
    id_jenis_pekerja: Yup.string().required("Pilih Nama Unit Produksi"),
    id_status_pekerja: Yup.string().required("Pilih Status Pekerja"),
    id_jabatan: Yup.string().required("Pilih Jabatan"),
    id_lokasi_kerja: Yup.string().required("Pilih Lokasi Kerja"),
    username: Yup.string()
      .test("checkUsername", "Username tidak dapat digunakan", (value) =>
        RegistrasiKaryawanApi.checkUsername(value)
          .then(() => true)
          .catch(() => false)
      )
      .required("Masukan username"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Konfirmasi Password tidak sama dengan Password"
    ),
    nama_karyawan: Yup.string()
      .required("Masukan nama Pekerja")
      .test("checkDuplicate", "Nama Pekerja Sudah Didaftarkan", (value) =>
        RegistrasiKaryawanApi.checkDuplicate({ nama_karyawan: value })
          .then(() => true)
          .catch(() => false)
      ),
    no_ktp: Yup.string()
      .test("checkKtp", "Nomor ktp telah didaftarkan", (value) =>
        RegistrasiKaryawanApi.checkKtp2({ no_ktp: value })
          .then(() => true)
          .catch(() => false)
      )
      .required("Masukan nomor ktp"),
    tempat_lahir: Yup.string().required("Masukan tempat lahir"),
    tgl_lahir: Yup.string().required("Pilih tanggal lahir"),
    jenis_kelamin: Yup.string().required("Pilih jenis kelamin"),
    agama: Yup.string().required("Pilih agama"),
    status_perkawinan: Yup.string().required("Pilih status perkawinan"),
    tgl_bergabung: Yup.string().required("Tanggal Bergabung wajib diisi"),
    tgl_pengangkatan: Yup.string().required("Tanggal Pengangkatan wajib diisi"),
    id_unit_produksi: Yup.string().required("Pilih Unit Produksi"),
    keterangan: Yup.string().required("Masukan Keterangan"),
    tgl_selesai: Yup.string().required("Masukan Tanggal Selesai"),
  });

  const formSubmitHandler = (values) => {
    let id_status_pekerja_detail =
      values.id_status_pekerja_detail == "" ? null : values.id_status_pekerja_detail;

    RegistrasiKaryawanApi.createDataPribadi({
      username: values.username,
      password: values.password,
      password_confirmation: values.password_confirmation,
      nama_karyawan: values.nama_karyawan,
      no_ktp: values.no_ktp,
      tempat_lahir: values.tempat_lahir,
      tgl_lahir: values.tgl_lahir,
      jenis_kelamin: values.jenis_kelamin,
      agama: values.agama,
      status_perkawinan: values.status_perkawinan,
      id_jabatan: values.id_jabatan,
      id_status_pekerja: values.id_status_pekerja,
      umur: values.umur,
      no_kk: values.no_kk,
      golongan_darah: values.golongan_darah,
    })
      .then((res) => {
        RegistrasiKaryawanApi.createDataKepegawaian({
          id_karyawan: res.data.id_karyawan,
          no_npwp: values.no_npwp,
          id_lokasi_kerja: values.id_lokasi_kerja,
          tgl_bergabung: values.tgl_bergabung,
          tgl_pengangkatan: values.tgl_pengangkatan,
          id_jabatan: values.id_jabatan,
          id_jenis_pekerja: values.id_jenis_pekerja,
          id_status_pekerja: values.id_status_pekerja,
          id_status_pekerja_detail: id_status_pekerja_detail,
          id_unit_produksi: values.id_unit_produksi,
          no_karyawan: values.no_karyawan,
          keterangan: values.keterangan,
          tgl_selesai: values.tgl_selesai,
        });
        setIdKaryawan(res.data.id_karyawan);
        setPage(2);
      })
      .catch((err) =>
        setAlertHandler({
          show: true,
          variant: "danger",
          text: `Tambah data karyawan gagal! (${err})`,
        })
      );
  };

  const ColumnData = ({ title, className, children }) => (
    <div className={className ? className : "mb-4"}>
      <h6
        className="ml-1 text-dark"
        style={{
          fontSize: "17px",
          marginBottom: "12.5px",
        }}
      >
        <b>{title}</b>
      </h6>
      <div className="bg-white p-2 px-3 shadow-sm rounded">{children}</div>
    </div>
  );

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="py-2">
            <ColumnData title="Data Akun">
              <Input
                type="text"
                label="Username"
                placeholder="Masukan username"
                name="username"
                value={values.username}
                error={errors.username && true}
                errorText={errors.username}
                onChange={handleChange}
              />
              <Input
                type="password"
                label="Password"
                placeholder="Password menjadi '123456' jika kosong"
                name="password"
                value={values.password}
                error={errors.password && true}
                errorText={errors.password}
                onChange={handleChange}
              />
              <Input
                type="password"
                label="Konfirmasi Password"
                name="password_confirmation"
                value={values.password_confirmation}
                error={errors.password_confirmation && touched.password_confirmation && true}
                errorText={errors.password_confirmation}
                onChange={handleChange}
              />
            </ColumnData>

            <ColumnData title="Data Pribadi">
              <Input
                type="text"
                label="Nama Pekerja"
                name="nama_karyawan"
                placeholder="Masukan Nama Pekerja"
                value={values.nama_karyawan}
                error={errors.nama_karyawan && true}
                errorText={errors.nama_karyawan}
                onChange={handleChange}
              />
              <Row>
                <Col lg>
                  <Input
                    type="text"
                    label="No. KTP"
                    name="no_ktp"
                    placeholder="Masukan No. KTP"
                    value={values.no_ktp}
                    error={errors.no_ktp && true}
                    errorText={errors.no_ktp}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg>
                  <Input
                    type="text"
                    label="No. KK"
                    name="no_kk"
                    placeholder="Masukan No. KK"
                    value={values.no_kk}
                    error={errors.no_kk && true}
                    errorText={errors.no_kk}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <Input
                    type="text"
                    label="Tempat Lahir"
                    placeholder="Masukan tempat lahir"
                    name="tempat_lahir"
                    value={values.tempat_lahir}
                    error={errors.tempat_lahir && touched.tempat_lahir && true}
                    errorText={errors.tempat_lahir}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg>
                  <Input
                    type="date"
                    label="Tanggal Lahir"
                    name="tgl_lahir"
                    value={values.tgl_lahir}
                    error={errors.tgl_lahir && touched.tgl_lahir && true}
                    errorText={errors.tgl_lahir}
                    onChange={(val) => {
                      const currentYear = new Date().getFullYear();
                      const yearDate = val.target.value.slice(0, 4);
                      const age = parseInt(currentYear - yearDate);
                      setFieldValue("tgl_lahir", val.target.value);
                      setFieldValue("umur", age);
                    }}
                  />
                </Col>
                <Col lg>
                  <Input
                    type="text"
                    label="Umur"
                    name="umur"
                    readOnly
                    placeholder="Masukan Tanggal Lahir"
                    value={values.umur}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <Select
                    label="Jenis Kelamin"
                    name="jenis_kelamin"
                    defaultValue={values.jenis_kelamin}
                    error={errors.jenis_kelamin && touched.jenis_kelamin && true}
                    errorText={errors.jenis_kelamin}
                    onChange={handleChange}
                  >
                    <option value="">Pilih jenis kelamin</option>
                    <option value="L">Laki-laki</option>
                    <option value="P">Perempuan</option>
                  </Select>
                </Col>
                <Col lg>
                  <Select
                    label="Agama"
                    name="agama"
                    defaultValue={values.agama}
                    error={errors.agama && touched.agama && true}
                    errorText={errors.agama}
                    onChange={handleChange}
                  >
                    <option value="">Pilih agama</option>
                    <option value="IS">Islam</option>
                    <option value="KP">Kristen Protestan</option>
                    <option value="KK">Kristen Katholik</option>
                    <option value="HD">Hindu</option>
                    <option value="BD">Budha</option>
                    <option value="KO">Khonghucu</option>
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <Select
                    label="Status Perkawinan"
                    name="status_perkawinan"
                    defaultValue={values.status_perkawinan}
                    error={errors.status_perkawinan && touched.status_perkawinan && true}
                    errorText={errors.status_perkawinan}
                    onChange={handleChange}
                  >
                    <option value="">Pilih status perkawinan</option>
                    <option value="BK">Belum Kawin</option>
                    <option value="KW">Kawin</option>
                    <option value="CH">Cerai Hidup</option>
                    <option value="CM">Cerai Mati</option>
                  </Select>
                </Col>
                <Col lg>
                  <Select
                    label="Golongan Darah"
                    name="golongan_darah"
                    defaultValue={values.golongan_darah}
                    error={errors.golongan_darah && touched.golongan_darah && true}
                    errorText={errors.golongan_darah}
                    onChange={handleChange}
                  >
                    <option value="">Pilih Golongan Darah</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="O">O</option>
                    <option value="AB">AB</option>
                  </Select>
                </Col>
              </Row>
            </ColumnData>

            <ColumnData title="Data Pekerja">
              <Row>
                <Col lg>
                  <Input
                    type="text"
                    label="No. NPWP"
                    name="no_npwp"
                    value={values.no_npwp}
                    error={errors.no_npwp && true}
                    errorText={errors.no_npwp}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg>
                  <SelectSearch
                    label="Nama Unit Produksi"
                    name="id_jenis_pekerja"
                    defaultValue={{
                      label: values.nama_jenis_pekerja
                        ? values.nama_jenis_pekerja
                        : "Pilih Nama Unit Produksi",
                      value: values.id_jenis_pekerja ? values.id_jenis_pekerja : "",
                    }}
                    onChange={(val) => {
                      setFieldValue("id_jenis_pekerja", val.value);
                      setFieldValue("nama_jenis_pekerja", val.label);
                      setFieldValue("id_status_pekerja", "");
                      setFieldValue("nama_status_pekerja", "");
                      setFieldValue("id_status_pekerja_detail", "");
                      setFieldValue("nama_status_pekerja_detail", "");

                      getStatus(val.value);
                    }}
                    option={formDataPekerja.jenis.map((val) => {
                      return {
                        value: val.id_jenis_pekerja,
                        label: val.nama_jenis_pekerja,
                      };
                    })}
                    error={errors.id_jenis_pekerja && touched.id_jenis_pekerja && true}
                    errorText={
                      errors.id_jenis_pekerja && touched.id_jenis_pekerja && errors.id_jenis_pekerja
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <Input
                    type="text"
                    label="No. Induk Karyawan"
                    name="no_karyawan"
                    value={values.no_karyawan}
                    error={errors.no_karyawan && touched.no_karyawan && true}
                    errorText={errors.no_karyawan}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg>
                  <Select
                    label="Unit Produksi"
                    name="id_unit_produksi"
                    defaultValue={values.id_unit_produksi}
                    error={errors.id_unit_produksi && touched.id_unit_produksi && true}
                    errorText={errors.id_unit_produksi}
                    onChange={handleChange}
                  >
                    <option value="">Pilih Unit Produksi</option>
                    {formDataPekerja.unit_produksi.map((res, index) => (
                      <option key={index} value={res.id_unit_produksi}>
                        {res.nama_unit_produksi}
                      </option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <SelectSearch
                    label="Status Pekerja"
                    name="id_status_pekerja"
                    defaultValue={{
                      label: values.nama_status_pekerja
                        ? values.nama_status_pekerja
                        : "Pilih Status Pekerja",
                      value: values.id_status_pekerja ? values.id_status_pekerja : "",
                    }}
                    onChange={(val) => {
                      setFieldValue("id_status_pekerja", val.value);
                      setFieldValue("nama_status_pekerja", val.label);
                      setFieldValue("id_status_pekerja_detail", "");
                      setFieldValue("nama_status_pekerja_detail", "");

                      getStatusDetail(val.value);
                    }}
                    option={formDataPekerja.status.map((val) => {
                      return {
                        value: val.id_status_pekerja,
                        label: val.nama_status_pekerja,
                      };
                    })}
                    error={errors.id_status_pekerja && touched.id_status_pekerja && true}
                    errorText={
                      errors.id_status_pekerja &&
                      touched.id_status_pekerja &&
                      errors.id_status_pekerja
                    }
                    isDisabled={values.id_jenis_pekerja == "" ? true : false}
                    loading={isLoading.status}
                  />
                </Col>
                <Col lg="6">
                  <SelectSearch
                    label="Status Detail Pekerja"
                    name="id_status_pekerja_detail"
                    defaultValue={{
                      label: values.nama_status_pekerja_detail
                        ? values.nama_status_pekerja_detail
                        : "Pilih Status Detail Pekerja",
                      value: values.id_status_pekerja_detail ? values.id_status_pekerja_detail : "",
                    }}
                    onChange={(val) => {
                      setFieldValue("id_status_pekerja_detail", val.value);
                      setFieldValue("nama_status_pekerja_detail", val.label);
                    }}
                    option={formDataPekerja.status_detail.map((val) => {
                      return {
                        value: val.id_status_pekerja_detail,
                        label: val.nama_status_pekerja_detail,
                      };
                    })}
                    error={
                      errors.id_status_pekerja_detail && touched.id_status_pekerja_detail && true
                    }
                    errorText={
                      errors.id_status_pekerja_detail &&
                      touched.id_status_pekerja_detail &&
                      errors.id_status_pekerja_detail
                    }
                    isDisabled={values.id_status_pekerja == "" ? true : false}
                    loading={isLoading.status_detail}
                  />
                </Col>
                <Col lg="6">
                  <SelectSearch
                    label="Lokasi Kerja"
                    name="id_lokasi_kerja"
                    defaultValue={{
                      label: values.nama_lokasi_kerja
                        ? values.nama_lokasi_kerja
                        : "Pilih Lokasi Kerja",
                      value: values.id_lokasi_kerja ? values.id_lokasi_kerja : "",
                    }}
                    onChange={(val) => {
                      setFieldValue("id_lokasi_kerja", val.value);
                      setFieldValue("nama_lokasi_kerja", val.label);
                    }}
                    option={formDataLokasi.lokasi.map((val) => {
                      return {
                        value: val.id_lokasi_kerja,
                        label: val.nama_lokasi_kerja,
                      };
                    })}
                    error={errors.id_lokasi_kerja && touched.id_lokasi_kerja && true}
                    errorText={
                      errors.id_lokasi_kerja && touched.id_lokasi_kerja && errors.id_lokasi_kerja
                    }
                  />
                </Col>
                <Col lg="6">
                  <Select
                    label="Jabatan"
                    name="id_jabatan"
                    defaultValue={values.id_jabatan}
                    error={errors.id_jabatan && touched.id_jabatan && true}
                    errorText={errors.id_jabatan}
                    onChange={handleChange}
                  >
                    <option value="">Pilih jabatan</option>
                    {dataJabatan.map((val, index) => (
                      <option key={index} value={val.id_jabatan}>
                        {val.nama_jabatan}
                      </option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row>
                <Col lg>
                  <Input
                    type="date"
                    label="Tanggal Bergabung"
                    name="tgl_bergabung"
                    value={values.tgl_bergabung}
                    error={errors.tgl_bergabung && touched.tgl_bergabung && true}
                    errorText={errors.tgl_bergabung}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg>
                  <Input
                    type="date"
                    label="Tanggal Pengangkatan"
                    name="tgl_pengangkatan"
                    value={values.tgl_pengangkatan}
                    error={errors.tgl_pengangkatan && touched.tgl_pengangkatan && true}
                    errorText={errors.tgl_pengangkatan}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <Input
                    label="Keterangan"
                    type="text"
                    name="keterangan"
                    error={errors.keterangan && touched.keterangan && true}
                    errorText={errors.keterangan}
                    onChange={handleChange}
                    placeholder="Masukan Keterangan"
                  />
                </Col>
                <Col lg>
                  <Input
                    type="date"
                    label="Tanggal Selesai"
                    name="tgl_selesai"
                    value={values.tgl_selesai}
                    error={errors.tgl_selesai && touched.tgl_selesai && true}
                    errorText={errors.tgl_selesai}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </ColumnData>
            <div className="d-flex justify-content-end pt-3">
              <ActionButton
                type="submit"
                variant="primary"
                text="Tambah Data Kepegawaian"
                loading={isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FormDataKepegawaian;
