import Services from "../../../services";
import ServicesFiles from "../../../servicesFiles";

class PelamarKerjaApi {
  get(params) {
    return Services.get("/hrda/pelamar_kerja/page", { params });
  }
  getSingle(params) {
    return Services.get("/hrda/pelamar_kerja/single", { params });
  }

  getKode(params) {
    return Services.get("/hrda/pelamar_kerja/kode_pelamar", { params });
  }

  single(params) {
    return Services.get("/hrda/pelamar_kerja/single", { params });
  }

  create(value) {
    return Services.post("/hrda/pelamar_kerja", value);
  }

  saveFile(value) {
    return ServicesFiles.post("/upload/upload/pelamar_kerja", value);
  }

  update(value) {
    return Services.put("/hrda/pelamar_kerja", value);
  }

  updateStatus(value) {
    return Services.put("/hrda/pelamar_kerja/set_status", value);
  }

  updateTahap(value) {
    return Services.put("/hrda/pelamar_kerja/set_tahap", value);
  }

  delete(value) {
    return Services.post("/hrda/pelamar_kerja/delete", value);
  }
}

export default new PelamarKerjaApi();
