import Axios from 'axios'

const {REACT_APP_BASE_URL} = process.env

const Services = Axios.create({
  baseURL: REACT_APP_BASE_URL,
})

Services.interceptors.request.use(config => {
  const token = 'Bearer ' + sessionStorage.getItem('token')

  config.headers = {
    'Authorization': token,
    'Content-Type': 'application/json',
    'Api-Key': 'TUdQQmFuZ3NhdFBhbnRla0FzdQ=='
  }

  return config
})

export default Services