import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Axios from "axios";
import {
  SelectSearch,
  Switch,
  ActionButton,
  DataStatus,
  Alert,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
  ThFixed,
  TdFixed,
  BackButton,
} from "../../../../components";
import { HakAksesDivisiApi } from "../../../../api";

const HakAksesDivisi = ({
  dataAksi,
  setAlertConfig,
  setIsCreateForm,
  setIsUpdateForm,
  getInitialData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [idDivisi, setIdDivisi] = useState("");
  const [inputDisabled, setInputDisabled] = useState(false);
  const [dataKaryawan, setDataKaryawan] = useState([]);
  const [dataDivisi, setDataDivisi] = useState([]);
  const [dataId, setDataId] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const getData = () => {
    setIsLoading(true);

    // request data ke server
    Axios.all([HakAksesDivisiApi.getDropdown(), HakAksesDivisiApi.getDivisi()])
      .then(
        Axios.spread((karyawan, divisi) => {
          setDataKaryawan(karyawan.data.data);
          setDataDivisi(divisi.data.data);
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
    if (dataAksi.aksi === "ubah") {
      setInputDisabled(true);
      setIdDivisi(dataAksi.kode_hak_akses);
    }
    return () => setIsLoading(false);
  }, []);

  const DivisiTable = () => {
    const [data, setData] = useState([]);
    const [isSwitchChange, setIsSwitchChange] = useState(false);

    const getDataKaryawan = () => {
      if (dataAksi.aksi === "ubah") {
        HakAksesDivisiApi.get(dataAksi.id_karyawan, dataAksi.kode_hak_akses)
          .then((res) => {
            setData(res.data.data);
          })
          .catch((err) => alert(err))
          .finally(() => setIsSwitchChange(false));
      }
      if (dataTable && idDivisi) {
        HakAksesDivisiApi.get(dataTable, idDivisi)
          .then((res) => {
            setData(res.data.data);
          })
          .catch((err) => alert(err))
          .finally(() => setIsSwitchChange(false));
      }
    };

    const changeStatus = (index) => {
      setIsSwitchChange(true);

      let dataAwal = data.filter((res) => res.tipe_hak_akses === "menu");
      dataAwal[index] = {
        ...dataAwal[index],
        status: !dataAwal[index].status,
      };
      setData(dataAwal);
      setTimeout(() => {
        setIsSwitchChange(false);
      }, 50);
    };

    useEffect(() => {
      getDataKaryawan();
      return setIsSwitchChange(false);
    }, []);

    const formSubmitedHandler = () => {
      const dataKode = data.filter((res) => res.status === true).map((val) => val.kode_hak_akses);

      dataKode.unshift(idDivisi);

      if (dataAksi.aksi === "add") {
        HakAksesDivisiApi.create({
          id_karyawan: dataId.id_karyawan,
          kode_hak_akses: dataKode,
          kode_hak_akses_parent: idDivisi,
          tipe_hak_akses: "menu,divisi",
        })
          .then(() => {
            setIsLoading(true);

            setAlertConfig({
              variant: "primary",
              text: `Tambah data berhasil!`,
              show: true,
            });
            setIsCreateForm(false);
            getInitialData();
          })
          .catch((err) => {
            setIsCreateForm(false);
            setAlertConfig({
              variant: "danger",
              text: `Tambah data gagal! (${err})`,
              show: true,
            });
            getInitialData();
          })
          .finally(() => {
            setIsLoading(false);
            setShowAlert(true);
            getData();
          });
      }
      if (dataAksi.aksi === "ubah") {
        HakAksesDivisiApi.create({
          id_karyawan: dataAksi.id_karyawan,
          kode_hak_akses: dataKode,
          kode_hak_akses_parent: dataAksi.kode_hak_akses_parent,
          tipe_hak_akses: "menu,divisi",
        })
          .then(() => {
            setIsLoading(true);
            setAlertConfig({
              variant: "primary",
              text: `Ubah data berhasil!`,
              show: true,
            });
            setIsUpdateForm(false);
            getInitialData();
          })
          .catch((err) => {
            setAlertConfig({
              variant: "danger",
              text: `Ubah data gagal! (${err})`,
              show: true,
            });
            setIsUpdateForm(false);
            getInitialData();
          })
          .finally(() => {
            setIsLoading(false);
            setShowAlert(true);
            getData();
          });
      }
    };

    return (
      <>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <Th>Nama Menu</Th>
              <Th>Tipe Menu</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.nama_hak_akses ?? "-"}</Td>
                <Td>{val.nama_tipe_menu ?? "-"}</Td>
                <TdFixed>
                  <Switch
                    checked={val.status ? true : false}
                    onChange={() => changeStatus(index)}
                  />
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
        <div className="d-flex justify-content-end">
          <ActionButton
            type="button"
            onClick={formSubmitedHandler}
            text="Simpan Hak Akses Divisi"
          />
        </div>
      </>
    );
  };
  return (
    <div
      className="p-1"
      style={{
        minHeight: "70vh",
        overflow: "auto",
      }}
    >
      <>
        <Row>
          <Col sm={6} lg={8}>
            <SelectSearch
              label="Karyawan Pengakses"
              name="id_karyawan"
              placeholder="Pilih karyawan"
              isDisabled={inputDisabled}
              onChange={(val) => {
                setDataTable(val.value);
                setDataId({
                  ...dataId,
                  id_karyawan: val.value,
                  nama_karyawan: val.label,
                });
                // handleChange(val);
              }}
              option={dataKaryawan.map((val) => {
                return {
                  value: val.id_karyawan,
                  label: `${val.no_karyawan} - ${val.nama_karyawan} - ${val.nama_jabatan} - ${val.nama_unit_produksi}`,
                };
              })}
              defaultValue={{
                value: dataAksi.id_karyawan ? dataAksi.id_karyawan : "Pilih karyawan",
                label: dataAksi.nama_karyawan
                  ? `${dataAksi.no_karyawan} - ${dataAksi.nama_karyawan} - ${dataAksi.nama_jabatan} - ${dataAksi.nama_unit_produksi}`
                  : "Pilih karyawan",
              }}
            />
          </Col>
          <Col sm={6} lg={4}>
            <SelectSearch
              label="Modul"
              name="kode_hak_akses"
              placeholder="Pilih divisi"
              isDisabled={inputDisabled}
              onChange={(val) => {
                setIdDivisi(val.value);
              }}
              option={dataDivisi.map((val) => {
                return {
                  value: val.kode_hak_akses,
                  label: val.nama_hak_akses,
                };
              })}
              defaultValue={{
                value: dataAksi.kode_hak_akses ? dataAksi.kode_hak_akses : "Pilih divisi",
                label: dataAksi.nama_hak_akses ? dataAksi.nama_hak_akses : "Pilih divisi",
              }}
            />
          </Col>
        </Row>
        {isLoading ? (
          <DataStatus loading={true} text="Memuat..." />
        ) : (
          <>
            <Alert
              show={showAlert}
              showCloseButton={true}
              // variant={alertConfig.variant}
              // text={alertConfig.text}
              onClose={() => setShowAlert(false)}
            />
            <DivisiTable />
          </>
        )}
      </>
    </div>
  );
};

export default HakAksesDivisi;
