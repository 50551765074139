import React, { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Input, Select, ActionButton, Alert } from "../../../../../components";
import { RegistrasiKaryawanApi } from "../../../../../api";

const FormDataBPJS = ({ dataKaryawan }) => {
  const [showAlert, setShowAlert] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const formInitialValues = {
    id_karyawan: dataKaryawan.id_karyawan,
    bpjs_kesehatan: dataKaryawan.bpjs_kesehatan == null ? "" : dataKaryawan.bpjs_kesehatan,
    bpjs_ketenagakerjaan:
      dataKaryawan.bpjs_ketenagakerjaan == null ? "" : dataKaryawan.bpjs_ketenagakerjaan,
  };

  const formValidationSchema = Yup.object().shape({
    bpjs_kesehatan: Yup.string()
      .required("Masukan nomor BPJS kesehatan")
      .nullable(),
      // .test("checkDuplicate", "No BPJS Kesehatan Sudah Didaftarkan", (value) => RegistrasiKaryawanApi.checkDuplicate({ bpjs_kesehatan: value }).then(() => true).catch(() => false)),
    bpjs_ketenagakerjaan: Yup.string()
      .required("Masukan nomor BPJS ketenagakerjaan")
      .nullable(),
      // .test("checkDuplicate", "No BPJS Ketenagakerjaan Sudah Didaftarkan", (value) => RegistrasiKaryawanApi.checkDuplicate({ bpjs_ketenagakerjaan: value }).then(() => true).catch(() => false)),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    RegistrasiKaryawanApi.updateDataBPJS({
      id_karyawan: values.id_karyawan,
      bpjs_kesehatan: values.bpjs_kesehatan,
      bpjs_ketenagakerjaan: values.bpjs_ketenagakerjaan,
    })
      .then(() => {
        setShowAlert({
          show: true,
          variant: "primary",
          text: "Ubah data sukses!",
        });
      })
      .catch((err) => {
        setShowAlert({
          show: true,
          variant: "danger",
          text: `Ubah data gagal! (${err})`,
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
          <form className="py-2" onSubmit={handleSubmit}>
            <Alert
              show={showAlert.show}
              showCloseButton={true}
              text={showAlert.text}
              variant={showAlert.variant}
              onClose={() =>
                setShowAlert({
                  ...showAlert,
                  show: false,
                })
              }
            />
            <Row>
              <Col lg>
                <Input
                  type="text"
                  label="BPJS Kesehatan"
                  placeholder="Masukan BPJS Kesehatan"
                  name="bpjs_kesehatan"
                  value={values.bpjs_kesehatan}
                  error={errors?.bpjs_kesehatan && touched?.bpjs_kesehatan && true}
                  errorText={errors?.bpjs_kesehatan}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  label="BPJS Ketenagakerjaan"
                  placeholder="Masukan BPJS Ketenagakerjaan"
                  name="bpjs_ketenagakerjaan"
                  value={values.bpjs_ketenagakerjaan}
                  error={errors?.bpjs_ketenagakerjaan && touched?.bpjs_ketenagakerjaan && true}
                  errorText={errors?.bpjs_ketenagakerjaan}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <ActionButton
                type="submit"
                variant="success"
                text="Ubah Data BPJS"
                loading={isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FormDataBPJS;
