import Services from "services"

class LaporanDurasiKontrakApi {
  get(params) {
    return Services.get("/hrda/sisa_durasi_kontrak/page", { params })
  }

  getSingle(params) {
    return Services.get("/hrda/sisa_durasi_kontrak/single", { params })
  }

  dropdownKaryawan(params) {
    return Services.get("/hrda/karyawan/dropdown", { params })
  }

  dropdownJenisPegawai(params) {
    return Services.get("/hrda/karyawan/jenis_pegawai", { params })
  }

  dropdownStatusPegawai(params) {
    return Services.get("/hrda/karyawan/status_pegawai", { params })
  }

  dropdownStatusDetail(params) {
    return Services.get("/hrda/karyawan/status_pegawai_detail", { params })
  }

  dropdownJabatan(params) {
    return Services.get("/hrda/jabatan/dropdown", { params })
  }

  dropdownUnitOrgansiasi(params) {
    return Services.get("/hrda/unit_organisasi/dropdown", { params })
  }

  export(params) {
    return Services.get("/hrda/sisa_durasi_kontrak/export", { params })
  }
}

export default new LaporanDurasiKontrakApi()
