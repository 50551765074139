// React
import React, { useState, useEffect } from "react";

// Component
import { Modal } from "react-bootstrap";
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DeleteButton,
  UpdateButton,
  ActionButton,
  DataStatus,
  DeleteModal,
  Alert,
  Input,
  Pagination,
  Th,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Td,
} from "../../../components";
import { TableNumber } from "../../../utilities";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// API
import { UnitProduksiApi } from "../../../api";

const UnitProduksi = ({ setNavbarTitle }) => {
  // Title
  const title = "Unit Produksi";

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(false);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");

  // Modal
  const [modal, setModal] = useState({
    show: false,
    tipe: "",
    data: {},
  });

  // data jenis anggaran
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  // menampung data yang akan diubah
  const [updateData, setUpdateData] = useState([]);
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    searchKey: "",
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    // request data ke server
    UnitProduksiApi.page({
      page,
      per_page: dataLength,
      q: searchKey,
    })
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Gagal untuk memuat data",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle(title);

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // modal tambah
  const DataModal = () => {
    // nilai awal form
    const formInitialValues = {
      nama_unit_produksi: modal?.data?.nama_unit_produksi ?? "",
    };

    // Validasi
    const formValidationSchema = Yup.object().shape({
      nama_unit_produksi: Yup.string()
        .required("Masukan Nama Unit Produksi")
        .test("checkDuplicate", "Nama Unit Produksi Sudah Didaftarkan", (value) =>
          modal?.data?.nama_unit_produksi !== value
            ? UnitProduksiApi.checkDuplicate({ nama_unit_produksi: value })
                .then(() => true)
                .catch(() => false)
            : true
        ),
    });

    // request tambah data ke server
    const formSubmitHandler = (values) => {
      if (modal?.tipe === "tambah") {
        UnitProduksiApi.create(values)
          .then(() => {
            // konfigurasi alert
            setAlertConfig({
              variant: "primary",
              text: "Tambah data berhasil!",
            });
          })
          .catch(() => {
            // konfigurasi alert
            setAlertConfig({
              variant: "danger",
              text: `Tambah data gagal!`,
            });
          })
          .finally(() => {
            // menutup modal
            setModal({
              ...modal,
              show: false,
            });
            // menampilkan alert
            setShowAlert(true);
            // request data baru ke server
            getData();
          });
      } else if (modal?.tipe === "ubah") {
        UnitProduksiApi.update({
          ...values,
          id_unit_produksi: modal?.data?.id_unit_produksi,
        })
          .then(() => {
            // konfigurasi alert
            setAlertConfig({
              variant: "primary",
              text: "Ubah data berhasil!",
            });
          })
          .catch(() => {
            // konfigurasi alert
            setAlertConfig({
              variant: "danger",
              text: `Ubah data gagal!`,
            });
          })
          .finally(() => {
            // menutup modal
            setModal({
              ...modal,
              show: false,
            });
            // menampilkan alert
            setShowAlert(true);
            // request data baru ke server
            getData();
          });
      }
    };

    return (
      <Modal
        show={modal?.show && modal?.tipe !== "hapus"}
        onHide={() =>
          setModal({
            ...modal,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            <h6 className="mb-0 text-capitalize">
              {modal?.tipe ?? ""} Data {title ?? ""}
            </h6>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Unit Produksi"
                  placeholder="Masukan Unit Produksi"
                  name="nama_unit_produksi"
                  value={values.nama_unit_produksi}
                  error={errors.nama_unit_produksi && true}
                  errorText={errors.nama_unit_produksi}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Simpan"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    );
  };

  // modal hapus
  const HapusModal = () => {
    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      UnitProduksiApi.delete({ id_unit_produksi: modal?.data?.id_unit_produksi })
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err})`,
          });
        })
        .finally(() => {
          // menutup modal
          setModal({
            ...modal,
            show: false,
          });
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={modal?.show && modal?.tipe === "hapus"}
        onHide={() =>
          setModal({
            ...modal,
            show: false,
          })
        }
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <span>Nama Unit Produksi : {modal?.data?.nama_unit_produksi ?? ""}</span>
      </DeleteModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th>Nama Unit Produksi</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <UpdateButton
                    onClick={() =>
                      setModal({
                        show: true,
                        tipe: "ubah",
                        data: val,
                      })
                    }
                  />
                  <DeleteButton
                    onClick={() =>
                      setModal({
                        show: true,
                        tipe: "hapus",
                        data: val,
                      })
                    }
                  />
                </div>
              </TdFixed>
              <Td>{val.nama_unit_produksi}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      {/* head */}
      <CRUDLayout.Head>
        {/* search section */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* button section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              setModal({
                show: true,
                tipe: "tambah",
                data: {},
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* aLert */}
      {isSearching ? (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={`Hasil dari pencarian: ${alertConfig.searchKey}`}
          onClose={() => {
            setShowAlert(false);
            getData();
            setIsSearching(false);
          }}
        />
      ) : (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
      )}

      {/* table section */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          // Ada data
          <Table />
        ) : (
          // Tidak ada data
          <DataStatus text="Tidak ada data" />
        )
      }

      {/* Modal */}
      <DataModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default UnitProduksi;
