import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoPersonCircleOutline,
  IoSchoolOutline,
  IoPeopleOutline,
  IoKeyOutline,
  IoCalendarOutline,
  IoEaselOutline,
  IoInformationOutline,
  IoCheckboxOutline,
  IoDocumentOutline,
} from "react-icons/io5"

// IMPORT LOGO
import Logo from "../assets/image/logo_krisna.png"

// IMPORT PAGE
import {
  // MASTER JABATAN
  Hirarki,
  UnitOrganisasi,
  Pangkat,
  Grade,
  Jabatan,

  // MASTER KARYAWAN
  LokasiKerja,
  JenisKaryawan,
  StatusKaryawan,
  DetailStatusKaryawan,
  RegistrasiKaryawan,
  DetailKaryawan,
  TambahKaryawan,
  UbahKaryawan,
  UnitProduksi,

  // Pelamar Kerja
  PelamarKerjaList,
  PelamarKerjaDetail,
  PelamarKerjaCreate,
  PelamarKerjaUpdate,

  // MASTER HAK AKSES
  HakAksesAplikasi,
  HakAksesApproval,
  HakAsesDivisiList,
  HakAksesDashboard,

  // Laporan
  LaporanDurasiKontrak,
  LaporanDurasiKontrakDetail
} from "../pages/HumanResource"



export default {
  LOGO: Logo,
  MODUL: "Admin HRD",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["HRDA", "SUPA"],
    },
    {
      text: "Master Jabatan",
      type: "dropdown",
      icon: <IoSchoolOutline />,
      hak: ["HRDA", "SUPA"],
      menu: [
        {
          text: "Hirarki Unit Organisasi",
          link: "/human-resource/master/jabatan/hirarki",
          hak: ["HRDA", "SUPA", "HRDA_JBT_HIR"],
        },
        {
          text: "Unit Organisasi",
          link: "/human-resource/master/jabatan/unit-organisasi",
          hak: ["HRDA", "SUPA", "HRDA_JBT_UOR"],
        },
        {
          text: "Pangkat",
          link: "/human-resource/master/jabatan/pangkat",
          hak: ["HRDA", "SUPA", "HRDA_JBT_PAN"],
        },
        {
          text: "Grade",
          link: "/human-resource/master/jabatan/grade",
          hak: ["HRDA", "SUPA", "HRDA_JBT_GRD"],
        },
        {
          text: "Jabatan",
          link: "/human-resource/master/jabatan/jabatan",
          hak: ["HRDA", "SUPA", "HRDA_JBT_JAB"],
        },
      ],
    },
    {
      text: "Master Pekerja",
      type: "dropdown",
      icon: <IoPeopleOutline />,
      hak: ["HRDA", "SUPA"],
      menu: [
        {
          text: "Lokasi Kerja",
          link: "/human-resource/master/karyawan/lokasi-kerja",
          hak: ["HRDA", "SUPA", "HRDA_KRY_LOK"],
        },
        {
          text: "Jenis Pekerja",
          link: "/human-resource/master/karyawan/jenis",
          hak: ["HRDA", "SUPA", "HRDA_KRY_JK"],
        },
        {
          text: "Status Pekerja",
          link: "/human-resource/master/karyawan/status",
          hak: ["HRDA", "SUPA", "HRDA_KRY_STA"],
        },
        {
          text: "Detail Status Pekerja",
          link: "/human-resource/master/karyawan/detail-status",
          hak: ["HRDA", "SUPA", "HRDA_KRY_SKD"],
        },
        {
          text: "Pekerja",
          link: "/human-resource/master/karyawan/registrasi",
          hak: ["HRDA", "SUPA", "HRDA_KRY_KRY"],
        },
        {
          text: "Pelamar Kerja",
          link: "/human-resource/master/karyawan/pelamar-kerja",
          hak: ["HRDA", "SUPA", "HRDA_KRY_PLK"],
        },
        {
          text: "Unit Produksi",
          link: "/human-resource/master/karyawan/unit-produksi",
          hak: ["HRDA", "SUPA", "HRDA_KRY_UPS"],
        },
      ],
    },
    {
      text: "Hak Akses",
      type: "dropdown",
      icon: <IoKeyOutline />,
      hak: ["HRDA", "SUPA"],
      menu: [
        {
          text: "Hak Akses Divisi",
          link: "/human-resource/master/hak/divisi",
          hak: ["HRDA", "SUPA", "HRDA_HAK_DIV"],
        },
        {
          text: "Hak Akses Approval",
          link: "/human-resource/master/hak/approval",
          hak: ["HRDA", "SUPA", "HRDA_HAK_APR"],
        },
        {
          text: "Hak Akses Aplikasi",
          link: "/human-resource/master/hak/aplikasi",
          hak: ["HRDA", "SUPA", "HRDA_HAK_APP"],
        },
        {
          text: "Hak Akses Dashboard",
          link: "/human-resource/master/hak/dashboard",
          hak: ["HRDA", "SUPA", "HRDA_HAK_DAS"],
        },
      ],
    },
    {
      text: "Laporan",
      type: "dropdown",
      icon: <IoDocumentOutline />,
      hak: ["HRDA", "SUPA"],
      menu: [
        {
          text: "Sisa Durasi Kontrak",
          link: "/human-resource/laporan/sisa-durasi-kontrak",
          hak: ["HRDA", "SUPA", "HRDA_RPT_SDK"],
        },
      ],
    },
    {
      text: "Absensi",
      link: "/human-resource/absensi",
      type: "menu",
      exact: true,
      icon: <IoCheckboxOutline />,
      hak: ["HRDA", "SUPA"],
    },
    {
      text: "Training",
      link: "/human-resource/training",
      type: "menu",
      exact: true,
      icon: <IoEaselOutline />,
      hak: ["HRDA", "SUPA"],
    },
    {
      text: "Info Karyawan",
      link: "/human-resource/info-karyawan",
      type: "menu",
      exact: true,
      icon: <IoInformationOutline />,
      hak: ["HRDA", "SUPA"],
    },
    {
      text: "Agenda",
      link: "/human-resource/agenda",
      type: "menu",
      exact: true,
      icon: <IoCalendarOutline />,
      hak: ["HRDA", "SUPA"],
    },
    {
      text: "Profil",
      type: "dropdown",
      icon: <IoPersonCircleOutline />,
      hak: ["ANG"],
      menu: [
        {
          text: "Akun Saya",
          link: "/profil",
          hak: ["ANG"],
        },
        {
          text: "Ubah Akun",
          link: "/profil/ubah",
          hak: ["ANG"],
        },
        {
          text: "Ganti Password",
          link: "/profil/ganti-password",
          hak: ["ANG"],
        },
      ],
    },
  ],

  ROUTES: [
    {
      exact: true,
      path: "/human-resource/master/jabatan/hirarki",
      page: Hirarki,
      hak: ["HRDA_JBT_HIR", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/jabatan/unit-organisasi",
      page: UnitOrganisasi,
      hak: ["HRDA_JBT_UOR", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/jabatan/pangkat",
      page: Pangkat,
      hak: ["HRDA_JBT_PAN", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/jabatan/grade",
      page: Grade,
      hak: ["HRDA_JBT_GRD", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/jabatan/jabatan",
      page: Jabatan,
      hak: ["HRDA_JBT_JBT", "SUPA"],
    },

    {
      exact: true,
      path: "/human-resource/master/karyawan/lokasi-kerja",
      page: LokasiKerja,
      hak: ["HRDA_KRY_LOK", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/jenis",
      page: JenisKaryawan,
      hak: ["HRDA_KRY_JK", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/status",
      page: StatusKaryawan,
      hak: ["HRDA_KRY_STA"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/detail-status",
      page: DetailStatusKaryawan,
      hak: ["HRDA_KRY_SKD", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/unit-produksi",
      page: UnitProduksi,
      hak: ["HRDA_KRY_UPS", "SUPA"],
    },

    {
      exact: true,
      path: "/human-resource/master/karyawan/pelamar-kerja",
      page: PelamarKerjaList,
      hak: ["HRDA_KRY_PLK", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/pelamar-kerja/detail/:id",
      page: PelamarKerjaDetail,
      hak: ["HRDA_KRY_PLK", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/pelamar-kerja/tambah",
      page: PelamarKerjaCreate,
      hak: ["HRDA_KRY_PLK", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/pelamar-kerja/ubah/:id",
      page: PelamarKerjaUpdate,
      hak: ["HRDA_KRY_PLK", "SUPA"],
    },

    {
      exact: true,
      path: "/human-resource/master/karyawan/registrasi",
      page: RegistrasiKaryawan,
      hak: ["HRDA_KRY_KRY", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/tambah",
      page: TambahKaryawan,
      hak: ["HRDA_KRY_KRY", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/:id",
      page: DetailKaryawan,
      hak: ["HRDA_KRY_KRY", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/ubah/:id",
      page: UbahKaryawan,
      hak: ["HRDA_KRY_KRY", "SUPA"],
    },

    {
      exact: true,
      path: "/human-resource/master/hak/aplikasi",
      page: HakAksesAplikasi,
      hak: ["HRDA_HAK_APP", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/hak/approval",
      page: HakAksesApproval,
      hak: ["HRDA_HAK_APR", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/hak/divisi",
      page: HakAsesDivisiList,
      hak: ["HRDA_HAK_DIV", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/master/hak/dashboard",
      page: HakAksesDashboard,
      hak: ["HRDA_HAK_DAS", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/laporan/sisa-durasi-kontrak",
      page: LaporanDurasiKontrak,
      hak: ["HRDA_RPT_SDK", "SUPA"],
    },
    {
      exact: true,
      path: "/human-resource/laporan/sisa-durasi-kontrak/detail/:id",
      page: LaporanDurasiKontrakDetail,
      hak: ["HRDA_RPT_SDK", "SUPA"],
    },
  ],
}
