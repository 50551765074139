import React, {
  useState
} from 'react'
import {
  Col
} from 'react-bootstrap'
import {
  IoEyeOutline
} from 'react-icons/io5'
import {
  useFormik
} from 'formik'
import * as Yup from 'yup'
import config from 'config'
import { 
  ActionButton, 
  Input, 
  TextArea,
  DatePicker,
  SelectSearch
} from 'components'
import { 
  DateConvert 
} from 'utilities'
import {
  PelamarKerjaApi
} from 'api'

const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, "")
  const convert = newValue.substring(0, 16)

  return convert
}

const InputFile = ({ label, text, name, value, readOnly, error, errorText, onChange, accept }) => {
  return (
    <div className="mb-2">
      <small style={{ textTransform: "capitalize" }}>{label}</small>
      <div className="custom-file" style={{zIndex: 1}}>
        <input
          type="file"
          name={name}
          value={value}
          readOnly={readOnly}
          accept={accept}
          className={`custom-file-input`}
          onChange={onChange}
          />
        <label className={`custom-file-label ${error && 'border-danger'}`}>{text}</label>
      </div>
      <small className="text-danger">{errorText}</small>
    </div>
  )
}

/**
 * Form Pelamar Kerja
 * Digunakan untuk pelamar yang ingin mendaftarkan diri sebagai karyawan perusahaan.
 * Dapat diakses tanpa perlu login pada link /form-pelamar-kerja.
 * Konfigurasi dapat dilihat pada folder routes
 */
export const FormPelamarKerja = () => {
  const dataPendidikanTerakhir = ['SD', 'SMP', 'SMA/SMK', 'D1', 'D2', 'D3', 'S1', 'S2', 'S3']
  const [type, setType] = useState('form')
  const { values, errors, touched, setValues, setFieldValue, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      nama_pelamar_kerja: '',
      nik: '',
      tgl_lahir: '',
      tgl_pelamar_kerja: '',
      kode_pelamar_kerja: '',
      alamat: '',
      pendidikan_terakhir: '',
      institusi: '',
      jurusan: '',
      no_telepon: '',
      email: '',
      posisi: '',
      path_file_ktp: undefined,
      path_file_cv: undefined,
      data_file_ktp: undefined,
      data_file_cv: undefined,
      name_file_ktp: undefined,
      name_file_cv: undefined,
      tgl_pelamar_kerja: DateConvert(new Date()).default,
    },
    validationSchema: Yup.object().shape({
      tgl_pelamar_kerja: Yup.string().required('Tgl pelamar tidak boleh kosong'),
      nama_pelamar_kerja: Yup.string().required('Nama pelamar tidak boleh kosong'),
      nik: Yup.string().required('Nik tidak boleh kosong').length(16, 'Format nik tidak valid'),
      tgl_lahir: Yup.string().required('Tanggal lahir tidak boleh kosong'),
      alamat: Yup.string().required('Alamat tidak boleh kosong'),
      pendidikan_terakhir: Yup.string().required('Pilih pendidikan'),
      institusi: Yup.string().required('Institusi tidak boleh kosong'),
      jurusan: Yup.string().required('Jurusan tidak boleh kosong'),
      no_telepon: Yup.string()
        .min(11, 'No. Telp minimal 11 karakter')
        .max(13, 'No. Telp maksimal 13 karakter')
        .required('No. telepon tidak boleh kosong'),
      email: Yup.string().required('Email tidak boleh kosong').email('Format email salah'),
      posisi: Yup.string().required('Posisi tidak boleh kosong'),
      path_file_ktp: Yup.string().required('KTP tidak boleh kosong'),
      path_file_cv: Yup.string().required('CV tidak boleh kosong'),
    }),
    onSubmit: async (values) => {
      const dataFile = [values?.data_file_cv, values?.data_file_ktp] // index 0 = data CV, index 1 = data KTP
    
      let pathCV = ''
      let pathKTP = ''
  
      await Promise.all(dataFile.map(async (val, index) => {
        if (!!val) {
          const fileData = new FormData()
          fileData.append("file", val)
  
          return await PelamarKerjaApi.saveFile(fileData)
            .then(res => {
              const pathFile = res?.data?.data ?? ''
  
              if(index === 0) {
                pathCV = pathFile
              } else {
                pathKTP = pathFile
              }
            })
            .catch(() => {
              if(index === 0) {
                pathCV = ''
              } else {
                pathKTP = ''
              }
            })
        } else {
          pathCV = values?.path_file_cv
          pathKTP = values?.path_file_ktp
        }
      }))
  
      const finalValues = {
        ...values,
        path_file_ktp: pathKTP,
        path_file_cv: pathCV,
      }
  
      PelamarKerjaApi.create(finalValues)
      .then(() => {
        setType('success')
      })
      .catch(() => {
        alert('Gagal mengajukan lamaran kerja')
      })
    }
  })

  const handleFileChange = (e) => {
    const name = e.target.name
    const file = e.target.files[0]

    // Check apakah ada file yang dipilih
    if (!file) return null;

    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.name.split(".").pop()

    // Check jika type file sudah benar
    if (["pdf"].includes(getFileType)) {
      const generateName = file.name
      const generateLink = URL.createObjectURL(file)

      setValues({
        ...values,
        [`name_file_${name}`]: generateName,
        [`path_file_${name}`]: generateLink,
        [`data_file_${name}`]: file,
      })
    } else {
      window.alert("File tidak valid! harap masukan .pdf");
    }  
  }
  
  return (
    <div
      style={{
        minHeight: '100vh',
        minWidth: '100vw'
      }}
      className="bg-light d-flex justify-content-center align-items-center"
    >
      <Col md={10} lg={8} xl={4} className="bg-white rounded border shadow-sm my-5">
        <div className="px-5 py-4 text-center">
          <img src={config.LOGO} className="img-fluid" width={200} />
        </div>
        <div className="d-flex align-items-center flex-column">
          <b>KRISNA</b>
          <span className='d-block text-center'>JL. RAYA SAMBANGAN, ALING-ALING WATERFALL, SINGARAJA - BALI</span>
          <span>krisnaadventures@gmail.com </span>
          <span>+62 877 6245 3130</span>
        </div>
        <hr />
        {type === 'form'
          ? <>
              <b>Form Pelamar Kerja</b>
              <div className="pt-2 pb-3">
                <Input 
                  label="Nama Pelamar Kerja"
                  name="nama_pelamar_kerja"
                  placeholder="Masukan nama pelamar kerja"
                  value={values.nama_pelamar_kerja}
                  onChange={handleChange}
                  error={errors.nama_pelamar_kerja && touched.nama_pelamar_kerja}
                  errorText={!!(errors.nama_pelamar_kerja && touched.nama_pelamar_kerja) && errors.nama_pelamar_kerja}
                />
                <TextArea 
                  label="Alamat Pelamar Kerja"
                  name="alamat"
                  value={values.alamat}
                  placeholder="Masukan alamat kerja"
                  onChange={handleChange}
                  error={errors.alamat && touched.alamat}
                  errorText={!!(errors.alamat && touched.alamat) && errors.alamat}
                />
                <DatePicker 
                  label="Tgl. Lahir Pelamar Kerja"
                  name="tgl_lahir"
                  placeholderText="Pilih tgl. lahir"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selected={values.tgl_lahir ? new Date(values.tgl_lahir) : undefined}
                  onChange={date => setFieldValue('tgl_lahir', DateConvert(new Date(date)).default)}
                  error={errors.tgl_lahir && touched.tgl_lahir}
                  errorText={!!(errors.tgl_lahir && touched.tgl_lahir) && errors.tgl_lahir}
                />
                <Input 
                  label="Nomor Telepon"
                  name="no_telepon"
                  placeholder="Masukan nomor telepon pelamar kerja"
                  onChange={e => setFieldValue('no_telepon', decimalConvert(e?.target?.value || ''))}
                  value={values.no_telepon}
                  error={errors.no_telepon && touched.no_telepon}
                  errorText={!!(errors.no_telepon && touched.no_telepon) && errors.no_telepon}
                />
                <Input 
                  label="Email"
                  name="email"
                  placeholder="Masukan email pelamar kerja"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email && touched.email}
                  errorText={!!(errors.email && touched.email) && errors.email}
                />
                <SelectSearch 
                  menuPortalTarget={document.body} 
                  label="Pendidikan Terakhir"
                  placeholder="Pilih pendidikan terakhir"
                  defaultValue={values.pendidikan_terakhir ? dataPendidikanTerakhir.find(val => val === values.pendidikan_terakhir) ? ({value: dataPendidikanTerakhir.find(val => val === values.pendidikan_terakhir), label: dataPendidikanTerakhir.find(val => val === values.pendidikan_terakhir)}) : {} : ''}
                  option={dataPendidikanTerakhir.map(val => ({label: val, value: val}))}
                  onChange={val => setFieldValue('pendidikan_terakhir', val.value)}
                  error={errors.pendidikan_terakhir && touched.pendidikan_terakhir}
                  errorText={!!(errors.pendidikan_terakhir && touched.pendidikan_terakhir) && errors.pendidikan_terakhir}
                />
                <Input 
                  label="Nama Sekolah/Institusi"
                  name="institusi"
                  placeholder="Masukan nama institusi"
                  value={values.institusi}
                  onChange={handleChange}
                  error={errors.institusi && touched.institusi}
                  errorText={!!(errors.institusi && touched.institusi) && errors.institusi}
                />
                <Input 
                    label="Jurusan"
                    name="jurusan"
                    placeholder="Masukan nama pelamar kerja"
                    value={values.jurusan}
                    onChange={handleChange}
                    error={errors.jurusan && touched.jurusan}
                    errorText={!!(errors.jurusan && touched.jurusan) && errors.jurusan}
                />
                <Input 
                  label="Posisi Yang Diinginkan"
                  name="posisi"
                  placeholder="Masukan posisi pelamar kerja"
                  value={values.posisi}
                  onChange={handleChange}
                  error={errors.posisi && touched.posisi}
                  errorText={!!(errors.posisi && touched.posisi) && errors.posisi}
                />
                <Input 
                  label="NIK"
                  name="nik"
                  placeholder="Masukan nik pelamar kerja"
                  onChange={e => setFieldValue('nik', decimalConvert(e?.target?.value || ''))}
                  value={values.nik}
                  error={errors.nik && touched.nik}
                  errorText={!!(errors.nik && touched.nik) && errors.nik}
                />
                <InputFile 
                    label="Dokumen KTP"
                    accept="application/pdf"
                    name="ktp"
                    onChange={handleFileChange}
                    text={values?.name_file_ktp ?? values?.path_file_ktp ?? 'Pilih dokumen'}
                    error={errors.path_file_ktp && touched.path_file_ktp}
                    errorText={!!(errors.path_file_ktp && touched.path_file_ktp) && errors.path_file_ktp}
                  />
                  {values?.path_file_ktp &&
                    <ActionButton 
                      size="sm" 
                      className="my-2"
                      text={<><IoEyeOutline /> Lihat Dokumen</>} 
                      onClick={() => window.open(values?.path_file_ktp)}
                    />
                  }
                <InputFile 
                  label="Dokumen CV"
                  accept="application/pdf"
                  name="cv"
                  onChange={handleFileChange}
                  text={values?.name_file_cv ?? values?.path_file_cv ?? 'Pilih dokumen'}
                  error={errors.path_file_cv && touched.path_file_cv}
                  errorText={!!(errors.path_file_cv && touched.path_file_cv) && errors.path_file_cv}
                />
                {values?.path_file_cv && 
                  <ActionButton 
                    size="sm" 
                    className="my-2"
                    text={<><IoEyeOutline /> Lihat Dokumen</>} 
                    onClick={() => window.open(values?.path_file_cv)}
                  />
                }
                <hr />
                {console.log(errors)}
                <div className="text-right">
                  <ActionButton 
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    className="px-4"
                    text="Kirim"
                  />
                </div>
              </div>
            </>
          : <div style={{height: '100%', width: '100%'}} className="border border-success p-3 py-5 mb-5 text-center align-center">
              <span className='d-block'>Terimakasih Sudah Sudah Mendaftar</span>
              <span className='d-block'>Informasi selanjutnya akan dikirim via telepon atau email</span>
            </div>
        }
      </Col>
    </div>
  )
}
