import * as Yup from 'yup'

export const formValidationSchema = Yup.object().shape({
  tgl_pelamar_kerja: Yup.string().required('Tgl pelamar tidak boleh kosong'),
  kode_pelamar_kerja: Yup.string().required('Kode pelamar tidak boleh kosong'),
  nama_pelamar_kerja: Yup.string().required('Nama pelamar tidak boleh kosong'),
  nik: Yup.string().required('Nik tidak boleh kosong').length(16, 'Format nik tidak valid'),
  tgl_lahir: Yup.string().required('Tanggal lahir tidak boleh kosong'),
  alamat: Yup.string().required('Alamat tidak boleh kosong'),
  pendidikan_terakhir: Yup.string().required('Pilih pendidikan'),
  institusi: Yup.string().required('Institusi tidak boleh kosong'),
  jurusan: Yup.string().required('Jurusan tidak boleh kosong'),
  no_telepon: Yup.string()
    .min(11, 'No. Telp minimal 11 karakter')
    .max(13, 'No. Telp maksimal 13 karakter')
    .required('No. telepon tidak boleh kosong'),
  email: Yup.string().required('Email tidak boleh kosong').email('Format email salah'),
  posisi: Yup.string().required('Posisi tidak boleh kosong'),
  path_file_ktp: Yup.string().required('KTP tidak boleh kosong'),
  path_file_cv: Yup.string().required('CV tidak boleh kosong'),
})
