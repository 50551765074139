import Services from "../../../services";

class HakAksesDivisiApi {
  get(id, kode) {
    return Services.get(
      `/hrda/hak_akses/karyawan/?id_karyawan=${id}&tipe_hak_akses=menu&kode_hak_akses_parent=${kode}`
    );
  }
  getPage(params) {
    return Services.get("/hrda/hak_akses/hak_akses_karyawan", { params });
  }
  getDropdown() {
    return Services.get("/hrda/karyawan/");
  }
  getDivisi() {
    return Services.get("/hrda/hak_akses/?tipe_hak_akses=divisi");
  }
  create(value) {
    return Services.post("/hrda/hak_akses/karyawan/", value);
  }
  delete(data) {
    return Services.post("/hrda/hak_akses/delete/", data);
  }
}

export default new HakAksesDivisiApi();
