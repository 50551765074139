import { DatePicker, SelectSearch, FilterModal } from 'components'
import { DateConvert } from 'utilities'
import { useFormik } from 'formik'

const ModalFilterDurasiKontrak = ({ SESSION_KEY, dropdown, modalConfig, setModalConfig, data, setData }) => {
  const filter = data?.filter
  const formInitialValues = {
    karyawan: filter?.karyawan,
    jabatan: filter?.jabatan,
    unit_organisasi: filter?.unit_organisasi,
    jenis_karyawan: filter?.jenis_karyawan,
    status_karyawan: filter?.status_karyawan,
    status_karyawan_detail: filter?.status_karyawan_detail,
    tgl_mulai_kontrak_mulai: filter?.tgl_mulai_kontrak_mulai,
    tgl_mulai_kontrak_selesai: filter?.tgl_mulai_kontrak_selesai,
    tgl_selesai_kontrak_mulai: filter?.tgl_selesai_kontrak_mulai,
    tgl_selesai_kontrak_selesai: filter?.tgl_selesai_kontrak_selesai,
    sisa_durasi: filter?.sisa_durasi,
  }

  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({
        filter: { ...values, active: true },
        pagination: { ...data.pagination, page: 1 }
      })
    } else {
      setData({ ...data, filter: { ...values, active: false } })
    }
    setModalConfig(false)
  }

  const formik = useFormik({ initialValues: formInitialValues, onSubmit: formSubmitHandler })

  const { values, setFieldValue, setValues, handleSubmit } = formik

  const onChangeTglMulai = (dates) => {
    const [start, end] = dates

    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({ ...values, tgl_mulai_kontrak_mulai: startDate, tgl_mulai_kontrak_selesai: endDate })
  }

  const onChangeTglSelesai = (dates) => {
    const [start, end] = dates

    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({ ...values, tgl_selesai_kontrak_mulai: startDate, tgl_selesai_kontrak_selesai: endDate })
  }

  const onResetButtonClick = () => {
    // DELETE FILTER & PAGINATION DI SESSION STORAGE
    sessionStorage.removeItem(SESSION_KEY.FILTER)
    sessionStorage.removeItem(SESSION_KEY.PAGINATION)

    setData(prev => ({
      ...prev,
      filter: {
        karyawan: undefined,
        jabatan: undefined,
        unit_organisasi: undefined,
        jenis_karyawan: undefined,
        status_karyawan: undefined,
        status_karyawan_detail: undefined,
        tgl_mulai_kontrak_mulai: undefined,
        tgl_mulai_kontrak_selesai: undefined,
        tgl_selesai_kontrak_mulai: undefined,
        tgl_selesai_kontrak_selesai: undefined,
        sisa_durasi: undefined,
      },
      pagination: { ...prev.pagination, page: 1, per_page: 10 }
    }))

    setModalConfig(false)
  }

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <SelectSearch
        key={values?.karyawan}
        label="Nama Pekerja"
        placeholder="Pilih Pekerja"
        defaultValue={dropdown?.karyawan?.find(item => item.value === values.karyawan)}
        option={dropdown.karyawan}
        onChange={val => setFieldValue('karyawan', val.value)}
      />

      <SelectSearch
        key={values?.jabatan}
        label="Jabatan"
        placeholder="Pilih Jabatan"
        defaultValue={dropdown?.jabatan?.find(item => item.value === values.jabatan)}
        option={dropdown.jabatan}
        onChange={val => setFieldValue('jabatan', val.value)}
      />

      <SelectSearch
        key={values?.unit_organisasi}
        label="Unit Organisasi"
        placeholder="Pilih Unit Organisasi"
        defaultValue={dropdown?.unit_organisasi?.find(item => item.value === values.unit_organisasi)}
        option={dropdown.unit_organisasi}
        onChange={val => setFieldValue('unit_organisasi', val.value)}
      />

      <SelectSearch
        key={values?.jenis_karyawan}
        label="Jenis Pekerja"
        placeholder="Pilih Jenis Pekerja"
        defaultValue={dropdown?.jenis_karyawan?.find(item => item.value === values.jenis_karyawan)}
        option={dropdown.jenis_karyawan}
        onChange={val => setFieldValue('jenis_karyawan', val.value)}
      />

      <SelectSearch
        key={values?.status_karyawan}
        label="Status Pekerja"
        placeholder="Pilih Status Pekerja"
        defaultValue={dropdown?.status_karyawan?.find(item => item.value === values.status_karyawan)}
        option={dropdown.status_karyawan}
        onChange={val => setFieldValue('status_karyawan', val.value)}
      />

      <SelectSearch
        key={values?.status_karyawan_detail}
        label="Detail Status Pekerja"
        placeholder="Pilih Detail Status Pekerja"
        defaultValue={dropdown?.status_karyawan_detail?.find(item => item.value === values.status_karyawan_detail)}
        option={dropdown.status_karyawan_detail}
        onChange={val => setFieldValue('status_karyawan_detail', val.value)}
      />

      <DatePicker
        selectsRange
        label="Tgl. Mulai Kontrak"
        placeholderText="Pilih tanggal mulai kontrak"
        startDate={values?.tgl_mulai_kontrak_mulai ? new Date(values?.tgl_mulai_kontrak_mulai) : ''}
        endDate={values?.tgl_mulai_kontrak_selesai ? new Date(values?.tgl_mulai_kontrak_selesai) : ''}
        onChange={(dates) => onChangeTglMulai(dates)}
        monthsShown={2}
      />

      <DatePicker
        selectsRange
        label="Tgl. Selesai Kontrak"
        placeholderText="Pilih tanggal selesai kontrak"
        startDate={values?.tgl_selesai_kontrak_mulai ? new Date(values?.tgl_selesai_kontrak_mulai) : ''}
        endDate={values?.tgl_selesai_kontrak_selesai ? new Date(values?.tgl_selesai_kontrak_selesai) : ''}
        onChange={(dates) => onChangeTglSelesai(dates)}
        monthsShown={2}
      />

      <SelectSearch
        key={values.sisa_durasi}
        label="Sisa Durasi Kontrak"
        placeholder="Pilih Sisa Durasi Kontrak"
        defaultValue={dropdown?.sisa_durasi?.find(item => item.value === values.sisa_durasi)}
        option={dropdown.sisa_durasi}
        onChange={val => setFieldValue('sisa_durasi', val.value)}
      />
    </FilterModal>
  )
}

export default ModalFilterDurasiKontrak