import Services from "../../../services";

class JabatanApi {
  get() {
    return Services.get("/hrda/jabatan");
  }

  getDropdown() {
    return Services.get("/hrda/jabatan/dropdown");
  }

  getPage(page, dataLength, key) {
    return Services.get(`/hrda/jabatan/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  kode() {
    return Services.get("/hrda/jabatan/no_baru");
  }

  search(key) {
    return Services.get("/hrda/jabatan/page/?per_page=10&page=1&q=" + key);
  }

  show(value) {
    return Services.put("/hrda/jabatan/show", value);
  }

  hide(value) {
    return Services.put("/hrda/jabatan/hide", value);
  }

  create(value) {
    return Services.post("/hrda/jabatan", value);
  }

  update(value) {
    return Services.put("/hrda/jabatan", value);
  }

  delete(value) {
    return Services.post("/hrda/jabatan/delete", value);
  }

  checkDuplicate(params) {
    return Services.get("/hrda/jabatan/check_duplicate", { params });
  }
}

export default new JabatanApi();
