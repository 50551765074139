import {
  useState,
  useEffect,
  memo
} from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  useFormikContext
} from 'formik'
import {
  ActionButton,
  DatePicker,
  Input,
  SelectSearch,
  TextArea
} from 'components'
import { 
  DateConvert 
} from 'utilities'
import {
  PelamarKerjaApi
} from 'api'
import { IoEyeOutline } from 'react-icons/io5'

const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, "")
  const convert = newValue.substring(0, 16)

  return convert
}

const InputFile = ({ label, text, name, value, readOnly, error, errorText, onChange, accept }) => {
  return (
    <>
      <small style={{ textTransform: "capitalize" }}>{label}</small>
      <div className="custom-file" style={{zIndex: 1}}>
        <input
          type="file"
          name={name}
          value={value}
          readOnly={readOnly}
          accept={accept}
          className={`custom-file-input`}
          onChange={onChange}
          />
        <label className={`custom-file-label ${error && 'border-danger'}`}>{text}</label>
      </div>
      <small className="text-danger">{errorText}</small>
    </>
  )
}

export const SectionPelamarKerja = ({action}) => {
  const [loadingKode, setLoadingKode] = useState(false)
  const { values, errors, touched, setFieldValue, isSubmitting, setValues, handleChange, handleSubmit } = useFormikContext()
  const dataPendidikanTerakhir = ['SD', 'SMP', 'SMA/SMK', 'D1', 'D2', 'D3', 'S1', 'S2', 'S3']

  const getKode = () => {
    setLoadingKode(true)

    PelamarKerjaApi.getKode()
      .then((res) => {
        setFieldValue('kode_pelamar_kerja', res?.data?.data?.kode_pelamar ?? '')
      })
      .catch(() => {
        setFieldValue('kode_pelamar_kerja', '')
      })
      .finally(() => setLoadingKode(false))
  }

  const handleFileChange = (e) => {
    const name = e.target.name
    const file = e.target.files[0]

    // Check apakah ada file yang dipilih
    if (!file) return null;

    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.name.split(".").pop()

    // Check jika type file sudah benar
    if (["pdf"].includes(getFileType)) {
      const generateName = file.name
      const generateLink = URL.createObjectURL(file)

      setValues({
        ...values,
        [`name_file_${name}`]: generateName,
        [`path_file_${name}`]: generateLink,
        [`data_file_${name}`]: file,
      })
    } else {
      window.alert("File tidak valid! harap masukan .pdf");
    }  
  }
  
  useEffect(() => {
    action !== 'update' && getKode()
  }, [])
  

  return (
    <>
      {/* Tgl & Kode Pelamar Kerja */}
      <Row>
        <Col md>
          <Input 
            label="Kode Pelamar Kerja"
            name="kode_pelamar_kerja"
            placeholder="Masukan kode pelamar kerja"
            onChange={handleChange}
            value={loadingKode ? 'Memuat data . . .' : values?.kode_pelamar_kerja}
            error={errors.kode_pelamar_kerja && touched.kode_pelamar_kerja}
            errorText={!!(errors.kode_pelamar_kerja && touched.kode_pelamar_kerja) && errors.kode_pelamar_kerja}
            readOnly
          />
        </Col>
        <Col md>
          <DatePicker 
            label="Tgl. Pelamar Kerja"
            name="tgl_pelamar_kerja"
            placeholderText="Pilih tgl. pelamar kerja"
            selected={values.tgl_pelamar_kerja ? new Date(values.tgl_pelamar_kerja) : undefined}
            onChange={date => setFieldValue('tgl_pelamar_kerja', DateConvert(new Date(date)).default)}
            error={errors.tgl_pelamar_kerja && touched.tgl_pelamar_kerja}
            errorText={!!(errors.tgl_pelamar_kerja && touched.tgl_pelamar_kerja) && errors.tgl_pelamar_kerja}
          />
        </Col>
      </Row>

        {/* Nama, Tgl Lahir & Alamat Pelamar Kerja */}
        <Row>
          <Col md>
            <Input 
              label="Nama Pelamar Kerja"
              name="nama_pelamar_kerja"
              placeholder="Masukan nama pelamar kerja"
              value={values.nama_pelamar_kerja}
              onChange={handleChange}
              error={errors.nama_pelamar_kerja && touched.nama_pelamar_kerja}
              errorText={!!(errors.nama_pelamar_kerja && touched.nama_pelamar_kerja) && errors.nama_pelamar_kerja}
            />
            <DatePicker 
              label="Tgl. Lahir Pelamar Kerja"
              name="tgl_lahir"
              placeholderText="Pilih tgl. lahir"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode= "scroll"
              selected={values.tgl_lahir ? new Date(values.tgl_lahir) : undefined}
              onChange={date => setFieldValue('tgl_lahir', DateConvert(new Date(date)).default)}
              error={errors.tgl_lahir && touched.tgl_lahir}
              errorText={!!(errors.tgl_lahir && touched.tgl_lahir) && errors.tgl_lahir}
            />
          </Col>
          <Col md>
            <TextArea 
              label="Alamat Pelamar Kerja"
              rows={4}
              name="alamat"
              value={values.alamat}
              placeholder="Masukan alamat kerja"
              onChange={handleChange}
              error={errors.alamat && touched.alamat}
              errorText={!!(errors.alamat && touched.alamat) && errors.alamat}
            />
          </Col>
        </Row>
      
        {/* No. Telp & Email */}
        <Row>
          <Col md>
            <Input 
              label="Nomor Telepon"
              name="no_telepon"
              placeholder="Masukan nomor telepon pelamar kerja"
              onChange={e => setFieldValue('no_telepon', decimalConvert(e?.target?.value || ''))}
              value={values.no_telepon}
              error={errors.no_telepon && touched.no_telepon}
              errorText={!!(errors.no_telepon && touched.no_telepon) && errors.no_telepon}
            />
          </Col>
          <Col md>
            <Input 
              label="Email"
              name="email"
              placeholder="Masukan email pelamar kerja"
              value={values.email}
              onChange={handleChange}
              error={errors.email && touched.email}
              errorText={!!(errors.email && touched.email) && errors.email}
            />
          </Col>
        </Row>

        {/* Pendidikan Terakhir, Jurusan & Nama Sekolah */}
        <Row>
          <Col md>
            <SelectSearch 
              menuPortalTarget={document.body} 
              label="Pendidikan Terakhir"
              placeholder="Pilih pendidikan terakhir"
              defaultValue={values.pendidikan_terakhir ? dataPendidikanTerakhir.find(val => val === values.pendidikan_terakhir) ? ({value: dataPendidikanTerakhir.find(val => val === values.pendidikan_terakhir), label: dataPendidikanTerakhir.find(val => val === values.pendidikan_terakhir)}) : {} : ''}
              option={dataPendidikanTerakhir.map(val => ({label: val, value: val}))}
              onChange={val => setFieldValue('pendidikan_terakhir', val.value)}
              error={errors.pendidikan_terakhir && touched.pendidikan_terakhir}
              errorText={!!(errors.pendidikan_terakhir && touched.pendidikan_terakhir) && errors.pendidikan_terakhir}
            />
          </Col>
          <Col md>
            <Input 
              label="Jurusan"
              name="jurusan"
              placeholder="Masukan nama jurusan"
              value={values.jurusan}
              onChange={handleChange}
              error={errors.jurusan && touched.jurusan}
              errorText={!!(errors.jurusan && touched.jurusan) && errors.jurusan}
            />
          </Col>
          <Col md>
            <Input 
              label="Nama Sekolah/Institusi"
              name="institusi"
              placeholder="Masukan nama institusi"
              value={values.institusi}
              onChange={handleChange}
              error={errors.institusi && touched.institusi}
              errorText={!!(errors.institusi && touched.institusi) && errors.institusi}
            />
          </Col>
        </Row>

        {/* Posisi Yang Diinginkan & NIK */}
        <Row>
          <Col md>
            <Input 
              label="Posisi Yang Diinginkan"
              name="posisi"
              placeholder="Masukan posisi pelamar kerja"
              value={values.posisi}
              onChange={handleChange}
              error={errors.posisi && touched.posisi}
              errorText={!!(errors.posisi && touched.posisi) && errors.posisi}
            />
          </Col>
          <Col md>
            <Input 
              label="NIK"
              name="nik"
              placeholder="Masukan nik pelamar kerja"
              onChange={e => setFieldValue('nik', decimalConvert(e?.target?.value || ''))}
              value={values.nik}
              error={errors.nik && touched.nik}
              errorText={!!(errors.nik && touched.nik) && errors.nik}
            />
          </Col>
        </Row>

        {/* Dokumen */}
        <Row>
          <Col md>
            <InputFile 
              label="Dokumen CV"
              accept="application/pdf"
              name="cv"
              onChange={handleFileChange}
              text={values?.name_file_cv ?? values?.path_file_cv ?? 'Pilih dokumen'}
              error={errors.path_file_cv && touched.path_file_cv}
              errorText={!!(errors.path_file_cv && touched.path_file_cv) && errors.path_file_cv}
            />
            {values?.path_file_cv && 
              <ActionButton 
                size="sm" 
                className="my-2"
                text={<><IoEyeOutline /> Lihat Dokumen</>} 
                onClick={() => window.open(values?.path_file_cv)}
              />
            }
          </Col>
          <Col md>
            <InputFile 
              label="Dokumen KTP"
              accept="application/pdf"
              name="ktp"
              onChange={handleFileChange}
              text={values?.name_file_ktp ?? values?.path_file_ktp ?? 'Pilih dokumen'}
              error={errors.path_file_ktp && touched.path_file_ktp}
              errorText={!!(errors.path_file_ktp && touched.path_file_ktp) && errors.path_file_ktp}
            />
            {values?.path_file_ktp &&
              <ActionButton 
                size="sm" 
                className="my-2"
                text={<><IoEyeOutline /> Lihat Dokumen</>} 
                onClick={() => window.open(values?.path_file_ktp)}
              />
            }
          </Col>
        </Row>
    </>
  )
}
