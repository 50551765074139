import { FormFile } from "react-bootstrap"
import { ActionButton } from "components"

const InputDokumenKontrak = ({ type, document, setDocument }) => {

  const onSelectFile = (e) => {
    const file = e.target.files[0]

    if (!file || file.length === 0) {
      setDocument({})
      return
    }

    if (file.name.split(".").pop() === "pdf") {
      setDocument({
        data: e.target.files[0],
        name: e.target.files[0].name,
        link: URL.createObjectURL(e.target.files[0])
      })
    }
    else { window.alert("File harus berupa file PDF") }
  }

  return (
    <>
      <small>Dokumen Kontrak Pekerja</small>
      <FormFile
        custom
        type="file"
        style={{ zIndex: 0 }}
        accept="application/pdf"
      >
        <FormFile.Input size="sm" onChange={onSelectFile} />
        <FormFile.Label style={{ fontSize: 14 }}>
          {document?.name ? document.name : "Pilih dokumen"}
        </FormFile.Label>
      </FormFile>

      {type === "update" && document && (
        <ActionButton
          size="sm"
          className="mt-2"
          text={
            <a href={document.link} target='_blank' className="d-flex align-items-center text-white">
              <div className="ml-1">Lihat Dokumen Kontrak</div>
            </a>
          } />
      )}
    </>
  )
}

export default InputDokumenKontrak