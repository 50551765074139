import React, {
  useState,
  useEffect
} from 'react'
import { 
  useHistory,
  useLocation
} from 'react-router-dom'
import {
  debounce
} from 'lodash'
import {
  CRUDLayout,
  InputSearch,
  Alert,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ReadButton,
  UpdateButton,
  DataStatus,
  Pagination,
  CreateButton,
  FilterButton
} from 'components'
import {
  DateConvert,
  TableNumber
} from 'utilities'
import {
  PelamarKerjaApi
} from 'api'
import {
  StatusSeleksi,
  TahapanSeleksi,
  ModalFilter
} from './Comps'

export const PelamarKerjaList = ({setNavbarTitle}) => {
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState([])
  const [search, setSearch] = useState(undefined)
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true
  })
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.pelamar_kerja?.filter?.active,
      tgl_produksi_mulai: location?.state?.pelamar_kerja?.filter?.tgl_produksi_mulai,
      tgl_produksi_selesai: location?.state?.pelamar_kerja?.filter?.tgl_produksi_selesai,
      tgl_sales_order_mulai: location?.state?.pelamar_kerja?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: location?.state?.pelamar_kerja?.filter?.tgl_sales_order_selesai,
      customer: location?.state?.pelamar_kerja?.filter?.customer,
      barang_jadi: location?.state?.pelamar_kerja?.filter?.barang_jadi,
      status_progress: location?.state?.pelamar_kerja?.filter?.status_progress,
    },
    pagination: {
      page: location?.state?.pelamar_kerja?.filter?.page ?? "1",
      dataLength: location?.state?.pelamar_kerja?.filter?.dataLength ?? "10",
      totalPage: location?.state?.pelamar_kerja?.filter?.totalPage ?? "1",
      dataCount: location?.state?.pelamar_kerja?.filter?.dataCount ?? "0",
    }
  })
  
  const getData = () => {
    setData([])
    setFetchingStatus({
      loading: true,
      error: true
    })

    PelamarKerjaApi.get({
      page: dataFilter.pagination.page,
      per_page: dataFilter.pagination.dataLength,
      q: search,
      tgl_pelamar_kerja_mulai: dataFilter.filter.tgl_pelamar_kerja_mulai,
      tgl_pelamar_kerja_selesai: dataFilter.filter.tgl_pelamar_kerja_selesai,
      tgl_lahir_mulai: dataFilter.filter.tgl_lahir_mulai,
      tgl_lahir_selesai: dataFilter.filter.tgl_lahir_selesai,
      tgl_tahap_seleksi_mulai: dataFilter.filter.tgl_tahap_seleksi_mulai,
      tgl_tahap_seleksi_selesai: dataFilter.filter.tgl_tahap_seleksi_selesai,
      tgl_status_seleksi_mulai: dataFilter.filter.tgl_status_seleksi_mulai,
      tgl_status_seleksi_selesai: dataFilter.filter.tgl_status_seleksi_selesai,
      tahap_seleksi: dataFilter.filter.tahapan_seleksi,
      status_seleksi: dataFilter.filter.status_seleksi
    })
      .then((res) => {
        setData(res?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          }
        })
        setFetchingStatus({
          loading: false,
          error: false
        })
      })
      .catch(() => {
        setData([])
        setFetchingStatus({
          loading: false,
          error: true
        })
      })
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: 1
      }
    })
  }

  useEffect(() => {
    setNavbarTitle('Pelamar Kerja')
    setAlert({
      show: !!history?.location?.state?.alert?.show,
      variant: history?.location?.state?.alert?.variant,
      text: history?.location?.state?.alert?.text,
    })
  }, [])

  useEffect(() => {
    getData()
  }, [
    search,  
    dataFilter.pagination.page, 
    dataFilter.pagination.dataLength,
    dataFilter.filter.tgl_pelamar_kerja_mulai,
    dataFilter.filter.tgl_pelamar_kerja_selesai,
    dataFilter.filter.tgl_lahir_mulai,
    dataFilter.filter.tgl_lahir_selesai,
    dataFilter.filter.tgl_tahap_seleksi_mulai,
    dataFilter.filter.tgl_tahap_seleksi_selesai,
    dataFilter.filter.tgl_status_seleksi_mulai,
    dataFilter.filter.tgl_status_seleksi_selesai,
    dataFilter.filter.tahapan_seleksi,
    dataFilter.filter.status_seleksi
  ])

  return (
    <CRUDLayout>

      {/* Head */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch 
              onChange={debounce(searchHandler, 800)}
            />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton 
            onClick={() => history.push('/human-resource/master/karyawan/pelamar-kerja/tambah')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <div className="mt-2">
        <Alert 
          show={alert.show}
          variant={alert.variant}
          text={alert.text}
        />
      </div>

      {/* Content */}
      {fetchingStatus.loading || fetchingStatus.error
        ? <DataStatus 
            loading={fetchingStatus.loading}
            text={fetchingStatus.loading ? 'Memuat . . .' : 'Data gagal dimuat'}
          />
        : <>
            <div className="p-1">
              <b>List Data Pelamar Kerja</b>
            </div>
            <Table>
              <THead>
                <Tr>
                  <ThFixed>No.</ThFixed>
                  <ThFixed>Aksi</ThFixed>
                  <Th style={{minWidth: 100,}} className="p-1">Tgl. Pelamar Kerja</Th>
                  <Th style={{minWidth: 100,}} className="p-1">Kode. Pelamar Kerja</Th>
                  <Th style={{minWidth: 300,}} className="p-1">Nama Pelamar Kerja</Th>
                  <Th style={{minWidth: 150,}} className="p-1">NIK</Th>
                  <Th style={{width: 150,}} className="p-1">No. Telepon</Th>
                  <Th style={{width: 150,}} className="p-1">Email</Th>
                  <Th style={{minWidth: 200,}} className="p-1">Posisi Yang Diinginkan</Th>
                  <Th style={{minWidth: 150,}} className="p-1">Keterangan</Th>
                  <Th className="p-1">Tahapan Seleksi</Th>
                  <Th className="p-1">Status Seleksi</Th>
                </Tr>
              </THead>
              <TBody>
                {data && data?.length > 0
                  ? data.map((e, i) => (
                      <Tr key={i}>
                        <TdFixed>{TableNumber(dataFilter.pagination.page, dataFilter.pagination.dataLength, i)}</TdFixed>
                        <TdFixed>
                          <div className="d-flex">
                            <ReadButton size="sm" onClick={() => history.push('/human-resource/master/karyawan/pelamar-kerja/detail/' + e?.id_pelamar_kerja)} />
                            <UpdateButton size="sm" onClick={() => history.push('/human-resource/master/karyawan/pelamar-kerja/ubah/' + e?.id_pelamar_kerja)} />
                          </div>
                        </TdFixed>
                        <Td>{e?.tgl_pelamar_kerja ? DateConvert(new Date(e.tgl_pelamar_kerja)).defaultDMY : '-'} </Td>
                        <Td>{e?.kode_pelamar_kerja ?? '-'} </Td>
                        <Td>{e?.nama_pelamar_kerja ?? '-'}</Td>
                        <Td>{e?.nik ?? "-"}</Td>
                        <Td>{e?.no_telepon ?? '-'}</Td>
                        <Td>{e?.email ?? '-'}</Td>
                        <Td>{e?.posisi ?? '-'}</Td>
                        <Td>{e?.keterangan ?? '-'}</Td>
                        <Td>
                          <TahapanSeleksi
                            status={e?.tahap_seleksi}
                            date={e?.tgl_tahap_seleksi}
                          />
                        </Td>
                        <Td>
                          <StatusSeleksi 
                            status={e?.status_seleksi}
                            date={e?.tgl_status_seleksi}
                          />
                        </Td>
                      </Tr>
                    ))
                  : <Tr>
                      <Td colSpan={11}>
                        <DataStatus 
                          text="Tidak ada data"
                        />
                      </Td>
                    </Tr>
                }
              </TBody>
            </Table>
            <Pagination
              dataLength={dataFilter?.pagination?.dataLength}
              dataNumber={dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1}
              dataPage={
                dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                  ? dataFilter?.pagination?.dataCount
                  : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
              }
              dataCount={dataFilter?.pagination?.dataCount}
              currentPage={dataFilter?.pagination?.page}
              totalPage={dataFilter?.pagination?.totalPage}
              onPaginationChange={({ selected }) =>
                setDataFilter({
                  ...dataFilter,
                  pagination: {
                    ...dataFilter.pagination,
                    page: selected + 1,
                  }
                })
              }
              onDataLengthChange={(e) =>
                setDataFilter({
                  ...dataFilter,
                  pagination: {
                    ...dataFilter.pagination,
                    page: 1,
                    dataLength: e.target.value,
                  }
                })
              }
            />
          </>
      }
      <ModalFilter
        show={showModalFilter}
        setShow={setShowModalFilter}
        data={dataFilter}
        setData={setDataFilter}
      />
    </CRUDLayout>
  )
}