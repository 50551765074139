import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "react-bootstrap"
import { Formik, useFormik } from "formik"
import * as Yup from "yup"
import {
  Input,
  SelectSearch,
  ActionButton,
  Select,
  TextArea,
  Alert,
  DataStatus,
  CreateModal,
  UpdateModal,
  DatePicker,
} from "../../../../../components"
import _ from "lodash"
import { WilayahApi } from "../../../../../api"
import { RegistrasiKaryawanApi } from "../../../../../api"
import {
  IoTextOutline,
  IoAddOutline,
  IoPencilOutline,
  IoTrashOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from "react-icons/io5"
import { useHistory } from "react-router-dom"

import Kontrak from "../components/DataKontrak"

const FormDataAlamat = ({
  idKaryawan,
  dataProvinsi,
  formDataAlamat,
  dataJenjangPendidikan,
  dataBank,
}) => {
  const history = useHistory()

  const [dataAsal, setDataAsal] = useState({
    kabupaten: [],
    kecamatan: [],
    desa: [],
  })
  const [dataSekarang, setDataSekarang] = useState({
    kabupaten: [],
    kecamatan: [],
    desa: [],
  })
  const [isLoadingAsal, setIsLoadingAsal] = useState({
    kabupaten: false,
    kecamatan: false,
    desa: false,
  })
  const [isLoadingSekarang, setIsLoadingSekarang] = useState({
    kabupaten: false,
    kecamatan: false,
    desa: false,
  })
  const [alert, setAlert] = useState({
    data: "",
    text: "",
    variant: "",
  })

  const getDataKabupatenAsal = (id) => {
    setIsLoadingAsal({
      kabupaten: true,
      kecamatan: true,
      desa: true,
    })

    WilayahApi.getKabupaten(id)
      .then((res) =>
        setDataAsal({
          ...dataAsal,
          kabupaten: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingAsal({
          kabupaten: false,
          kecamatan: false,
          desa: false,
        })
      )
  }

  const getDataKecamatanAsal = (id) => {
    setIsLoadingAsal({
      ...isLoadingAsal,
      kecamatan: true,
      desa: true,
    })

    WilayahApi.getKecamatan(id)
      .then((res) =>
        setDataAsal({
          ...dataAsal,
          kecamatan: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingAsal({
          ...isLoadingAsal,
          kecamatan: false,
          desa: false,
        })
      )
  }

  const getDataDesaAsal = (id) => {
    setIsLoadingAsal({
      ...isLoadingAsal,
      desa: true,
    })

    WilayahApi.getDesa(id)
      .then((res) =>
        setDataAsal({
          ...dataAsal,
          desa: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingAsal({
          ...isLoadingAsal,
          desa: false,
        })
      )
  }

  const getDataKabupatenSekarang = (id) => {
    setIsLoadingSekarang({
      kabupaten: true,
      kecamatan: true,
      desa: true,
    })

    WilayahApi.getKabupaten(id)
      .then((res) =>
        setDataSekarang({
          ...dataSekarang,
          kabupaten: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingSekarang({
          kabupaten: false,
          kecamatan: false,
          desa: false,
        })
      )
  }

  const getDataKecamatanSekarang = (id) => {
    setIsLoadingSekarang({
      ...isLoadingSekarang,
      kecamatan: true,
      desa: true,
    })

    WilayahApi.getKecamatan(id)
      .then((res) =>
        setDataSekarang({
          ...dataSekarang,
          kecamatan: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingSekarang({
          ...isLoadingSekarang,
          kecamatan: false,
          desa: false,
        })
      )
  }

  const getDataDesaSekarang = (id) => {
    setIsLoadingSekarang({
      ...isLoadingSekarang,
      desa: true,
    })

    WilayahApi.getDesa(id)
      .then((res) =>
        setDataSekarang({
          ...dataSekarang,
          desa: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingSekarang({
          ...isLoadingSekarang,
          desa: false,
        })
      )
  }

  const formInitialValues = {
    // Alamat
    id_karyawan: idKaryawan,
    id_provinsi_asal: formDataAlamat.id_provinsi_asal,
    id_kabupaten_asal: formDataAlamat.id_kabupaten_asal,
    id_kecamatan_asal: formDataAlamat.id_kecamatan_asal,
    id_desa_asal: formDataAlamat.id_desa_asal,
    nama_provinsi_asal: formDataAlamat.nama_provinsi_asal,
    nama_kabupaten_asal: formDataAlamat.nama_kabupaten_asal,
    nama_kecamatan_asal: formDataAlamat.nama_kecamatan_asal,
    nama_desa_asal: formDataAlamat.nama_desa_asal,
    alamat_asal: formDataAlamat.alamat_asal,
    kode_pos_asal: formDataAlamat.kode_pos_asal,
    id_provinsi_saat_ini: formDataAlamat.id_provinsi_saat_ini,
    id_kabupaten_saat_ini: formDataAlamat.id_kabupaten_saat_ini,
    id_kecamatan_saat_ini: formDataAlamat.id_kecamatan_saat_ini,
    id_desa_saat_ini: formDataAlamat.id_desa_saat_ini,
    nama_provinsi_saat_ini: formDataAlamat.nama_provinsi_saat_ini,
    nama_kabupaten_saat_ini: formDataAlamat.nama_kabupaten_saat_ini,
    nama_kecamatan_saat_ini: formDataAlamat.nama_kecamatan_saat_ini,
    nama_desa_saat_ini: formDataAlamat.nama_desa_saat_ini,
    alamat_saat_ini: formDataAlamat.alamat_saat_ini,
    kode_pos_saat_ini: formDataAlamat.kode_pos_saat_ini,

    // Kontak
    no_telp: "",
    no_hp: "",
    email: "",
    kontak_darurat: "",
    keterangan_kontak_darurat: "",

    // Pendidikan Terakhir
    id_jenjang_pendidikan: "",
    lembaga_pendidikan: "",
    tahun_lulus: "",

    // Bank
    kode_bank: "",
    no_rekening: "",
    atas_nama: "",
    cabang: "",

    // BPJS
    bpjs_kesehatan: "",
    bpjs_ketenagakerjaan: "",
  }

  const formValidationSchemaAlamat = Yup.object().shape({
    id_provinsi_asal: Yup.string().required("Pilih provinsi asal").nullable(),
    id_kabupaten_asal: Yup.string().required("Pilih kabupaten asal").nullable(),
    id_kecamatan_asal: Yup.string().required("Pilih kecamatan asal").nullable(),
    id_desa_asal: Yup.string().required("Pilih desa asal").nullable(),
    alamat_asal: Yup.string().required("Masukan alamat asal").nullable(),
    kode_pos_asal: Yup.string().required("Masukan kode pos asal").nullable(),
    id_provinsi_saat_ini: Yup.string().required("Pilih provinsi saat ini").nullable(),
    id_kabupaten_saat_ini: Yup.string().required("Pilih kabupaten saat ini").nullable(),
    id_kecamatan_saat_ini: Yup.string().required("Pilih kecamatan saat ini").nullable(),
    id_desa_saat_ini: Yup.string().required("Pilih desa saat ini").nullable(),
    alamat_saat_ini: Yup.string().required("Masukan alamat saat ini").nullable(),
    kode_pos_saat_ini: Yup.string().required("Masukan kode pos saat ini").nullable(),
  })

  const formValidationSchemaKontak = Yup.object().shape({
    no_telp: Yup.string()
      .required("Masukan nomor telephone")
      .test("checkDuplicate", "No Telp Sudah Didaftarkan", (value) =>
        RegistrasiKaryawanApi.checkDuplicate({ no_telp: value })
          .then(() => true)
          .catch(() => false)
      )
      .nullable(),
    no_hp: Yup.string()
      .required("Masukan nomor handphone")
      .test("checkDuplicate", "No Telp Sudah Didaftarkan", (value) =>
        RegistrasiKaryawanApi.checkDuplicate({ no_hp: value })
          .then(() => true)
          .catch(() => false)
      ),
    kontak_darurat: Yup.string().required("Masukan kontak darurat"),
  })

  const formValidationSchemaPendidikan = Yup.object().shape({
    id_jenjang_pendidikan: Yup.string().required("Pilih jenjang pendidikan"),
    lembaga_pendidikan: Yup.string().required("Masukan lembaga pendidikan"),
    tahun_lulus: Yup.string().required("Masukan tahun lulus"),
  })

  const formValidationSchemaBPJS = Yup.object().shape({
    bpjs_kesehatan: Yup.string()
      .required("Masukan nomor BPJS kesehatan")
      .test("checkDuplicate", "No BPJS Kesehatan Sudah Didaftarkan", (value) =>
        RegistrasiKaryawanApi.checkDuplicate({ bpjs_kesehatan: value })
          .then(() => true)
          .catch(() => false)
      ),
    bpjs_ketenagakerjaan: Yup.string()
      .required("Masukan nomor BPJS ketenagakerjaan")
      .test("checkDuplicate", "No BPJS Ketenagakerjaan Sudah Didaftarkan", (value) =>
        RegistrasiKaryawanApi.checkDuplicate({ bpjs_ketenagakerjaan: value })
          .then(() => true)
          .catch(() => false)
      ),
  })

  const formValidationSchemaBank = Yup.object().shape({
    kode_bank: Yup.string().required("Pilih bank").nullable(),
    no_rekening: Yup.string()
      .required("Masukan nomor rekening")
      .test("checkDuplicate", "No Rekening Sudah Didaftarkan", (value) =>
        RegistrasiKaryawanApi.checkDuplicate({ no_rekening: value })
          .then(() => true)
          .catch(() => false)
      ),
    atas_nama: Yup.string().required("Masukan atas nama rekening").nullable(),
  })

  const formSubmitHandlerAlamat = (values) => {
    RegistrasiKaryawanApi.updateDataAlamat({
      id_karyawan: values.id_karyawan,
      id_provinsi_asal: values.id_provinsi_asal,
      id_kabupaten_asal: values.id_kabupaten_asal,
      id_kecamatan_asal: values.id_kecamatan_asal,
      id_desa_asal: values.id_desa_asal,
      nama_provinsi_asal: values.nama_provinsi_asal,
      nama_kabupaten_asal: values.nama_kabupaten_asal,
      nama_kecamatan_asal: values.nama_kecamatan_asal,
      nama_desa_asal: values.nama_desa_asal,
      alamat_asal: values.alamat_asal,
      kode_pos_asal: values.kode_pos_asal,
      id_provinsi_saat_ini: values.id_provinsi_saat_ini,
      id_kabupaten_saat_ini: values.id_kabupaten_saat_ini,
      id_kecamatan_saat_ini: values.id_kecamatan_saat_ini,
      id_desa_saat_ini: values.id_desa_saat_ini,
      nama_provinsi_saat_ini: values.nama_provinsi_saat_ini,
      nama_kabupaten_saat_ini: values.nama_kabupaten_saat_ini,
      nama_kecamatan_saat_ini: values.nama_kecamatan_saat_ini,
      nama_desa_saat_ini: values.nama_desa_saat_ini,
      alamat_saat_ini: values.alamat_saat_ini,
      kode_pos_saat_ini: values.kode_pos_saat_ini,
    })
      .then(() =>
        setAlert({
          data: "Alamat",
          text: "Alamat berhasil diubah",
          variant: "success",
        })
      )
      .catch(() =>
        setAlert({
          data: "Alamat",
          text: "Alamat gagal diubah",
          variant: "danger",
        })
      )
  }

  const formSubmitHandlerKontak = (values) => {
    RegistrasiKaryawanApi.updateDataKontak({
      id_karyawan: values.id_karyawan,
      no_telp: values.no_telp,
      no_hp: values.no_hp,
      email: values.email,
      kontak_darurat: values.kontak_darurat,
      keterangan_kontak_darurat: values.keterangan_kontak_darurat,
    })
      .then(() =>
        setAlert({
          data: "Kontak",
          text: "Kontak berhasil diubah",
          variant: "success",
        })
      )
      .catch(() =>
        setAlert({
          data: "Kontak",
          text: "Kontak gagal diubah",
          variant: "danger",
        })
      )
  }

  const formSubmitHandlerPendidikan = (values) => {
    RegistrasiKaryawanApi.updateDataPendidikan({
      id_karyawan: values.id_karyawan,
      id_jenjang_pendidikan: values.id_jenjang_pendidikan,
      lembaga_pendidikan: values.lembaga_pendidikan,
      tahun_lulus: values.tahun_lulus,
    })
      .then(() =>
        setAlert({
          data: "Pendidikan",
          text: "Pendidikan berhasil diubah",
          variant: "success",
        })
      )
      .catch(() =>
        setAlert({
          data: "Pendidikan",
          text: "Pendidikan gagal diubah",
          variant: "danger",
        })
      )
  }

  const formSubmitHandlerBank = (values) => {
    RegistrasiKaryawanApi.updateDataBank({
      id_karyawan: values.id_karyawan,
      kode_bank: values.kode_bank,
      no_rekening: values.no_rekening,
      atas_nama: values.atas_nama,
      cabang: values.cabang,
    })
      .then(() =>
        setAlert({
          data: "Bank",
          text: "Bank berhasil diubah",
          variant: "success",
        })
      )
      .catch(() =>
        setAlert({
          data: "Bank",
          text: "Bank gagal diubah",
          variant: "danger",
        })
      )
  }

  const formSubmitHandlerBPJS = (values) => {
    RegistrasiKaryawanApi.updateDataBPJS({
      id_karyawan: values.id_karyawan,
      bpjs_kesehatan: values.bpjs_kesehatan,
      bpjs_ketenagakerjaan: values.bpjs_ketenagakerjaan,
    })
      .then(() =>
        setAlert({
          data: "BPJS",
          text: "BPJS berhasil diubah",
          variant: "success",
        })
      )
      .catch(() =>
        setAlert({
          data: "BPJS",
          text: "BPJS gagal diubah",
          variant: "danger",
        })
      )
  }

  const ColumnData = ({ title, className, children }) => (
    <div className={className ? className : "mb-4"}>
      <h6
        className="ml-1 text-dark"
        style={{
          fontSize: "17px",
          marginBottom: "12.5px",
        }}
      >
        <b>{title}</b>
      </h6>
      <div className="bg-white p-2 px-3 shadow-sm rounded">{children}</div>
    </div>
  )

  const Keluarga = () => {
    const [isFormHeader, setIsFormHeader] = useState(false)
    const [loadingTambah, setLoadingTambah] = useState(false)
    const [loading, setLoading] = useState(false)
    const [processedData, setProcessedData] = useState({})
    const [editedData, setEditedData] = useState({})
    const [dataKeluarga, setDataKeluarga] = useState([])
    const [modalConfig, setModalConfig] = useState({
      show: false,
      type: "danger",
    })

    useEffect(() => {
      getDataKeluarga()
    }, [])

    const getDataKeluarga = () => {
      setLoading(true)

      RegistrasiKaryawanApi.getKeluarga(idKaryawan)
        .then((res) => setDataKeluarga(res.data.data_keluarga))
        .finally(() => setLoading(false))
    }

    const tableStyling = {
      padding: "0.5px",
      fontSize: "14px",
      verticalAlign: "middle",
    }
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    })

    const formInitialValues = {
      id_anggota_keluarga: "",
      jenis_anggota_keluarga: "",
      nama_anggota_keluarga: "",
      tgl_lahir: "",
      keterangan: "",
    }

    const formSubmitHandler = (values) => {
      setLoadingTambah(true)

      RegistrasiKaryawanApi.updateDataKeluarga({
        id_karyawan: idKaryawan,
        data_keluarga: [
          {
            jenis_anggota_keluarga: values.jenis_anggota_keluarga,
            nama_anggota_keluarga: values.nama_anggota_keluarga,
            tgl_lahir: values.tgl_lahir,
            keterangan: values.keterangan,
          },
        ],
      })
        .then(() =>
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil ditambah!",
          })
        )
        .catch(() =>
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal ditambah!",
          })
        )
        .finally(() => {
          setLoadingTambah(false)

          getDataKeluarga()
        })
    }

    const formValidationSchema = Yup.object().shape({
      nama_anggota_keluarga: Yup.string().required(),
      jenis_anggota_keluarga: Yup.string().required(),
      tgl_lahir: Yup.string().required(),
    })

    const formik = useFormik({
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    })

    const ListDataTable = ({ index, val, setProcessedData, setModalConfig }) => {
      return (
        <tr key={index}>
          <td className="px-2 text-center" style={tableStyling}>
            {index + 1}
          </td>
          <td style={tableStyling}>{val.nama_anggota_keluarga}</td>
          <td style={tableStyling}>{val.jenis_anggota_keluarga}</td>
          <td style={tableStyling}>{val.tgl_lahir}</td>
          <td style={tableStyling}>{val.keterangan}</td>
          <td
            className="d-flex justify-content-center align-items-center btn-group px-1"
            style={tableStyling}
          >
            <ActionButton
              size="sm"
              variant="success"
              text={<IoPencilOutline />}
              onClick={() => setEditedData(val)}
            />
            <ActionButton
              size="sm"
              variant="danger"
              text={<IoTrashOutline />}
              onClick={() => {
                setProcessedData(val)
                setModalConfig({
                  show: true,
                  type: "danger",
                })
              }}
            />
          </td>
        </tr>
      )
    }

    const PageModal = () => {
      const ModalDelete = () => {
        const [isDeleting, setIsDeleting] = useState(false)

        const deleteDataHandler = () => {
          setIsDeleting(true)

          RegistrasiKaryawanApi.deleteKeluarga({
            id_anggota_keluarga: processedData.id_anggota_keluarga,
          })
            .then(() => {
              setModalConfig({
                ...modalConfig,
                show: false,
              })
              setAlertConfig({
                show: true,
                variant: "primary",
                text: "Data berhasil dihapus!",
              })

              getDataKeluarga()
            })
            .finally(() => setIsDeleting(false))
        }

        useEffect(() => {
          return () => {
            setIsDeleting(false)
          }
        }, [])

        const ModalDeleteText = () => (
          <>
            <h5>
              <span>Hapus data dengan nama: </span>
              <br />
              <b>{processedData.nama_anggota_keluarga}</b>
            </h5>
            <small className="text-danger">Data yang dihapus tidak dapat dikembalikan!</small>
          </>
        )

        const ModalDeleteButton = () => (
          <div className="d-flex justify-content-center mt-3">
            <ActionButton
              variant="outline-secondary"
              className="m-1"
              text="Batal"
              onClick={() =>
                setModalConfig({
                  ...modalConfig,
                  show: false,
                })
              }
            />
            <ActionButton
              variant="danger"
              className="m-1"
              text="Hapus Data"
              loading={isDeleting}
              onClick={deleteDataHandler}
            />
          </div>
        )

        return (
          <>
            <Modal.Body className="text-center">
              <ModalDeleteText />
              <ModalDeleteButton />
            </Modal.Body>
          </>
        )
      }

      return (
        <Modal
          show={modalConfig.show}
          onHide={() =>
            setModalConfig({
              ...modalConfig,
              show: false,
            })
          }
        >
          <Modal.Header closeButton>
            <span className="text-danger">Hapus Data Keluarga</span>
          </Modal.Header>
          <ModalDelete />
        </Modal>
      )
    }

    const EditDataTable = ({ index, val }) => {
      const formEditInitialValues = {
        id_anggota_keluarga: val.id_anggota_keluarga,
        nama_anggota_keluarga: val.nama_anggota_keluarga,
        jenis_anggota_keluarga: val.jenis_anggota_keluarga,
        tgl_lahir: val.tgl_lahir,
        keterangan: val.keterangan,
      }

      const formEditValidationSchema = Yup.object().shape({
        id_anggota_keluarga: Yup.string().required(),
        nama_anggota_keluarga: Yup.string().required(),
        jenis_anggota_keluarga: Yup.string().required(),
        tgl_lahir: Yup.string().required(),
      })

      const formEditSubmitHandler = (values) => {
        RegistrasiKaryawanApi.updateKeluarga({
          id_karyawan: idKaryawan,
          id_anggota_keluarga: values.id_anggota_keluarga,
          nama_anggota_keluarga: values.nama_anggota_keluarga,
          jenis_anggota_keluarga: values.jenis_anggota_keluarga,
          tgl_lahir: values.tgl_lahir,
          keterangan: values.keterangan,
        }).then(() => {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil diubah!",
          })
          setEditedData({})
          getDataKeluarga()
        })
      }

      const editFormik = useFormik({
        enableReinitialize: true,
        initialValues: formEditInitialValues,
        validationSchema: formEditValidationSchema,
        onSubmit: formEditSubmitHandler,
      })

      const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = editFormik

      return (
        <tr key={index}>
          <td style={tableStyling} className="text-center">
            {index + 1}
          </td>
          <td style={tableStyling}>
            <input
              name="nama_anggota_keluarga"
              className={`form-control form-control-sm ${errors.nama_anggota_keluarga && touched.nama_anggota_keluarga ? "is-invalid" : ""
                }`}
              onChange={handleChange}
              value={values.nama_anggota_keluarga}
              readOnly={isFormHeader ? true : false}
            />
          </td>
          <td style={tableStyling}>
            <select
              name="jenis_anggota_keluarga"
              className={`custom-select custom-select-sm ${errors.jenis_anggota_keluarga && touched.jenis_anggota_keluarga ? "is-invalid" : ""
                }`}
              onChange={handleChange}
              disabled={isFormHeader ? true : false}
              defaultValue={values.jenis_anggota_keluarga}
            >
              <option value="" hidden>
                Pilih status keluarga
              </option>
              <option value="Ayah">Ayah</option>
              <option value="Ibu">Ibu</option>
              <option value="Saudara Kandung">Saudara Kandung</option>
              <option value="Suami">Suami</option>
              <option value="Istri">Istri</option>
              <option value="Anak">Anak</option>
              <option value="Paman">Paman</option>
              <option value="Bibi">Bibi</option>
              <option value="Keponakan">Keponakan</option>
              <option value="Sepupu">Sepupu</option>
              <option value="Kakek">Kakek</option>
              <option value="Nenek">Nenek</option>
            </select>
          </td>
          <td style={tableStyling}>
            <input
              name="tgl_lahir"
              type="date"
              className={`form-control form-control-sm ${errors.tgl_lahir && touched.tgl_lahir ? "is-invalid" : ""
                }`}
              onChange={handleChange}
              value={values.tgl_lahir}
              readOnly={isFormHeader ? true : false}
            />
          </td>
          <td style={tableStyling}>
            <input
              name="keterangan"
              className={`form-control form-control-sm ${errors.keterangan && touched.keterangan ? "is-invalid" : ""
                }`}
              onChange={handleChange}
              value={values.keterangan}
              readOnly={isFormHeader ? true : false}
            />
          </td>
          <td style={tableStyling}>
            <div className="d-flex justify-content-around align-items-center btn-group px-1">
              <ActionButton
                size="sm"
                variant="outline-success"
                text={<IoCheckmarkOutline />}
                onClick={handleSubmit}
              />
              <ActionButton
                size="sm"
                variant="outline-danger"
                text={<IoCloseOutline />}
                onClick={() => setEditedData({})}
              />
            </div>
          </td>
        </tr>
      )
    }

    const { values, errors, touched, setFieldValue, handleChange, handleSubmit, isSubmitting } =
      formik

    return (
      <>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        {loading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : (
          <div className="table-responsive">
            <table className="table table-bordered bg-white table-sm">
              <thead className="text-center">
                <tr>
                  <th style={{ ...tableStyling, width: 40 }}>No.</th>
                  <th style={tableStyling}>Nama Anggota Keluarga</th>
                  <th style={tableStyling}>Status Anggota Keluarga</th>
                  <th style={{ ...tableStyling, width: 70 }}>Tgl. Lahir</th>
                  <th style={{ ...tableStyling, minWidth: "100px", width: "150px" }}>Keterangan</th>
                  <th style={{ ...tableStyling, width: "60px" }}>Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center"></td>
                  <td style={{ ...tableStyling, minWidth: "150px", width: "225px" }}>
                    <input
                      name="nama_anggota_keluarga"
                      className={`form-control form-control-sm ${errors.nama_anggota_keluarga && touched.nama_anggota_keluarga
                        ? "is-invalid"
                        : ""
                        }`}
                      onChange={handleChange}
                      value={values.nama_anggota_keluarga}
                      readOnly={isFormHeader ? true : false}
                    />
                  </td>
                  <td style={{ ...tableStyling, minWidth: "150px", width: "225px" }}>
                    <select
                      name="jenis_anggota_keluarga"
                      className={`custom-select custom-select-sm ${errors.jenis_anggota_keluarga && touched.jenis_anggota_keluarga
                        ? "is-invalid"
                        : ""
                        }`}
                      onChange={handleChange}
                      disabled={isFormHeader ? true : false}
                    >
                      <option value="" hidden>
                        Pilih status keluarga
                      </option>
                      <option value="Ayah">Ayah</option>
                      <option value="Ibu">Ibu</option>
                      <option value="Saudara Kandung">Saudara Kandung</option>
                      <option value="Suami">Suami</option>
                      <option value="Istri">Istri</option>
                      <option value="Anak">Anak</option>
                      <option value="Paman">Paman</option>
                      <option value="Bibi">Bibi</option>
                      <option value="Keponakan">Keponakan</option>
                      <option value="Sepupu">Sepupu</option>
                      <option value="Kakek">Kakek</option>
                      <option value="Nenek">Nenek</option>
                    </select>
                  </td>
                  <td style={tableStyling}>
                    <input
                      name="tgl_lahir"
                      type="date"
                      className={`form-control form-control-sm ${errors.tgl_lahir && touched.tgl_lahir ? "is-invalid" : ""
                        }`}
                      onChange={handleChange}
                      value={values.tgl_lahir}
                      readOnly={isFormHeader ? true : false}
                    />
                  </td>
                  <td>
                    <input
                      name="keterangan"
                      className={`form-control form-control-sm ${errors.keterangan && touched.keterangan ? "is-invalid" : ""
                        }`}
                      onChange={handleChange}
                      value={values.keterangan}
                      readOnly={isFormHeader ? true : false}
                    />
                  </td>
                  <td style={tableStyling}>
                    <div className="d-flex justify-content-center align-items-center btn-group px-1">
                      <ActionButton
                        size="sm"
                        variant={isFormHeader ? "info" : "secondary"}
                        text={<IoTextOutline />}
                        onClick={() => setIsFormHeader(!isFormHeader)}
                      />
                      <ActionButton
                        size="sm"
                        text={<IoAddOutline />}
                        onClick={handleSubmit}
                        loading={loadingTambah}
                      />
                    </div>
                  </td>
                </tr>
                {dataKeluarga?.map((val, index) => {
                  return editedData === val ? (
                    <EditDataTable index={index} val={val} />
                  ) : (
                    <ListDataTable
                      index={index}
                      val={val}
                      editedData={editedData}
                      setEditedData={setEditedData}
                      setProcessedData={setProcessedData}
                      setModalConfig={setModalConfig}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        )}

        <PageModal />
      </>
    )
  }

  return (
    <>
      <Row>
        <Col lg="6">
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchemaAlamat}
            onSubmit={formSubmitHandlerAlamat}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form className="py-2" onSubmit={handleSubmit}>
                <ColumnData title="Data Alamat">
                  <Alert
                    show={alert.data == "Alamat" ? true : false}
                    showCloseButton={true}
                    text={alert.text}
                    variant={alert.variant}
                    onClose={() =>
                      setAlert({
                        data: "",
                        text: "",
                        variant: "",
                      })
                    }
                  />

                  <Row>
                    <Col lg>
                      <SelectSearch
                        label="Provinsi Asal"
                        placeholder="Pilih provinsi asal"
                        name="id_provinsi_asal"
                        defaultValue={{
                          value: values.id_provinsi_asal ? values.id_provinsi_asal : "",
                          label: values.nama_provinsi_asal
                            ? values.nama_provinsi_asal
                            : "Pilih provinsi asal",
                        }}
                        option={dataProvinsi.map((val) => {
                          return {
                            value: val.id_provinsi,
                            label: val.nama_provinsi,
                          }
                        })}
                        onChange={(val) => {
                          setFieldValue("id_provinsi_asal", val.value)
                          setFieldValue("nama_provinsi_asal", val.label)
                          setFieldValue("id_kabupaten_asal", "")
                          setFieldValue("id_kecamatan_asal", "")
                          setFieldValue("id_desa_asal", "")
                          getDataKabupatenAsal(val.value)
                        }}
                        error={errors.id_provinsi_asal && touched.id_provinsi_asal && true}
                        errorText={
                          errors.id_provinsi_asal &&
                          touched.id_provinsi_asal &&
                          errors.id_provinsi_asal
                        }
                      />
                    </Col>
                    <Col lg>
                      <SelectSearch
                        label="Kabupaten Asal"
                        placeholder="Pilih kabupaten asal"
                        name="id_kabupaten_asal"
                        defaultValue={
                          values.id_kabupaten_asal
                            ? {
                              value: values.id_kabupaten_asal,
                              label: values.nama_kabupaten_asal,
                            }
                            : false
                        }
                        option={dataAsal.kabupaten.map((val) => {
                          return {
                            value: val.id_kabupaten,
                            label: val.nama_kabupaten,
                          }
                        })}
                        onChange={(val) => {
                          setFieldValue("id_kabupaten_asal", val.value)
                          setFieldValue("nama_kabupaten_asal", val.label)
                          setFieldValue("id_kecamatan_asal", "")
                          setFieldValue("id_desa_asal", "")
                          getDataKecamatanAsal(val.value)
                        }}
                        loading={isLoadingAsal.kabupaten}
                        isDisabled={values.id_provinsi_asal ? false : true}
                        error={errors.id_kabupaten_asal && touched.id_kabupaten_asal && true}
                        errorText={
                          errors.id_kabupaten_asal &&
                          touched.id_kabupaten_asal &&
                          errors.id_kabupaten_asal
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg>
                      <SelectSearch
                        label="Kecamatan Asal"
                        placeholder="Pilih kecamatan asal"
                        name="id_kecamatan_asal"
                        defaultValue={
                          values.id_kecamatan_asal
                            ? {
                              value: values.id_kecamatan_asal,
                              label: values.nama_kecamatan_asal,
                            }
                            : false
                        }
                        option={dataAsal.kecamatan.map((val) => {
                          return {
                            value: val.id_kecamatan,
                            label: val.nama_kecamatan,
                          }
                        })}
                        onChange={(val) => {
                          setFieldValue("id_kecamatan_asal", val.value)
                          setFieldValue("nama_kecamatan_asal", val.label)
                          setFieldValue("id_desa_asal", "")
                          getDataDesaAsal(val.value)
                        }}
                        loading={isLoadingAsal.kecamatan}
                        isDisabled={values.id_kabupaten_asal ? false : true}
                        error={errors.id_kecamatan_asal && touched.id_kecamatan_asal && true}
                        errorText={
                          errors.id_kecamatan_asal &&
                          touched.id_kecamatan_asal &&
                          errors.id_kecamatan_asal
                        }
                      />
                    </Col>
                    <Col lg>
                      <SelectSearch
                        label="Desa Asal"
                        placeholder="Pilih desa asal"
                        name="id_desa_asal"
                        defaultValue={
                          values.id_desa_asal
                            ? {
                              value: values.id_desa_asal,
                              label: values.nama_desa_asal,
                            }
                            : false
                        }
                        option={dataAsal.desa.map((val) => {
                          return {
                            value: val.id_desa,
                            label: val.nama_desa,
                          }
                        })}
                        onChange={(val) => {
                          setFieldValue("id_desa_asal", val.value)
                          setFieldValue("nama_desa_asal", val.label)
                        }}
                        loading={isLoadingAsal.desa}
                        isDisabled={values.id_kecamatan_asal ? false : true}
                        error={errors.id_desa_asal && touched.id_desa_asal && true}
                        errorText={
                          errors.id_desa_asal && touched.id_desa_asal && errors.id_desa_asal
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg>
                      <Input
                        type="text"
                        label="Alamat Asal"
                        placeholder="Masukan alamat asal"
                        name="alamat_asal"
                        value={values.alamat_asal}
                        error={errors.alamat_asal && touched.alamat_asal && true}
                        errorText={errors.alamat_asal}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col lg>
                      <Input
                        type="text"
                        label="Kode Pos"
                        placeholder="Masukan kode pos asal"
                        name="kode_pos_asal"
                        value={values.kode_pos_asal}
                        error={errors.kode_pos_asal && touched.kode_pos_asal && true}
                        errorText={errors.kode_pos_asal}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg>
                      <SelectSearch
                        label="Provinsi Saat Ini"
                        placeholder="Pilih provinsi saat ini"
                        name="id_provinsi_saat_ini"
                        defaultValue={{
                          value: values.id_provinsi_saat_ini ? values.id_provinsi_saat_ini : "",
                          label: values.nama_provinsi_saat_ini
                            ? values.nama_provinsi_saat_ini
                            : "Pilih provinsi saat ini",
                        }}
                        option={dataProvinsi.map((val) => {
                          return {
                            value: val.id_provinsi,
                            label: val.nama_provinsi,
                          }
                        })}
                        onChange={(val) => {
                          setFieldValue("id_provinsi_saat_ini", val.value)
                          setFieldValue("nama_provinsi_saat_ini", val.label)
                          setFieldValue("id_kabupaten_saat_ini", "")
                          setFieldValue("id_kecamatan_saat_ini", "")
                          setFieldValue("id_desa_saat_ini", "")
                          getDataKabupatenSekarang(val.value)
                        }}
                        error={errors.id_provinsi_saat_ini && touched.id_provinsi_saat_ini && true}
                        errorText={
                          errors.id_provinsi_saat_ini &&
                          touched.id_provinsi_saat_ini &&
                          errors.id_provinsi_saat_ini
                        }
                      />
                    </Col>
                    <Col lg>
                      <SelectSearch
                        label="Kabupaten Saat Ini"
                        placeholder="Pilih kabupaten saat ini"
                        name="id_kabupaten_saat_ini"
                        defaultValue={
                          values.id_kabupaten_saat_ini
                            ? {
                              value: values.id_kabupaten_saat_ini,
                              label: values.nama_kabupaten_saat_ini,
                            }
                            : false
                        }
                        option={dataSekarang.kabupaten.map((val) => {
                          return {
                            value: val.id_kabupaten,
                            label: val.nama_kabupaten,
                          }
                        })}
                        onChange={(val) => {
                          setFieldValue("id_kabupaten_saat_ini", val.value)
                          setFieldValue("nama_kabupaten_saat_ini", val.label)
                          setFieldValue("id_kecamatan_saat_ini", "")
                          setFieldValue("id_desa_saat_ini", "")
                          getDataKecamatanSekarang(val.value)
                        }}
                        loading={isLoadingSekarang.kabupaten}
                        isDisabled={values.id_provinsi_saat_ini ? false : true}
                        error={
                          errors.id_kabupaten_saat_ini && touched.id_kabupaten_saat_ini && true
                        }
                        errorText={errors.id_kabupaten_saat_ini && touched.id_kabupaten_saat_ini}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg>
                      <SelectSearch
                        label="Kecamatan Saat Ini"
                        placeholder="Pilih kecamatan saat ini"
                        name="id_kecamatan_saat_ini"
                        defaultValue={
                          values.id_kecamatan_saat_ini
                            ? {
                              value: values.id_kecamatan_saat_ini,
                              label: values.nama_kecamatan_saat_ini,
                            }
                            : false
                        }
                        option={dataSekarang.kecamatan.map((val) => {
                          return {
                            value: val.id_kecamatan,
                            label: val.nama_kecamatan,
                          }
                        })}
                        onChange={(val) => {
                          setFieldValue("id_kecamatan_saat_ini", val.value)
                          setFieldValue("nama_kecamatan_saat_ini", val.label)
                          setFieldValue("id_desa_saat_ini", "")
                          getDataDesaSekarang(val.value)
                        }}
                        loading={isLoadingSekarang.kecamatan}
                        isDisabled={values.id_kabupaten_saat_ini ? false : true}
                        error={
                          errors.id_kecamatan_saat_ini && touched.id_kecamatan_saat_ini && true
                        }
                        errorText={
                          errors.id_kecamatan_saat_ini &&
                          touched.id_kecamatan_saat_ini &&
                          errors.id_kecamatan_saat_ini
                        }
                      />
                    </Col>
                    <Col lg>
                      <SelectSearch
                        label="Desa Saat Ini"
                        placeholder="Pilih desa saat ini"
                        name="id_desa_saat_ini"
                        defaultValue={
                          values.id_desa_saat_ini
                            ? {
                              value: values.id_desa_saat_ini,
                              label: values.nama_desa_saat_ini,
                            }
                            : false
                        }
                        option={dataSekarang.desa.map((val) => {
                          return {
                            value: val.id_desa,
                            label: val.nama_desa,
                          }
                        })}
                        onChange={(val) => {
                          setFieldValue("id_desa_saat_ini", val.value)
                          setFieldValue("nama_desa_saat_ini", val.label)
                        }}
                        loading={isLoadingSekarang.desa}
                        isDisabled={values.id_kecamatan_saat_ini ? false : true}
                        error={errors.id_desa_saat_ini && touched.id_desa_saat_ini && true}
                        errorText={
                          errors.id_desa_saat_ini &&
                          touched.id_desa_saat_ini &&
                          errors.id_desa_saat_ini
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg>
                      <Input
                        type="text"
                        label="Alamat Saat Ini"
                        placeholder="Masukan alamat saat ini"
                        name="alamat_saat_ini"
                        value={values.alamat_saat_ini}
                        error={errors.alamat_saat_ini && touched.alamat_saat_ini && true}
                        errorText={errors.alamat_saat_ini}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col lg>
                      <Input
                        type="text"
                        label="Kode Pos Saat Ini"
                        placeholder="Masukan kode pos saat ini"
                        name="kode_pos_saat_ini"
                        value={values.kode_pos_saat_ini}
                        error={errors.kode_pos_saat_ini && touched.kode_pos_saat_ini && true}
                        errorText={errors.kode_pos_saat_ini}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end pt-3">
                    <ActionButton type="submit" variant="primary" text="Simpan" />
                  </div>
                </ColumnData>
              </form>
            )}
          </Formik>

          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchemaPendidikan}
            onSubmit={formSubmitHandlerPendidikan}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form className="py-2" onSubmit={handleSubmit}>
                <ColumnData title="Data Pendidikan Terakhir">
                  <Alert
                    show={alert.data == "Pendidikan" ? true : false}
                    showCloseButton={true}
                    text={alert.text}
                    variant={alert.variant}
                    onClose={() =>
                      setAlert({
                        data: "",
                        text: "",
                        variant: "",
                      })
                    }
                  />

                  <Row>
                    <Col lg>
                      <Select
                        label="Jenjang Pendidikan"
                        name="id_jenjang_pendidikan"
                        defaultValue={values.id_jenjang_pendidikan}
                        error={
                          errors.id_jenjang_pendidikan && touched.id_jenjang_pendidikan && true
                        }
                        errorText={errors.id_jenjang_pendidikan}
                        onChange={handleChange}
                      >
                        <option value="" hidden>
                          Pilih jenjang pendidikan
                        </option>
                        {dataJenjangPendidikan.map((val, index) => (
                          <option key={index} value={val.id_jenjang_pendidikan}>
                            {val.nama_jenjang_pendidikan}
                          </option>
                        ))}
                      </Select>
                    </Col>
                    <Col lg>
                      <Select
                        label="Tahun Lulus"
                        name="tahun_lulus"
                        defaultValue={values.tahun_lulus}
                        error={errors.tahun_lulus && touched.tahun_lulus && true}
                        errorText={errors.tahun_lulus}
                        onChange={handleChange}
                      >
                        <option value="">Pilih tahun lulus</option>
                        {_.range(new Date().getFullYear(), 1950 - 1).map((res, index) => (
                          <option key={index} value={res}>
                            {res}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Input
                    type="text"
                    label="Lembaga Pendidikan"
                    placeholder="Masukan lembaga pendidikan"
                    name="lembaga_pendidikan"
                    value={values.lembaga_pendidikan}
                    error={errors.lembaga_pendidikan && touched.lembaga_pendidikan && true}
                    errorText={errors.lembaga_pendidikan}
                    onChange={handleChange}
                  />

                  <div className="d-flex justify-content-end pt-3">
                    <ActionButton type="submit" variant="primary" text="Simpan" />
                  </div>
                </ColumnData>
              </form>
            )}
          </Formik>

          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchemaBPJS}
            onSubmit={formSubmitHandlerBPJS}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form className="py-2" onSubmit={handleSubmit}>
                <ColumnData title="Data BPJS">
                  <Alert
                    show={alert.data == "BPJS" ? true : false}
                    showCloseButton={true}
                    text={alert.text}
                    variant={alert.variant}
                    onClose={() =>
                      setAlert({
                        data: "",
                        text: "",
                        variant: "",
                      })
                    }
                  />

                  <Row>
                    <Col lg>
                      <Input
                        type="text"
                        label="BPJS Kesehatan"
                        placeholder="Masukan BPJS Kesehatan"
                        name="bpjs_kesehatan"
                        value={values.bpjs_kesehatan}
                        error={errors.bpjs_kesehatan && true}
                        errorText={errors.bpjs_kesehatan}
                        onChange={handleChange}
                      />
                      <Input
                        type="text"
                        label="BPJS Ketenagakerjaan"
                        placeholder="Masukan BPJS Ketenagakerjaan"
                        name="bpjs_ketenagakerjaan"
                        value={values.bpjs_ketenagakerjaan}
                        error={errors.bpjs_ketenagakerjaan && true}
                        errorText={errors.bpjs_ketenagakerjaan}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end pt-3">
                    <ActionButton type="submit" variant="primary" text="Simpan" />
                  </div>
                </ColumnData>
              </form>
            )}
          </Formik>
        </Col>

        <Col lg="6">
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchemaKontak}
            onSubmit={formSubmitHandlerKontak}
          >
            {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
              <form className="py-2" onSubmit={handleSubmit}>
                <ColumnData title="Data Kontak">
                  <Alert
                    show={alert.data == "Kontak" ? true : false}
                    showCloseButton={true}
                    text={alert.text}
                    variant={alert.variant}
                    onClose={() =>
                      setAlert({
                        data: "",
                        text: "",
                        variant: "",
                      })
                    }
                  />

                  <Input
                    type="text"
                    label="No. Telp"
                    placeholder="Masukan nomor telephone"
                    name="no_telp"
                    value={values.no_telp}
                    error={errors.no_telp && true}
                    errorText={errors.no_telp}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    label="No. HP"
                    placeholder="Masukan nomor handphone"
                    name="no_hp"
                    value={values.no_hp}
                    error={errors.no_hp && true}
                    errorText={errors.no_hp}
                    onChange={handleChange}
                  />
                  <Input
                    type="email"
                    label="Email"
                    placeholder="Masukan email"
                    name="email"
                    value={values.email}
                    error={errors.email && touched.email && true}
                    errorText={errors.email}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    label="Kontak Darurat"
                    placeholder="Masukan kontak darurat"
                    name="kontak_darurat"
                    value={values.kontak_darurat}
                    error={errors.kontak_darurat && touched.kontak_darurat && true}
                    errorText={errors.kontak_darurat}
                    onChange={handleChange}
                  />
                  <TextArea
                    label="Keterangan Kontak Darurat"
                    placeholder="Masukan keterangan kontak darurat"
                    name="keterangan_kontak_darurat"
                    value={values.keterangan_kontak_darurat}
                    error={
                      errors.keterangan_kontak_darurat && touched.keterangan_kontak_darurat && true
                    }
                    errorText={errors.keterangan_kontak_darurat}
                    onChange={handleChange}
                  />

                  <div className="d-flex justify-content-end pt-3">
                    <ActionButton type="submit" variant="primary" text="Simpan" />
                  </div>
                </ColumnData>
              </form>
            )}
          </Formik>

          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchemaBank}
            onSubmit={formSubmitHandlerBank}
          >
            {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
              <form className="py-2" onSubmit={handleSubmit}>
                <ColumnData title="Data Bank">
                  <Alert
                    show={alert.data == "Bank" ? true : false}
                    showCloseButton={true}
                    text={alert.text}
                    variant={alert.variant}
                    onClose={() =>
                      setAlert({
                        data: "",
                        text: "",
                        variant: "",
                      })
                    }
                  />

                  <Select
                    label="Nama Bank"
                    name="kode_bank"
                    defaultValue={values.kode_bank}
                    error={errors.kode_bank && touched.kode_bank && true}
                    errorText={errors.kode_bank}
                    onChange={handleChange}
                  >
                    <option value="">Pilih bank</option>
                    {dataBank.map((val, index) => (
                      <option key={index} value={val.kode_bank}>
                        {val.nama_bank}
                      </option>
                    ))}
                  </Select>
                  <Input
                    type="text"
                    label="No. rekening"
                    placeholder="Masukan nomor rekening"
                    name="no_rekening"
                    value={values.no_rekening}
                    error={errors.no_rekening && true}
                    errorText={errors.no_rekening}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    label="Atas Nama"
                    placeholder="Masukan atas nama"
                    name="atas_nama"
                    value={values.atas_nama}
                    error={errors.atas_nama && touched.atas_nama && true}
                    errorText={errors.atas_nama}
                    onChange={handleChange}
                  />
                  <TextArea
                    type="text"
                    label="Cabang"
                    placeholder="Masukan cabang"
                    name="cabang"
                    value={values.cabang}
                    error={errors.cabang && touched.cabang && true}
                    errorText={errors.cabang}
                    onChange={handleChange}
                  />

                  <div className="d-flex justify-content-end pt-3">
                    <ActionButton type="submit" variant="primary" text="Simpan" />
                  </div>
                </ColumnData>
              </form>
            )}
          </Formik>
        </Col>
      </Row>

      <ColumnData title="Data Kontrak">
        <Kontrak id_karyawan={idKaryawan} />
      </ColumnData>

      <ColumnData title="Data Anggota Keluarga">
        <Keluarga />
      </ColumnData>

      <div className="text-right">
        <ActionButton
          type="button"
          variant="primary"
          text="Simpan & Selesai"
          onClick={() => history.push("/human-resource/master/karyawan/registrasi")}
        />
      </div>
    </>
  )
}

export default FormDataAlamat
