import React from 'react'

const Th = ({children, ...props}) => {
  return (
    <th 
      {...props}
      className={`text-center align-middle ${props.className}`}
      style={{
        ...props.style,
        fontSize: '14px'
      }}
    >
      {children}
    </th>
  )
}

export default Th
