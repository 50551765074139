import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from 'yup'
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  CreateButton,
  UpdateButton,
  DeleteButton,
  ReadButton,
  DeleteModal,
  DataStatus,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  InputFile
} from "../../../../components";
import { Form, Modal } from "react-bootstrap";
import { RegistrasiKaryawanApi } from "../../../../api";
import { TableNumber } from "../../../../utilities";
import FileSaver from "file-saver";
import { Spinner } from './Comps'
const { REACT_APP_FILE_BASE_URL, REACT_APP_BASE_URL } = process.env;

const ImportModal = ({show, setShow}) => {
  const [submitStatus, setSubmitStatus] = useState({
    submit: false,
    loading: false,
    status: true 
  })
  
  const submitHandler = (values) => {
    setSubmitStatus({
      submit: true,
      loading: true,
      success: true 
    })

    let formData = new FormData()
    formData.append('file', values?.data)

    return RegistrasiKaryawanApi.importPekerja(formData)
      .then(() => {
        setSubmitStatus({
          submit: true,
          loading: false,
          success: true 
        })
      })
      .catch(() => {
        setSubmitStatus({
          submit: true,
          loading: false,
          success: false 
        })
      })
  }

  const downloadHandler = () => {
    RegistrasiKaryawanApi.getImportTemplate()
      .then(val => {
        const urlFile = REACT_APP_BASE_URL + '/hrda/' + val?.data?.data;

        FileSaver.saveAs(urlFile, val?.data?.data);
      })
  }

  useEffect(() => {
    return () => {
      setSubmitStatus({
        submit: false,
        loading: false,
        success: true 
      })
    }
  }, [show])
  
  
  return (
        <Modal 
          show={show}
          onHide={() => !submitStatus.loading && setShow(false)}
        >
          <Formik 
            key={show}
            initialValues={{data: '', name: '', link: ''}}
            validationSchema={Yup.object().shape({ name: Yup.string().required('Pilih file') })}
            onSubmit={submitHandler}
          >
          {({values, errors, touched, setValues, handleSubmit}) => (
            <>
              <Modal.Header closeButton>
                <b>Import Data Pekerja</b>
              </Modal.Header>
              <Modal.Body>
                {
                  submitStatus.submit
                    ? <>
                        {
                          submitStatus.loading
                            ? <div className="text-center py-3">
                                <small>Import data sedang dalam proses, mohon jangan keluar dari sistem</small><br />
                                {/* <Spinner animation="grow" className="mt-3" />
                                <Spinner animation="grow" className="mt-3 mx-2" />
                                <Spinner animation="grow" className="mt-3" /> */}
                                <Spinner />
                              </div>
                            : submitStatus.success
                              ? <div className="text-center py-5">
                                  Import data berhasil
                                </div>
                              : <div className="text-center text-danger py-5">
                                  Import data gagal, data ada yang tidak sesuai
                                </div>
                        }
                      </>
                    : <Form>
                        <small>Dokumen Excel Data Pekerja *</small>
                        <Form.File 
                          custom
                          label={values?.name}
                          data-browse="Upload"
                          onChange={(e) => {
                            const file = e.target.files && e.target.files[0]
                            const acceptFileType = ['xls', 'xlsx', 'xlsb', 'xlsm']

                            // Check apakah ada file yang dipilih
                            if (!file) return null 
                          
                            // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
                            const getFileType = file.name.split('.')[1] 

                            // Check jika type file sudah benar
                            if (acceptFileType.includes(getFileType)) {
                              const generateName = file.name
                              const generateLink = URL.createObjectURL(file)

                              return setValues({data: file, name: generateName, link: generateLink})
                            } else {
                              return window.alert(`Format file tidak valid, harap masukan file berformat ${acceptFileType.join(', ')}`)
                            }
                          }}
                        />
                        {errors.name && touched.name && <small className="text-danger">Pilih file yang ingin diimport</small>}

                        <small className="text-primary" style={{cursor: 'pointer'}} onClick={downloadHandler}>
                          <u>Download Template Excel Data Pekerja</u>
                        </small>
                      </Form>
                }
              </Modal.Body>
              { !submitStatus.loading ?
                <Modal.Footer>
                  {submitStatus.loading 
                    ? <div></div>
                    : !submitStatus.submit 
                    ? <ActionButton 
                        text="BATAL"
                        variant="outline-dark"
                        onClick={() => setShow(false)}
                      />
                    : <ActionButton 
                        text="KEMBALI"
                        variant="outline-dark"
                        onClick={() => setShow(false)}
                      />
                  }
                  {!submitStatus.submit &&
                    <ActionButton 
                      text="IMPORT"
                      onClick={handleSubmit}
                    />
                  }
                </Modal.Footer>
                : <div></div>
              }
            </>
          )}
        </Formik>
      </Modal>
  )
}

const RegistrasiKaryawan = ({ setNavbarTitle }) => {
  // Title
  const title = "Registrasi Pekerja";

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(true);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");

  // data jenis anggaran
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [deleteData, setDeleteData] = useState({});
  const [isDeleteData, setIsDeleteData] = useState(false);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    searchKey: "",
  });

  const [modalImportShow, setModalImportShow] = useState(false)

  const history = useHistory();

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    // request data ke server
    RegistrasiKaryawanApi.getPage(page, dataLength, searchKey)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Gagal untuk memuat data",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle(title);

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // modal hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const idData = deleteData.id_karyawan;
    const values = { id_karyawan: idData };

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      RegistrasiKaryawanApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <span>No. Pekerja : {deleteData.no_karyawan}</span>
        <br></br>
        <span>Nama Pekerja : {deleteData.nama_karyawan}</span>
      </DeleteModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>No. Pekerja</ThFixed>
            <Th>Username</Th>
            <Th>Nama Pekerja</Th>
            <Th>Jabatan</Th>
            <Th>Unit Produksi</Th>
            <Th>Keterangan</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ReadButton
                    onClick={() =>
                      history.push("/human-resource/master/karyawan/" + val.id_karyawan)
                    }
                  />
                  <UpdateButton
                    onClick={() =>
                      history.push("/human-resource/master/karyawan/ubah/" + val.id_karyawan)
                    }
                  />
                  <DeleteButton
                    onClick={() => {
                      setDeleteData(val);
                      setIsDeleteData(true);
                    }}
                  />
                </div>
              </TdFixed>
              <TdFixed>{val.no_karyawan ?? "-"}</TdFixed>
              <Td>{val.username ?? "-"}</Td>
              <Td>{val.nama_karyawan ?? "-"}</Td>
              <Td>{val.nama_jabatan ?? "-"}</Td>
              <Td>{val.nama_unit_produksi ?? "-"}</Td>
              <Td>{val.keterangan ?? "-"}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );
          
  return (
    <CRUDLayout>
      {/* head */}
      <CRUDLayout.Head>
        {/* search section */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* button section */}
        <CRUDLayout.HeadButtonSection>
          <ActionButton 
            variant="outline-primary"
            text="Import"
            onClick={() => setModalImportShow(true)}
          />
          <ActionButton 
            variant="outline-success"
            text="Export"
            className="mx-2"
            onClick={() => {
              setIsLoading(true);
              RegistrasiKaryawanApi.export()
                .then((val) => {
                  const urlFile = REACT_APP_FILE_BASE_URL + val.data.file;
                  FileSaver.saveAs(urlFile, val.data.file);
                })
                .catch((res) => alert(res))
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          />
          <CreateButton onClick={() => history.push("/human-resource/master/karyawan/tambah")} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      {isSearching ? (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={`Hasil dari pencarian: ${alertConfig.searchKey}`}
          onClose={() => {
            setShowAlert(false);
            getData();
            setIsSearching(false);
          }}
        />
      ) : (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
      )}

      {/* Table */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      }
      <HapusModal />
      <ImportModal 
        show={modalImportShow}
        setShow={setModalImportShow}
      />
    </CRUDLayout>
  );
};

export default RegistrasiKaryawan;
