import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  DataStatus,
  Alert,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
  ThFixed,
  TdFixed,
  InfoItemVertical,
} from "../../../../components";
import { HakAksesDivisiApi } from "../../../../api";

const HakAksesDivisiDetail = ({ dataAksi }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const getData = () => {
    setIsLoading(true);
    if (dataAksi.aksi === "detail") {
      HakAksesDivisiApi.get(dataAksi.id_karyawan, dataAksi.kode_hak_akses)
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => alert(err))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getData();
    return () => setIsLoading(false);
  }, []);

  const DivisiTable = () => {
    return (
      <>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <Th>Nama Menu</Th>
              <Th>Tipe Menu</Th>
            </Tr>
          </THead>
          <TBody>
            {data
              .filter((res) => res.status === true)
              .map((val, index) => (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>{val.nama_hak_akses ?? "-"}</Td>
                  <Td>{val.nama_tipe_menu ?? "-"}</Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      </>
    );
  };
  return (
    <div
      className="p-1"
      style={{
        minHeight: "70vh",
        overflow: "auto",
      }}
    >
      <>
        <Row className="mb-4">
          <Col sm={6} lg={8}>
            <InfoItemVertical label="Karyawan Pengakses" text={dataAksi.nama_karyawan} />
          </Col>
          <Col sm={6} lg={4}>
            <InfoItemVertical label="Modul" text={dataAksi.nama_hak_akses} />
          </Col>
        </Row>
        {isLoading ? (
          <DataStatus loading={true} text="Memuat..." />
        ) : (
          <>
            <Alert
              show={showAlert}
              showCloseButton={true}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() => setShowAlert(false)}
            />
            <DivisiTable />
          </>
        )}
      </>
    </div>
  );
};

export default HakAksesDivisiDetail;
