import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import Axios from "axios";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DeleteButton,
  DeleteModal,
  CreateModal,
  UpdateModal,
  ReadModal,
  SelectSearch,
} from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { HakAksesDivisiApi } from "api";
import HakAksesDivisiAction from "./HakAksesDivisiAction";
import HakAksesDivisiDetail from "./HakAksesDivisiDetail";

const HakAksesDivisiList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [isCreateForm, setIsCreateForm] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [isDetailForm, setIsDetailForm] = useState(false);
  const [updateParam, setupdateParam] = useState({});
  const [data, setData] = useState([]);
  const [hakFilter, setHakFilter] = useState([]);
  const [dataDivisi, setDataDivisi] = useState([]);
  const [deleteData, setDeleteData] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
    tipe_hak_akses: "",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = (kodeHak) => {
    setIsPageLoading(true);

    Axios.all([
      HakAksesDivisiApi.getPage({
        q: searchConfig.key,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
        tipe_hak_akses: "divisi",
        kode_hak_akses: kodeHak,
      }),
      HakAksesDivisiApi.getDivisi(),
    ])
      .then(
        Axios.spread((data, hak) => {
          setData(data.data.data);
          setPaginationConfig({
            ...paginationConfig,
            dataCount: data.data.data_count,
            totalPage: data.data.total_page,
          });
          setDataDivisi(hak.data.data);
        })
      )
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  console.log(dataDivisi);

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = {
      id_hak_akses_karyawan: deleteData.id_hak_akses_karyawan,
      kode_hak_akses: deleteData.kode_hak_akses,
    };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      HakAksesDivisiApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
            show: true,
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!`,
            show: true,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          getInitialData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
      >
        <div>Modul : {deleteData.nama_hak_akses}</div>
      </DeleteModal>
    );
  };

  useEffect(() => {
    setNavbarTitle("Hak Akses Divisi");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const TambahModal = () => {
    return (
      <CreateModal
        show={isCreateForm}
        onHide={() => setIsCreateForm(false)}
        title="Hak Akses"
        size="lg"
      >
        <Modal.Body>
          <HakAksesDivisiAction
            dataAksi={updateParam}
            setAlertConfig={setAlertConfig}
            setIsCreateForm={setIsCreateForm}
            getInitialData={getInitialData}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </CreateModal>
    );
  };
  const UbahModal = () => {
    return (
      <UpdateModal
        show={isUpdateForm}
        onHide={() => setIsUpdateForm(false)}
        title="Hak Akses"
        size="lg"
      >
        <Modal.Body>
          <HakAksesDivisiAction
            dataAksi={updateParam}
            setAlertConfig={setAlertConfig}
            setIsUpdateForm={setIsUpdateForm}
            getInitialData={getInitialData}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </UpdateModal>
    );
  };

  const DetailModal = () => {
    return (
      <ReadModal
        show={isDetailForm}
        onHide={() => setIsDetailForm(false)}
        title="Hak Akses"
        size="lg"
      >
        <Modal.Body>
          <HakAksesDivisiDetail dataAksi={updateParam} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </ReadModal>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th>Modul</Th>
            <Th>Karyawan Pengakses</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}
              </TdFixed>
              <Td>
                <div className="d-flex">
                  <ReadButton
                    onClick={() => {
                      setIsDetailForm(true);
                      setupdateParam({
                        kode_hak_akses: val.kode_hak_akses,
                        id_karyawan: val.id_karyawan,
                        nama_karyawan: val.nama_karyawan,
                        nama_hak_akses: val.nama_hak_akses,
                        aksi: "detail",
                      });
                    }}
                  />
                  <UpdateButton
                    onClick={() => {
                      setIsUpdateForm(true);
                      setupdateParam({
                        kode_hak_akses: val.kode_hak_akses,
                        id_karyawan: val.id_karyawan,
                        nama_karyawan: val.nama_karyawan,
                        nama_hak_akses: val.nama_hak_akses,
                        no_karyawan: val.no_karyawan,
                        nama_jabatan: val.nama_jabatan,
                        nama_unit_produksi: val.nama_unit_produksi,
                        kode_hak_akses_parent: val.kode_hak_akses,
                        aksi: "ubah",
                      });
                    }}
                  />
                  <DeleteButton
                    onClick={() => {
                      setDeleteData(val);
                      setIsDeleteData(true);
                    }}
                  />
                </div>
              </Td>
              <Td>{val.nama_hak_akses ?? "-"}</Td>
              <Td>
                {`${val.no_karyawan} - ${val.nama_karyawan} (${val.username}) - ${val.nama_jabatan} - ${val.nama_unit_produksi}` ??
                  "-"}
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}

      <Row className="mb-3">
        <Col md={4}>
          <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
        </Col>
        <Col md={4} />
        <Col md={3}>
          <SelectSearch
            placeholder="Modul"
            option={dataDivisi.map((val) => {
              return {
                value: val.kode_hak_akses,
                label: val.nama_hak_akses,
              };
            })}
            onChange={(val) => getInitialData(val.value)}
          />
        </Col>
        <Col md={1} className="d-flex justify-content-end">
          <div>
            <ActionButton
              text="Tambah"
              onClick={() => {
                setIsCreateForm(true);
                setupdateParam({
                  kode_hak_akses: "",
                  id_karyawan: "",
                  aksi: "add",
                });
              }}
            />
          </div>
        </Col>
      </Row>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <PageContent />
          <HapusModal />
          <TambahModal />
          <UbahModal />
          <DetailModal />
        </>
      )}
    </CRUDLayout>
  );
};

export default HakAksesDivisiList;
